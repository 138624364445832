import { useCallback, useEffect } from "react";
import { withTranslation } from 'react-i18next';

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Dots from 'components/markup/loading/Dots'

const MXUpdateConnection = ({ mxMemberToVerify, mxOpenConnect, t }) => {

    const runVerifyIdentity = useCallback(async() => {
        mxOpenConnect(mxMemberToVerify);
    }, [mxOpenConnect, mxMemberToVerify])


    useEffect(() => {
        runVerifyIdentity();
    }, [runVerifyIdentity])

    return (

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
                    <Card>
                        <CardBody>
                            <h4 className="text-center">
                                {t(`MXUpdateConnection: Updating Connection`)}
                                <Dots />
                            </h4>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    )
}

export default withTranslation()(MXUpdateConnection);

