import Circle from 'components/markup/loading/Circle';

const YodleeModal = ({yodleeShowModal, yodleeConnectLoaded}) => (
    <div className={yodleeShowModal ? "modal fade show" : "modal fade"} role="dialog" tabIndex="-1" style={{display: yodleeShowModal ? 'block' : 'none'}}>
        <div className="modal-backdrop fade show" />
        <div id="test" className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{maxWidth: 435, borderRadius: 10, margin: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 99999999}}>
            <div className="modal-content">
                <div className="modal-body p-0" style={{minHeight: 400, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                    {!yodleeConnectLoaded ? <div style={{position: 'relative', top: 325}}><Circle /></div> : null}
                    <div id="container-fastlink" />
                </div>
            </div>
        </div>
    </div>
)

export default YodleeModal;