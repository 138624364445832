import Notifications from '../pages/Notifications';
import Search from '../pages/Search';

const routes = [

    {
        path: "/notifications/:contact_id",
        component: Notifications,
    },
    {
        path: "/search",
        component: Search,
    },

]

export default routes;