/*
Documentation

this component renders the connect widget with MX

*/
import keys from 'keys';
import { withTranslation } from 'react-i18next';

import { useState, useCallback, useEffect } from "react";

import { Card, CardFooter, CardHeader, CardTitle, Col, Container,  Row, Progress } from "reactstrap";

import ConfirmationModal from 'components/functional/modals/Confirmation'
import Circle from 'components/markup/loading/Circle'

import MXConnectIcon from '../_images/MXConnect.svg'

import _events from '_functions/events'

const MemberCard = withTranslation()(({logo, member, mxGetConnectionName, mxMemberNeedsVerification, mxSetMemberToDelete, setView, name, mxOpenConnect, code, t}) => {

    if(member) return (
        <CardFooter>
            <Row>
                <Col xs={10} className="align-self-center">
                    <h4>
                        {logo ? (
                            <img 
                                src={logo}
                                alt="name"
                                className="mr-3"
                                style={{
                                    position: 'relative',
                                    top: -2,
                                    width: 28,
                                    borderRadius: 4,
                                    display: 'inline-blcok'
                                }}
                            />
                        ) : null}
                        {name}
                    </h4>
                    {mxMemberNeedsVerification(member) ? (
                        <p className="text-sm mb-0" onClick={() => setView('MXVerifyIdentity', member.guid, 'MXApps')}>
                            <span className="cursor-pointer text-muted">
                                <i className="fas fa-exclamation-triangle text-warning mr-2 " /> {t(`MXApps: Click To Verify`)}
                            </span>
                        </p>
                    ) : 
                        <p className="text-sm mb-0">
                            {member.connection_status === 'CONNECTED' ? (
                                <span><i className="fas fa-check text-success mr-2 " />{mxGetConnectionName(member.connection_status)}</span>
                            ) : (
                                <span><i className="fas fa-info-circle text-info mr-2 " />{mxGetConnectionName(member.connection_status)}</span>
                            )}
                        </p>
                    }
                </Col>
                <Col xs={2} className="align-self-center text-right">
                    <i className="fas fa-times text-danger cursor-pointer pl-3 pt-3 pb-3" onClick={() => mxSetMemberToDelete(member)} />
                </Col>
            </Row>  
        </CardFooter>
    )

    return  (
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <h4>
                        {logo ? (
                            <img 
                                src={logo}
                                alt="name"
                                className="mr-3"
                                style={{
                                    position: 'relative',
                                    top: -2,
                                    width: 28,
                                    borderRadius: 4,
                                    display: 'inline-blcok'
                                }}
                            />
                        ) : null}
                        {name}
                    </h4>
                    <p className="text-muted text-sm mb-0">{t(`MXApps: Not Set Up`)}</p>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button className="btn btn-outline-success" onClick={() => mxOpenConnect(null, code)}>
                        <i className="fas fa-plus mr-2 " /> {t(`MXApps: Connect`)}
                    </button>
                </Col>
            </Row>  
        </CardFooter>
    )

})

const MXApps = ({
    foundContact,
    mxOpenConnect,
    foundCompany,
    setView,
    mxMemberNeedsVerification,
    companyNameColor,
    mxAccounts,
    mxGetConnectionName,
    mxFetchAccounts,
    verifyAccess,
    mxSetMemberToDelete,
    t
}) => {

    const [showUnverified, setShowUnverified] = useState(false);


    const onMoveOn = useCallback((byPassUnverified) => {

        // see if we have unverified accounts, ignore those of partner
        const hasUnverified = mxAccounts.some(m => {
            if(
                m.institution_code === keys.INSTITUTION_VENMO || 
                m.institution_code === keys.INSTITUTION_CASH_APP || 
                m.institution_code === keys.INSTITUTION_PAYPAL || 
                m.institution_code === keys.INSTITUTION_ZELLE 
            ) {
                if(mxMemberNeedsVerification(m)) return true;
            }
            return false;
        })

        if(hasUnverified && !byPassUnverified) {
            return setShowUnverified(true);
        } else {
            setShowUnverified(false)
        }

        setView('PinwheelLink');
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'system', 
            body: `Finished linking financial institutions.`
        })
    }, [setView, foundContact._id, foundContact.access_code, foundCompany._id, mxMemberNeedsVerification, mxAccounts])

    const getMember = useCallback((institution) => {
        if(!mxAccounts) return null;

        return mxAccounts.find(member => member.institution_code === institution && !member.partner)
    }, [mxAccounts])

    useEffect(() => {
        mxFetchAccounts();
        verifyAccess();
    }, [mxFetchAccounts, verifyAccess])

    if(!mxAccounts) return <Circle />

    const memberVenmo = getMember(keys.INSTITUTION_VENMO);
    const memberCashApp = getMember(keys.INSTITUTION_CASH_APP);
    const memberPaypal = getMember(keys.INSTITUTION_PAYPAL);
    // const memberZelle = getMember(keys.INSTITUTION_ZELLE);
    
    const memberChime = getMember(keys.INSTITUTION_CHIME);

    return (

        <>

        <ConfirmationModal 
            prevButtonClasses="btn btn-success"
            nextButtonClasses="btn btn-outline-warning"
            prevButtonText="Verify"
            nextButtonText="Proceed"
            showModal={showUnverified}
            toggleModal={() => setShowUnverified(false)}
            title={t('MXApps: Proceed With Unverified Accounts')}
            body={(
                <span>
                    {t(`MXApps: Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.`)}
                    <br /><br /> 
                    <b>
                        <span className="text-underline">{t(`MXApps: To verify your account(s)`)}</span>, {t(`MXApps: click the`)}{' '}
                        <span className="text-warning">{t(`MXApps: ORANGE BUTTON`)}</span>{' '}
                        {t(`MXApps: next to their name.`)}
                    </b>
                </span>
            )}
            onConfirmation={() => onMoveOn(true)}
        />

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={MXConnectIcon} className="bg-white flow-icon" alt="..." />

                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">{t(`MXApps: Now let's check your apps.`)}</CardTitle>
                            <div className="progress-wrapper pt-2">
                                <div className="progress-yellow">
                                    <div className="progress-percentage">
                                    <span style={{position: 'relative', top: -3}}>{t(`MXApps: Step 2/5`)}</span>
                                    </div>
                                </div>
                                <Progress max="100" value={40} color="yellow" />
                            </div>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm mb-0">
                                {t(`MXApps: Now let's take a deep dive, into your financial apps. Take a look at the list below and connect any apps that you have an account with.`)}
                            </p>

                        </CardHeader>

                        <MemberCard 
                            logo="https://content.moneydesktop.com/storage/MD_Assets/Ipad%20Logos/100x100/INS-93fab479-3c13-82df-0aef-e05a80e5c452_100x100.png"
                            name="Chime"
                            member={memberChime}
                            mxMemberNeedsVerification={mxMemberNeedsVerification}
                            setView={setView}
                            mxGetConnectionName={mxGetConnectionName}
                            mxSetMemberToDelete={mxSetMemberToDelete}
                            mxOpenConnect={mxOpenConnect}
                            code={keys.INSTITUTION_CHIME}
                        />
                        
                        <MemberCard 
                            logo="https://content.moneydesktop.com/storage/MD_Assets/Ipad%20Logos/50x50/INS-f78cd062-8ec2-5bc2-cd48-ebb8bf032b2d_50x50.png"
                            name="PayPal"
                            member={memberPaypal}
                            mxMemberNeedsVerification={mxMemberNeedsVerification}
                            setView={setView}
                            mxGetConnectionName={mxGetConnectionName}
                            mxSetMemberToDelete={mxSetMemberToDelete}
                            mxOpenConnect={mxOpenConnect}
                            code={keys.INSTITUTION_PAYPAL}
                        />
                        <MemberCard 
                            logo="https://content.moneydesktop.com/storage/MD_Assets/Ipad%20Logos/100x100/INS-7231cb89-3c65-132b-da0e-023e7671f4b5_100x100.png"
                            name="Venmo"
                            member={memberVenmo}
                            mxMemberNeedsVerification={mxMemberNeedsVerification}
                            setView={setView}
                            mxGetConnectionName={mxGetConnectionName}
                            mxSetMemberToDelete={mxSetMemberToDelete}
                            mxOpenConnect={mxOpenConnect}
                            code={keys.INSTITUTION_VENMO}
                        />
                        <MemberCard 
                            logo="https://content.moneydesktop.com/storage/MD_Assets/Ipad%20Logos/100x100/INS-b287e747-54bd-47e6-9727-2a23300046bf_100x100.png"
                            name="Cash App"
                            member={memberCashApp}
                            mxMemberNeedsVerification={mxMemberNeedsVerification}
                            setView={setView}
                            mxGetConnectionName={mxGetConnectionName}
                            mxSetMemberToDelete={mxSetMemberToDelete}
                            mxOpenConnect={mxOpenConnect}
                            code={keys.INSTITUTION_CASH_APP}
                        />
                       
                        {/* <MemberCard 
                            name="Zelle"
                            member={memberZelle}
                            mxMemberNeedsVerification={mxMemberNeedsVerification}
                            setView={setView}
                            mxGetConnectionName={mxGetConnectionName}
                            mxSetMemberToDelete={mxSetMemberToDelete}
                            mxOpenConnect={mxOpenConnect}
                            code={keys.INSTITUTION_ZELLE}
                        /> */}
                        
                        <CardFooter className="text-center">
                            <button  onClick={() => onMoveOn(false)} className=" btn btn-success btn-block my-2 btn-lg">
                                {t(`MXApps: Move On`)} <i className="fas fa-arrow-right ml-2 " />
                            </button>
                        </CardFooter>

                    </Card>

                    <p className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" style={{color: companyNameColor}}>
                        <span onClick={() => setView('MXConnect')}>
                        <i className="fas fa-arrow-left mr-2 " />
                            {t(`MXApps: Back`)}
                        </span>
                    </p>

                </Col>
            </Row>
        </Container>

        </>

    )
}

export default withTranslation()(MXApps);

