import apiWorker from '../apiWorker'

const contacts = {
    verify              : (data) =>  apiWorker('post', `/v1/api/contacts/verify`, data, true),
    setStatus           : (data) =>  apiWorker('post', `/v1/api/contacts/set_status/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
    setName             : (data) =>  apiWorker('post', `/v1/api/contacts/set_name/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
    setAgreed           : (data) =>  apiWorker('post', `/v1/api/contacts/set_agreed/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
    setEmployment       : (data) =>  apiWorker('post', `/v1/api/contacts/set_employment/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
    setTimestamp        : (data) =>  apiWorker('post', `/v1/api/contacts/set_timestamp/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
    setNotifications    : (data) =>  apiWorker('post', `/v1/api/contacts/set_notifications/${data.contact_id}`, data, true),
}

export default contacts;