const ConfirmationPre = {
    en: {
        translation: {
            "ConfirmationPre: Finish With Unverified Accounts": "Finish With Unverified Accounts",
            "ConfirmationPre: Before finishing this page please verify all of your accounts. If you do not, no documents will be able to be retrieved from any account marked as needing verification.": "Before finishing this page please verify all of your accounts. If you do not, no documents will be able to be retrieved from any account marked as needing verification.",
            "ConfirmationPre: To verify your account(s)": "To verify your account(s)",
            "ConfirmationPre: click the": "click the",
            "ConfirmationPre: ORANGE BUTTON": "ORANGE BUTTON",
            "ConfirmationPre: next to their name.": "next to their name.",
            "ConfirmationPre: Last Step!": "Last Step!",
            "ConfirmationPre: Please confirm the below accounts are correct.": "Please confirm the below accounts are correct.",
            "ConfirmationPre: Once you hit": "Once you hit",
            'ConfirmationPre: "FINISH!"': '"FINISH!"',
            "ConfirmationPre: you're all done and will NOT be able to come back to this page.": "you're all done and will NOT be able to come back to this page.",
            "ConfirmationPre: Financial Accounts": "Financial Accounts",
            "ConfirmationPre: Employer Accounts": "Employer Accounts",
            "ConfirmationPre: Added By Partner": "Added By Partner",
            "ConfirmationPre: Needs Verification": "Needs Verification",
            "ConfirmationPre: You have not logged into any financial accounts.": "You have not logged into any financial accounts.",
            "ConfirmationPre: Connected": "Connected",
            "ConfirmationPre: You have not logged into any employer accounts.": "You have not logged into any employer accounts.",
            "ConfirmationPre: FINISH!": "FINISH!",
            "ConfirmationPre: Back": "Back",
            "ConfirmationPre: FINISH": "FINISH",
            "ConfirmationPre: Auto Policies": "Auto Policies",
        }
    },
    es: {
        translation: {
            "ConfirmationPre: Finish With Unverified Accounts": "Finalizar con Cuentas No Verificadas",
            "ConfirmationPre: Before finishing this page please verify all of your accounts. If you do not, no documents will be able to be retrieved from any account marked as needing verification.": "Antes de terminar con esta página por favor verifica todas tus cuentas. De no hacerlo, los documentos no podrán ser recuperados de ninguna cuenta marcada como no verificada.",
            "ConfirmationPre: To verify your account(s)": "Para verificar una(s) cuenta(s)",
            "ConfirmationPre: click the": "haz click en el",
            "ConfirmationPre: ORANGE BUTTON": "BOTÓN NARANJA",
            "ConfirmationPre: next to their name.": "que está al lado de tu nombre.",
            "ConfirmationPre: Last Step!": "¡Paso Final!",
            "ConfirmationPre: Please confirm the below accounts are correct.": " Por favor confirma que las cuentas que se encuentran abajo estén correctas. ",
            "ConfirmationPre: Once you hit": "Una vez presiones",
            'ConfirmationPre: "FINISH!"': '"¡FINALIZAR!"',
            "ConfirmationPre: you're all done and will NOT be able to come back to this page.": "el proceso estará completo y NO podrás volver a esta página.",
            "ConfirmationPre: Financial Accounts": "Cuentas Financieras",
            "ConfirmationPre: Employer Accounts": "Cuentas de Empleado/Nómina",
            "ConfirmationPre: Added By Partner": "Agregado por el Socio",
            "ConfirmationPre: Needs Verification": "Necesita Verificación",
            "ConfirmationPre: You have not logged into any financial accounts.": "No has ingresado a ninguna de tus cuentas financieras.",
            "ConfirmationPre: Connected": "Conectado(a).",
            "ConfirmationPre: You have not logged into any employer accounts.": "No ha ingresado a ninguna cuenta como empleador.",
            "ConfirmationPre: FINISH!": "¡FINALIZAR!",
            "ConfirmationPre: Back": "Regresar",
            "ConfirmationPre: FINISH": "¡FINALIZAR!",
            "ConfirmationPre: Auto Policies": "Pólizas de Auto",

        }
    }
};

export default ConfirmationPre;