import apiWorker from '../apiWorker'

const companies =  {

    findByName    : (name)          =>  apiWorker('get', `/v1/api/companies/name/${name}`, null),
    findById      : (company_id)    =>  apiWorker('get', `/v1/api/companies/${company_id}`, null),
    register      : (data)          =>  apiWorker('post', `/v1/api/companies/register`, data, true),

}

export default companies;