/*
Documentation

this page makes the user confirm the company they are logging into is correct

*/

import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row, Input, FormGroup } from "reactstrap";

import VerificationNameIcon from '../_images/VerificationName.svg'

import { toggleAlertBS } from 'store/functions/system/system';

import _analytics from '_functions/analytics';
import _failed_name_logs from '_functions/failed_name_logs';

const VerificationName = ({foundContact, onNameVerified, setView, session, t}) => {

    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [err, setErr] = useState(false);

    const onVerify = async (e) => {

        e.preventDefault();

        if(!givenName || !familyName) return setErr(t('VerifyName: Please fill out your full legal name.'));

        try {

            const displayName     = foundContact.display_name.split(' ');
            const given_name_1    = displayName[0].trim();
            const given_name_2    = givenName.trim();
            const family_name_1   = displayName[1].trim();
            const family_name_2   = familyName.trim();

            if(given_name_1 === given_name_2 && family_name_1 === family_name_2) return onNameVerified();

            _analytics.increment({portal_session_id: session, field: 'failed_to_match_name'});

            _failed_name_logs.create({
                contact: foundContact._id,
                given_name_1,
                given_name_2,
                family_name_1,
                family_name_2,
            })

            toggleAlertBS('info', t('VerifyName: The name you have added does not match the name you accepted the terms of service with. Please correct the spelling on this page before moving on.'))
            return setView('VerifyCompany')

        } catch(e) {

        }

    }

    return (
        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
    
                    <Form role="form">
    
                        <Card className="bg-white border-0 mb-0 position-relative" >
    
                            <img src={VerificationNameIcon} alt="..." className="flow-icon bg-white" />
    
                            <CardHeader>
                                <CardTitle className="mb-0">{t('VerifyName: Please Confirm Your Name')}</CardTitle>
                            </CardHeader>
    
                            <CardHeader>
                                <p className="text-sm">{t('VerifyName: Please type your legal first and last name EXACTLY as it appears on your drivers license or birth certificate.')}</p>
                                <p className="mb-0 text-sm">{t('VerifyName: Make sure your name is free of any spelling mistakes and capitalization is correct.')}</p>
                            </CardHeader>
    
                            <CardHeader>

                                <FormGroup>
                                    <label className="form-control-label">{t('VerifyName: Confirm Legal First Name*')}</label>
                                    <Input 
                                        type="text"
                                        value={givenName}
                                        onChange={(e) => setGivenName(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-control-label">{t('VerifyName: Confirm Legal Last Name*')}</label>
                                    <Input 
                                        type="text"
                                        value={familyName}
                                        onChange={(e) => setFamilyName(e.target.value)}
                                    />
                                </FormGroup>
                            </CardHeader>
    
                            {err ? (
                                <CardHeader>
                                    <p className="text-sm font-weight-bold text-warning mb-0">{err}</p>
                                </CardHeader>
                            ) : null}
    
    
                            <CardFooter className="text-center">
                                <button id="loginButton" onClick={onVerify} className="my-2 btn-lg btn-block btn btn-success">
                                    {t('VerifyName: Next')} <i className="fas fa-arrow-right " />
                                </button>
                            </CardFooter>
                        </Card>
    
                    </Form>
    
                </Col>
            </Row>
        </Container>
    )

}

export default withTranslation()(VerificationName);

