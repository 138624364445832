const PinwheelLink = {
    en: {
        translation: {
            "PinwheelLink: Let's Verify Your Employment": "Let's Verify Your Employment",
            "PinwheelLink: Step 3/5": "Step 3/5",
            "PinwheelLink: Click the": "Click the",
            "PinwheelLink: ORANGE": "ORANGE",
            'PinwheelLink: button below to verify your paystubs and income. If you are on unemployment search for "unemployment".': 'button below to verify your paystubs and income. If you are on unemployment search for "unemployment".',
            "PinwheelLink: Verify Employment": "Verify Employment",
            "PinwheelLink: Connected Employers": "Connected Employers",
            "PinwheelLink: Added By Partner": "Added By Partner",
            "PinwheelLink: Connected": "Connected",
            "PinwheelLink: You have not logged into any employer accounts.": "You have not logged into any employer accounts.",
            "PinwheelLink: Move On": "Move On",
            "PinwheelLink: Back": "Back",
            "PinwheelLink: Click To Verify": "Click To Verify",
        }
    },
    es: {
        translation: {
            "PinwheelLink: Let's Verify Your Employment": "Verifiquemos Su Empleo",
            "PinwheelLink: Step 3/5": "Paso 3/5",
            "PinwheelLink: Click the": "Haga clic en el botón",
            "PinwheelLink: ORANGE": "NARANJA",
            'PinwheelLink: button below to verify your paystubs and income. If you are on unemployment search for "unemployment".': 'de abajo para verificar sus recibos de sueldo e ingresos. Si no está laborando, busque "unemployment".',
            "PinwheelLink: Verify Employment": "Verificar el empleo",
            "PinwheelLink: Connected Employers": "Empleadores conectados",
            "PinwheelLink: Added By Partner": "Añadido por el socio",
            "PinwheelLink: You have not logged into any employer accounts.": "No ha entrado en ninguna cuenta de empleador.",
            "PinwheelLink: Connected": "Conectado",
            "PinwheelLink: Move On": "Avanza.",
            "PinwheelLink: Back": "Regresar",
            "PinwheelLink: Click To Verify": "Haz click para verificar.",

        }
    }
};

export default PinwheelLink;