const MXConnect = {
    en: {
        translation: {
            "MXConnect: Proceed With Unverified Accounts": "Proceed With Unverified Accounts",
            "MXConnect: Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.": "Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.",
            "MXConnect: To verify your account(s)": "To verify your account(s)",
            "MXConnect: click the": "click the",
            "MXConnect: ORANGE BUTTON": "ORANGE BUTTON",
            "MXConnect: next to their name.": "next to their name.",
            "MXConnect: Are you sure you wish to remove the account:": "Are you sure you wish to remove the account:",
            "MXConnect: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.": "You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.",
            "MXConnect: Let's Dig Deeper.": "Let's Dig Deeper.",
            "MXConnect: Step 1/5": "Step 1/5",
            "MXConnect: Now let's try an advanced search, click the": "Now let's try an advanced search, click the",
            "MXConnect: PURPLE": "PURPLE",
            "MXConnect: button below to search for any banks, financial institutions, etc. that you": "button below to search for any banks, financial institutions, etc. that you",
            "MXConnect: couldn't find in step 1.": "couldn't find in step 1.",
            "MXConnect: Advanced Search": "Advanced Search",
            "MXConnect: Deep Dive Accounts": "Deep Dive Accounts",
            "MXConnect: Added By Partner": "Added By Partner",
            "MXConnect: Click To Verify": "Click To Verify",
            "MXConnect: You have not logged into any deep dive accounts.": "You have not logged into any deep dive accounts.",
            "MXConnect: Accounts Connected On Step 1.": "Accounts Connected On Step 1.",
            "MXConnect: Move On": "Move On",
            "MXConnect: Back": "Back",

            "MXConnect: Let's connect your finances.": "Let's connect your finances.",
            "MXConnect: Click the": "Click the",
            "MXConnect: button below to search for any banks, financial institutions, etc. that you may have.": "button below to search for any banks, financial institutions, etc. that you may have.",

        }
    },
    es: {
        translation: {
            "MXConnect: Proceed With Unverified Accounts": "Proceder con cuentas no verificadas",
            "MXConnect: Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.": "Antes de continuar, verifique todas sus aplicaciones. Si no lo hace, no se podrán recuperar documentos de ninguna cuenta marcada como que necesita verificación.",
            "MXConnect: To verify your account(s)": "Para verificar su(s) cuenta(s)",
            "MXConnect: click the": "haga clic en el",
            "MXConnect: ORANGE BUTTON": "BOTÓN NARANJA",
            "MXConnect: next to their name.": "junto a su nombre.",
            "MXConnect: Are you sure you wish to remove the account:": "¿Estás segura de que deseas eliminar la cuenta:",
            "MXConnect: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.": "Deberá volver a autenticarse con esta institución en el futuro si desea que su informe incluya sus declaraciones.",
            "MXConnect: Let's Dig Deeper.": " ¡Vamos a investigar más a fondo!",
            "MXConnect: Step 1/5": "Paso 1/5",
            "MXConnect: Now let's try an advanced search, click the": "Ahora vamos a intentar una búsqueda avanzada. Haz click en el",
            "MXConnect: PURPLE": "BOTÓN PÚRPURA",
            "MXConnect: button below to search for any banks, financial institutions, etc. that you": "que se encuentra abajo, para buscar bancos, instituciones financieras, etc. que no",
            "MXConnect: couldn't find in step 1.": "pudiste encontrar en el paso 1. ",
            "MXConnect: Advanced Search": "Búsqueda Avanzada.",
            "MXConnect: Deep Dive Accounts": "Navegar a profundidad entre cuentas.",
            "MXConnect: Added By Partner": "Agregado por el Socio.",
            "MXConnect: Click To Verify": "Haz click para verificar.",
            "MXConnect: You have not logged into any deep dive accounts.": "No estás conectado a ninguna cuenta Navegando Profundamente.",
            "MXConnect: Accounts Connected On Step 1.": "Cuentas conectadas en Paso 1. ",
            "MXConnect: Move On": "Seguir Adelante",
            "MXConnect: Back": "Regresar",

            "MXConnect: Let's connect your finances.": "Vamos A Conectar Sus Bancos.",
            "MXConnect: Click the": "Haz click en el",
            "MXConnect: button below to search for any banks, financial institutions, etc. that you may have.": "que se encuentra abajo, para buscar bancos, instituciones financieras, etc.",


        }
    }
};

export default MXConnect;