/*
Documentation

this page is the last page before confirmation

*/

import { useState, useEffect } from "react";
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';

import _events from '_functions/events'
import _contacts from '_functions/contacts'

import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation';

const ConfirmationPre = ({
    foundContact, 
    foundCompany, 
    setView, 
    companyNameColor, 
    mxAccounts,
    mxMemberNeedsVerification,
    mxGetConnectionName,
    mxFetchAccounts,
    pinwheelAccounts,
    pinwheelAccountList,
    pinwheelNeedsVerification,
    pinwheelFetchAccounts,
    yodleeNeedsVerification,
    yodleeFetchAccounts,
    yodleeGetConnectionName,
    yodleeAccounts,
    t
}) => {

    const [showUnverified, setShowUnverified] = useState(false);

    const onConfirm = (byPassUnverified) => {

        const hasUnverified = mxAccounts.some(m => mxMemberNeedsVerification(m))

        if(hasUnverified && !byPassUnverified) {
            return setShowUnverified(true);
        } else {
            setShowUnverified(false)
        }

        setView('ConfirmationFinal');
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'info', 
            body: `Confirmed all payroll and financial accounts are correct`
        });
        _contacts.setTimestamp({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            field: 'time_to_finish'
        })
        _contacts.setStatus({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            report_status: 'complete'
        });
    }

    useEffect(() => {
        mxFetchAccounts();
        pinwheelFetchAccounts();
        yodleeFetchAccounts();
    }, [mxFetchAccounts, pinwheelFetchAccounts, yodleeFetchAccounts])

    if(!mxAccounts || !yodleeAccounts || !yodleeAccounts) return <Circle />

    return (

        <>

          <ConfirmationModal 
            showModal={showUnverified}
            toggleModal={() => setShowUnverified(false)}
            title={(
                <>
                <i className="fas fa-exclamation-triangle text-danger mr-2" />
                {t(`ConfirmationPre: Finish With Unverified Accounts`)}
                </>
            )}
            body={(
                <span>
                    {t(`ConfirmationPre: Before finishing this page please verify all of your accounts. If you do not, no documents will be able to be retrieved from any account marked as needing verification.`)}
                    <br /><br /> 
                    <b>
                        <span className="text-underline">{t(`ConfirmationPre: To verify your account(s)`)}</span>,{' '}
                        {t(`ConfirmationPre: click the`)}{' '}
                        <span className="text-warning">{t(`ConfirmationPre: ORANGE BUTTON`)}</span>{' '}
                        {t(`ConfirmationPre: next to their name.`)}
                    </b>
                </span>
            )}
            onConfirmation={() => onConfirm(true)}
        />

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

        
                    <Card className="bg-white border-0 mb-0 z-depth-4" >

                        <CardHeader>
                            <CardTitle className="mb-0">{t(`ConfirmationPre: Last Step!`)}</CardTitle>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm">{t(`ConfirmationPre: Please confirm the below accounts are correct.`)}</p>
                            <p className="text-sm mb-0">
                                {t(`ConfirmationPre: Once you hit`)}{' '}
                                <b className="text-success">{t(`ConfirmationPre: "FINISH!"`)}</b>{' '}
                                {t(`ConfirmationPre: you're all done and will NOT be able to come back to this page.`)}
                            </p>
                        </CardHeader>

                        {foundCompany.accept_financial ? 
                            <>
                            <CardHeader className="bg-secondary">    
                                <CardTitle className="mb-0">{t(`ConfirmationPre: Financial Accounts`)}({mxAccounts.length + yodleeAccounts.length})</CardTitle>
                            </CardHeader>
                            {mxAccounts.length || yodleeAccounts.length ? (
                                <>
                                    {yodleeAccounts.map((account, i) => (
                                        <CardFooter key={i}>
                                            <h4 className="mb-0">{account.providerName} - <span style={{fontWeight: 'normal'}}>{account.accountName}</span></h4>
                                            {account.partner ? (
                                                <p className="text-sm text-muted mb-0">{t(`ConfirmationPre: Added By Partner`)}</p>
                                            ) : null}
                                            <p className="text-sm mb-0">
                                                {yodleeNeedsVerification(account) ? (
                                                    <span>
                                                        <button className="btn btn-warning btn-sm" onClick={() => setView('Yodlee')}>
                                                            <i className="fas fa-info-circle mr-2 " />
                                                            {t(`ConfirmationPre: Needs Verification`)}
                                                        </button>
                                                    </span>

                                                ) : (
                                                    <span><i className="fas fa-check text-success mr-2 " />{account.accountStatus}</span>
                                                )}
                                            </p>

                                        </CardFooter>
                                    ))}
                                    {mxAccounts.map((member, i) => (
                                        <CardFooter key={i}>
                                            <h4> {member.name}</h4>
                                            {member.partner ? (
                                                <p className="text-sm text-muted mb-0">{t(`ConfirmationPre: Added By Partner`)}</p>
                                            ) : null}
                                            {!member.isPartner && mxMemberNeedsVerification(member) ? (
                                                <p className="text-sm mb-0" onClick={() => setView('MXVerifyIdentity', member.guid, 'ConfirmationPre')}>
                                                    <span className="cursor-pointer text-muted">
                                                        <i className="fas fa-exclamation-triangle text-warning mr-2 " />{t(`ConfirmationPre: Needs Verification`)}
                                                    </span>
                                                </p>
                                            ) : 
                                                <p className="text-sm mb-0">
                                                    <span><i className="fas fa-check text-success mr-2 " />{mxGetConnectionName(member.connection_status)}</span>
                                                </p>
                                            }
                                        </CardFooter>
                                    ))}
                                </>
                            ) : (
                                <CardFooter>
                                    <p className="text-sm mb-0 text-warning font-weight-bold">{t(`ConfirmationPre: You have not logged into any financial accounts.`)}</p>
                                </CardFooter>
                            )}
                            </>
                        : null}
                        
                        {foundCompany.accept_employment ? 
                            <>
                                <CardFooter className="bg-secondary">    
                                    <CardTitle className="mb-0">{t(`ConfirmationPre: Employer Accounts`)} ({pinwheelAccounts.length})</CardTitle>
                                </CardFooter>
                                {pinwheelAccounts.length ? pinwheelAccounts.map((account, i) => (
                                    <CardFooter key={i}>
                                        <h4> {account.platform}</h4>
                                        {account.partner ? (
                                            <p className="text-sm text-muted mb-0">{t(`ConfirmationPre: Added By Partner`)}</p>
                                        ) : null}
                                        {pinwheelNeedsVerification(account, pinwheelAccountList) ? (
                                            <button className="btn btn-warning btn-sm" onClick={() => setView('PinwheelLink')} >
                                                <i className="fas fa-info-circle mr-2 " />
                                                {t(`ConfirmationPre: Needs Verification`)}
                                            </button>
                                        ) : (
                                            <div><i className="fas fa-check text-success mr-2 " />{t(`ConfirmationPre: Connected`)}</div>
                                        )}
                                    </CardFooter>
                                )) : (
                                    <CardFooter>
                                        <p className="text-sm mb-0 text-warning font-weight-bold">{t(`ConfirmationPre: You have not logged into any employer accounts.`)}</p>
                                    </CardFooter>
                                )}
                            </>
                        : null}

                        {foundCompany.accept_auto_policies && foundContact.fize_connection_names && Array.isArray(foundContact.fize_connection_names) ? (
                            <>
                                <CardFooter className="bg-secondary">    
                                    <CardTitle className="mb-0">{t(`ConfirmationPre: Auto Policies`)} ({foundContact.fize_connection_names.length})</CardTitle>
                                </CardFooter>
                                {foundContact.fize_connection_names && foundContact.fize_connection_names.length ? foundContact.fize_connection_names.map((account, i) => (
                                    <CardFooter key={i}>
        
                                        <h4> {account}</h4>
                                        <p className="text-sm mb-0">
                                            <span>
                                                <i className="fas fa-check text-success mr-2 " />
                                                {t(`FIZE: Connected`)}
                                            </span>
                                        </p>
        
                                    </CardFooter>
                                )) : (
                                    <CardFooter>
                                        <p className="text-sm mb-0 text-warning font-weight-bold">
                                            {t(`Fize: You have not connected any auto insurance policies`)}
                                        </p>
                                    </CardFooter>
                                )}
                            </>
                        ) : null}

                        <CardFooter className="text-center">
                            <button  onClick={() => onConfirm(false)} className=" btn btn-success btn-block my-2 btn-lg">
                                {t(`ConfirmationPre: FINISH`)}
                            </button>

                        </CardFooter>
                    </Card>

                    <p 
                        className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" 
                        onClick={() => setView(foundCompany.accept_auto_policies ? 'Fize' :  foundCompany.accept_employment ? 'PinwheelSideHustles' : 'MXApps')}
                        style={{color: companyNameColor}}
                    >
                        <i className="fas fa-arrow-left ml-2 mr-2 " /> 
                        {t(`ConfirmationPre: Back`)}
                    </p>

                </Col>
            </Row>
        </Container>

        </>

    )
}

export default withTranslation()(ConfirmationPre);
