/*
Documentation

this page handles log in for a user based on their email and access code
add via input and the found company coming from the top URL

*/

import { useState } from "react";
import { withTranslation } from 'react-i18next';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, Row, Modal } from "reactstrap";

import _contacts from '_functions/contacts';
import _analytics from '_functions/analytics';

import VerificationAccessIcon from '../_images/VerificationAccess.svg'

import i18next from '../_i18n'

const VerificationAccess = ({foundCompany, onAccessVerified, session, onLanguageChange, companyNameColor, t}) => {

    const iframeCustomStyles = foundCompany.portal_embed_styles ? JSON.parse(JSON.parse(foundCompany.portal_embed_styles)) : {}

    const [showModal, setShowModal] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [errors, setErrors] = useState(null);

    const onVerify = async (e) => {

        e.preventDefault();
        setErrors(null);

        let errors = {};

        if(!identifier) errors.identifier = true;
        if(!accessCode) errors.accessCode = true;

        if(Object.keys(errors).length) return setErrors(errors);

        const verified = await _contacts.verify({
            phone: identifier, 
            email: identifier, 
            access_code: accessCode, 
            company: foundCompany._id 
        })

        if(verified.success) {
            _contacts.setTimestamp({
                contact_id: verified.data._id, 
                company_id: foundCompany._id, 
                access_code: verified.data.access_code, 
                field: 'time_to_login'
            })
            _analytics.increment({portal_session_id: session, field: 'successful_log_ins'})
            onAccessVerified(verified.data)
        } else {
            _analytics.increment({portal_session_id: session, field: 'failed_log_ins'})
            errors.credentials = true;
            return setErrors(errors);
        }

    }

    return (

        <>

        {foundCompany.portal_embed_link ? ( 
            <Container className="mt--7 mt-md--9 pb-9 mb-4 mb-md-5" style={{position: 'relative', top: -20}}>
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                    <p className="text-sm mb-0 text-center"><i className="fas fa-play mr-2 text-warning " /> Watch This Video</p>

                        <div style={iframeCustomStyles}>
                            <div className="video-container z-depth-" style={{overflow: 'visible'}}>
                                <iframe 
                                    id="tutorial-video"
                                    style={{zIndex: 2,}} 
                                    src={foundCompany.portal_embed_link} 
                                    frameBorder="0" 
                                    allow="autoplay; fullscreen; picture-in-picture" 
                                    allowFullScreen 
                                    title="Zap Reports Walkthrough.mp4"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        ) : null}

        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Form role="form" style={{zIndex: 2, position: 'relative'}}>

                        <Card className="bg-white border-0 mb-0 position-relative" >

                            <img src={VerificationAccessIcon} alt="..." className="flow-icon bg-white" />

                            <CardHeader>
                                <CardTitle className="flow-icon-header mb-0">
                                    {t('VerifyAccess: Hello and Welcome!')}
                                </CardTitle>
                            </CardHeader>

                            <CardHeader>
                                <p className="m text-sm"> 
                                    {t('VerifyAccess: Our mission is to make')}{' '}
                                    <b className="text-underline">
                                        {t('VerifyAccess: getting your information')}
                                    </b>{' '}
                                    {t('VerifyAccess: as easy as possible by helping you pull your required documents quickly and easily.')}{' '}
                                </p>

                                <p className="mb-0 text-sm">
                                    {t('VerifyAccess: Please enter the')}{' '}
                                    <b className="text-info">
                                        {t('VerifyAccess: phone number')}{' '}
                                    </b>{' '} 
                                    {t('VerifyAccess: or')}{' '}
                                    <b className="text-info">
                                        {t('VerifyAccess: email address')}{' '}
                                    </b>{' '}
                                    {t('VerifyAccess: you received your invitation from as well as your')}{' '}{' '}
                                    <b className="text-success">
                                        {t('VerifyAccess: 6 digit access code')}{' '}
                                    </b>{' '}
                                    {t('VerifyAccess: to get started.')}{' '}
                                </p>
                            </CardHeader>

                            <CardHeader >

                                <FormGroup>
                                    <label className="form-control-label">
                                        {t('VerifyAccess: Phone / Email*')}
                                    </label>
                                    <Input
                                        name="identifier"
                                        type="text"
                                        value={identifier}
                                        invalid={errors && errors.identifier}
                                        onChange={(e) => setIdentifier(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-control-label">
                                        {t('VerifyAccess: Access Code*')}
                                    </label>
                                    <Input
                                        name="accessCode"
                                        type="password"
                                        value={accessCode}
                                        invalid={errors && errors.accessCode}
                                        onChange={(e) => setAccessCode(e.target.value)}
                                    />
                                </FormGroup>

                            </CardHeader>

                            <CardHeader>
                                <p className="mb-0 text-sm" >
                                    <span onClick={() => setShowModal(true)} className="cursor-pointer">
                                        <i className="fas fa-info-circle text-info mr-2" /> 
                                        {t(`VerifyAccess: Don't know your access code?`)}
                                        
                                    </span>
                                </p>
                            </CardHeader>

                            {errors && errors.credentials ? (
                            <CardHeader>
                                <p className="mb-0 text-sm text-danger font-weight-bold">
                                    {t('VerifyAccess: Invalid Credentials, Please Try Again.')}</p>
                            </CardHeader>
                            ) : null}

                            <CardFooter className="text-center">
                                <button id="loginButton" onClick={onVerify} className="my-2 btn-block btn btn-success btn-lg">
                                    <i className="fas fa-lock mr-2 " /> 
                                    {t('VerifyAccess: Verify My Identity')}
                                </button>
                            </CardFooter>
                        </Card>

                        <p className="text-sm mt-4 text-center cursor-pointer" style={{color: companyNameColor}} onClick={() => onLanguageChange(i18next.language === 'es' ? 'en': 'es')} >
                            {t('VerifyAccess: Language Select')}
                        </p>

                    </Form>

                </Col>
            </Row>
        </Container>

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => setShowModal(false)}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title text-capitalize">
                    <i className="fas fa-info-circle mr-2 text-info " /> 
                    {t('VerifyAccess: Finding Your Access Code')}
                </h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setShowModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <p className="text-sm">
                    {t('VerifyAccess: Your access code is unique to you and can be found in the email or text you received inviting you to this portal.')}
                </p>
                
                <p className="text-sm">
                    {t('VerifyAccess: It is most likely next to the link you clicked to navigate to this page.')}
                </p>

                <p className="mb-0 text-sm">
                    <i className="fas fa-envelope mr-2 text-warning " />
                    {t(`VerifyAccess: If you can't find your access code please contact the company that requested you pull your documents.`)}
                    {' '}{foundCompany.name}
                </p>
            </div>

            <div className="modal-footer text-right ">
                <button className="btn btn-outline-warning" onClick={() => setShowModal(false)}>
                    {t(`VerifyAccess: Close`)}
                </button>
            </div>


        </Modal>

        </>

    )
}

export default withTranslation()(VerificationAccess)

