import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Container, Row } from "reactstrap";

import UtilityCloseIframe from './CloseIframe';

const UtilityAccessDenied = ({t, isIframe, parentDomain}) => {

    return (
        <>
            <Helmet>
                <title>Unauthorized</title>
                <meta name="Unauthorized" content="Unauthorized" />
            </Helmet>

            <UtilityCloseIframe isIframe={isIframe} parentDomain={parentDomain} />
                
            <Container className="mt--9 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">

                        <Card className="bg-white border-0 mb-0 z-depth-4" >

                            <CardHeader>
                                <CardTitle className="mb-0"><i className="fas fa-exclamation-triangle text-danger mr-2" />
                                    {t('UtilityAccessDenied: Access Denied.')}
                                </CardTitle>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm font-weight-bold mb-0">
                                    <span className="text-underline">{t('UtilityAccessDenied: We take your security seriously.')}</span>
                                    <i className="fas fa-lock ml-3 text-dar" /> 
                                </p>
                            </CardHeader>

                            <CardBody>
                                <p className="text-sm mb-0">
                                    {t('UtilityAccessDenied: If you are on this page it means you have been given an incorrect link or did not following the the exact directions to pull your documents.')}
                                </p>

                            </CardBody>

                            <CardFooter>
                                <p className="text-sm mb-0">
                                    {t('UtilityAccessDenied: To proceed please click on your invitation link in either your text messages or email. If you feel this page has been shown in error you may try refreshing your page.')}
                                </p>
                            </CardFooter>

                        </Card>

                    </Col>
                </Row>
            </Container>

            </>

    )
}

export default withTranslation()(UtilityAccessDenied);

