/*
Documentation

this page runs the routing for verifying a user creating mx and pinwheel reports

*/

import keys from 'keys';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Col, Container, Row } from "reactstrap";

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import { getUrlParameter, removeUrlParameters } from 'utils/urls';

// import moment from 'moment';

import ConfirmationModal from 'components/functional/modals/Confirmation'

import VerifyAccess from './Verification/Access'
import VerifyCompany from './Verification/Company'
import VerifyName from './Verification/Name'

import Payment from './Payment'

import MXConnect from './MX/Connect'
import MXApps from './MX/Apps'
import MXVerifyIdentity from './MX/VerifyIdentity'
import MXVerifyExtended from './MX/VerifyExtended'
import MXVerifyStatements from './MX/VerifyStatements'
import MXModal from './MX/Modal'
import MXUpdateConnection from './MX/UpdateConnection'

import PinwheelLink from './Pinwheel/Link'
import PinwheelSideHustles from './Pinwheel/SideHustles'

import Yodlee from './Yodlee/Yodlee';
import YodleeModal from './Yodlee/YodleeModal';

import Fize from './Fize'

import ConfirmationFinal from './Confirmation/Final'
import ConfirmationPre from './Confirmation/Pre'

import UtilityStatusRejected from './Utility/StatusRejected'
import UtilityAccessDenied from './Utility/AccessDenied'
import UtilityError from './Utility/Error'
import UtilityCloseIframe from './Utility/CloseIframe'

import _events from '_functions/events'
import _contacts from '_functions/contacts'
import _mx_initial_connections from  '_functions/mx_initial_connections'
import _integrations from '_functions/integrations'
import _companies from '_functions/companies';
import _credit_events from '_functions/credit_events';
import _documents from '_functions/documents';

import _analytics from '_functions/analytics'
import _partner_errors from '_functions/partner_errors'
import deviceInfo from 'utils/deviceInfo';

import { getYodleeConfigName } from './_helpers';
import { getNextStep } from './_helpers/getOrder';

import Cookies from 'universal-cookie';

import i18next from './_i18n';

const Reports = ({match, device, t}) => {

    // const [stepCount,               setStepCount]                 = useState(0);
    const [err,                     setErr]                       = useState(false);
    const [view,                    setView]                      = useState('VerifyAccess');
    const [redirectAfterVerify,     setRedirectAfterVerify]       = useState(false);
    const [foundContact,            setFoundContact]              = useState({});
    const [foundCompany,            setFoundCompany]              = useState({});
    const [accessDenied,            setAccessDenied]              = useState(false);
    const [session,                 setSession]                   = useState(null);
    const [skippedCompanyMatch,     setSkippedCompanyMatch]       = useState(false);

    const [mxShowModal,             mxSetShowModal]               = useState(false)
    const [mxConnectLoaded,         mxSetConnectLoaded]           = useState(false)
    const [mxShowModalExit,         mxSetShowModalExit]           = useState(false)
    const [mxModalWidth,            mxSetModalWidth]              = useState(435)
    const [mxMemberToDelete,        mxSetMemberToDelete]          = useState(null)
    const [mxAccounts,              mxSetAccounts]                = useState(null);
    const [mxMemberToVerify,        mxSetMemberToVerify]          = useState(false);

    const [yodleeAccounts,          yodleeSetAccounts]            = useState(null);
    const [yodleeShowModal,         yodleeSetShowModal]           = useState(null);
    const [yodleeConnectLoaded,     yodleeSetConnectLoaded]       = useState(null);
    const [yodleeAccountToDelete,   yodleeSetAccountToDelete]     = useState(false);

    const [pinwheelAccounts,        pinwheelSetAccounts]          = useState(null);
    const [pinwheelToken,           pinwheelSetToken]             = useState(null);
    const [pinwheelShowModal,       pinwheelSetShowModal]         = useState(null);
    const [pinwheelPlatform,        pinwheelSetPlatform]          = useState(null);
    const [pinwheelAccount,         pinwheelSetAccount]           = useState({});
    const [pinwheelAccountToDelete, pinwheelSetAccountToDelete]   = useState(null);
    const [pinwheelAccountList,     pinwheelSetAccountList]       = useState(null);
    
    const [fizeHandler,     setFizeHandler]       = useState(null);
    const [fizeLoading,     setFizeLoading]       = useState(null);
    const [fizeNewConnections,  setFizeNewConnections]       = useState([]);

    const [parentDomain, setParentDomain]     = useState(false);
    const [isIframe, setIsIframe]             = useState(false);

    const onLanguageChange = useCallback((language) => {
        const cookies = new Cookies();
        cookies.set('lang', language, { path: '/' });

        i18next.changeLanguage(language, (err, t) => {
            if (err) return console.log('something went wrong with language change', err);
        });
    }, [])

    const onSetView = useCallback((view, member) => {
        const nextView = getNextStep({
            accept_financial: foundCompany.accept_financial,
            accept_employment: foundCompany.accept_employment,
            accept_auto_policies: foundCompany.accept_auto_policies,
        }, view)
        if(nextView === 'MXVerifyIdentity') {
            mxSetMemberToVerify(member)
        }

        if(nextView === 'ConfirmationFinal' && isIframe && parentDomain) {
            window.parent.postMessage({
                type: 'zapReportsDocuments',
                event: 'onSubmit',
                data: {}
            }, parentDomain)
        }
        setView(nextView)

    },  [foundCompany.accept_financial, foundCompany.accept_employment, foundCompany.accept_auto_policies, isIframe, parentDomain])

    // checks if the contact has access AND refreshes the contact set to state
    const verifyAccess = useCallback(async () => {
        const verified = await _contacts.verify({
            phone: foundContact.phone, 
            email: foundContact.email, 
            access_code: foundContact.access_code, 
            company: foundCompany._id 
        })
        if(verified.success) {
            setFoundContact(verified.data)
        } else {
            return setAccessDenied(true);
        }
    }, [foundContact.phone, foundContact.email, foundContact.access_code, foundCompany._id, setFoundContact, setAccessDenied])


    const configureFize = useCallback((_foundContact, company_id) => {

        const script = document.createElement("script");
        if(keys.FIZE_CLIENT_ID === 'IrgkzNSg7AxPOhMFCFCHI8xhkMR08CMD') {
            script.src = "https://cdn.getfize.com/sdk/v1.0/getfize-staging.js";
        } else {
            script.src = "https://cdn.getfize.com/sdk/v1.0/getfize.js";
        }

        script.async = true;
        document.body.appendChild(script);

        let isProcessing = false

        const configure = (tries = 0) => {
            if(tries > 50) return;
            if(!window.FIZE) return setTimeout(() => {
                configure(tries + 1)
            }, 250)
            var _handler = window.FIZE.configure({ 
                client_id: keys.FIZE_CLIENT_ID,
                onSuccess: async function(metadata) {
                    if(isProcessing) return;
                    isProcessing = true;
                    const { connectionId } = metadata;
                    toggleAlertBS(false, 'You have successfully connected your auto policy!')
                    setFizeLoading(true)
                    await _integrations.fize.pullReport({
                        contact_id: _foundContact._id,
                        access_code: _foundContact.access_code,
                        company_id: company_id,
                        connection_id: connectionId
                    })
                    const _connections = JSON.parse(JSON.stringify(fizeNewConnections))
                    _connections.push(metadata.selectedCarrier)
                    setFizeNewConnections(_connections)
                    setFizeLoading(false)
                    verifyAccess()
                    isProcessing = false;
           
                },
            });
            setFizeHandler(_handler)
        }
        configure()
    }, [verifyAccess, fizeNewConnections])

    const statusIsRejected = useCallback((contact) => {
        if(!contact) return true;

        if(contact.report_status === 'null' || contact.report_status === 'complete') {
            if(!contact.contact_purchases && !foundCompany.allow_docs_return) {
                return true;
            }
        }

        return false;
    }, [foundCompany.allow_docs_return])

    const renderNonPaymentMessage = useCallback(() => {
        if(foundContact.contact_purchases) {
            toggleAlertBS(true,  t(`Main: Your account requires payment before proceeding. Please refresh your page and try again.`))
        } else {
            toggleAlertBS(true, t(`Main: This account has been temporarily suspended due to lack of funds.`))
        }
    }, [foundContact.contact_purchases, t])

    const onAccessVerified = useCallback((contact, company) => {

        setFoundContact(contact);

        const accounts1 = contact.pinwheel_accounts ? contact.pinwheel_accounts : [];
        const accounts2 = contact.partner && contact.partner.pinwheel_accounts ? contact.partner.pinwheel_accounts : [];

        accounts2.forEach(a => a.partner = true)
        pinwheelSetAccounts(accounts1.concat(accounts2))

        onSetView('VerifyCompany');

        if(!statusIsRejected(contact)) {
            _contacts.setStatus({
                contact_id: contact._id, 
                company_id: company ? company._id : foundCompany._id, 
                access_code: contact.access_code, 
                report_status: 'in progress'
            })
            _events.create({
                contact: contact._id, 
                company: company ? company._id : foundCompany._id, 
                access_code: contact.access_code, 
                type: 'system', 
                body: `Successfully logged in and validated credentials.`
            })
        }
        
    }, [foundCompany._id, onSetView, statusIsRejected])

    const needsPurchase = useCallback(() => {
        const days90 = 86400 * 90;
        const now = Math.floor(new Date() / 1000);

        const purchased = foundContact.contact_purchased_at ? foundContact.contact_purchased_at : 0;

        // in the future needsPurchase should be obsolete as contact_purchased_at will
        // get set to 0 at the end of 90 days via cron jobs
        const needsPurchase = (purchased + days90) < now ? true : false;
        const shouldPurchase = foundContact.contact_purchases ? true : false;

        if(shouldPurchase && needsPurchase) {
            return true
        }
        return false;
    }, [foundContact])

    const onNameVerified = useCallback(() => {

        if(needsPurchase()) {
            onSetView('Payment');
        } else {
            // onSetView('Yodlee');
            onSetView('MXConnect');
            toggleAlertBS(false,  t(`Main: You're ready to start pulling your information. Make sure you do not refresh or close this page before you complete the report process.`))
            _events.create({
                contact: foundContact._id, 
                company: foundCompany._id, 
                access_code: foundContact.access_code, 
                type: 'system', 
                body: `Confirmed legal name.`
            })
        }

       
    }, [foundContact, foundCompany._id, onSetView, needsPurchase, t])

    const onCompanyVerified = useCallback((updatedContact) => {
        if(updatedContact.bypass_name_verification) {
            onNameVerified() 
        } else if(foundCompany.bypass_name_verification && updatedContact.given_name && updatedContact.family_name) {
            onNameVerified()
        } else {
            onSetView('VerifyName');
        }

        configureFize(updatedContact, foundCompany._id)
        setFoundContact(updatedContact)
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'system', 
            body: `Confirmed company match.`
        })
    }, [foundContact._id, foundContact.access_code, foundCompany._id, foundCompany.bypass_name_verification, onSetView, onNameVerified, configureFize])

    /*********************************************
    **********************************************
    ***********************************************
    PINWHEEL FUNCTIONS
    **********************************************
    **********************************************
    **********************************************/

    const pinwheelNeedsVerification = useCallback((account, foundAccounts) => {
        if(!foundAccounts) return [];
        const _account = foundAccounts.find(a => a.id === account.account_id)

        // if we don't have a found account the account was just added and is active
        if(!_account) return false;

        if(_account.monitoring_status === 'active') return false;
        if(_account.monitoring_status === 'unavailable') return false;

        return true;

    }, []);

    const pinwheelFetchAccounts = useCallback(async () => {
        const foundAccounts = await _integrations.pinwheel.accounts.list({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
        })

        if(foundAccounts.data) {
            pinwheelSetAccountList(foundAccounts.data)
        } else {
            setErr(true)
        }
    }, [foundContact._id, foundContact.access_code, foundCompany._id]);

    const pinwheelOnEvent = useCallback((event, payload) => {

        switch (event) {
            case 'exit':
                pinwheelSetShowModal(false)
                break;
        
            case 'select_platform':
                pinwheelSetPlatform(payload.selectedPlatformName)
                break;
            case 'select_employer':
                pinwheelSetPlatform(payload.selectedEmployerName)
                break;
            case 'success':
                pinwheelSetAccount({account_id: payload.accountId, platform_id: payload.platformId})
                break;
        
            default:
                break;
        }
    }, [])

    const pinwheelOpenLink = useCallback(async (account_id, employer_id, employerOrPlatformName) => {

        if(pinwheelToken) {
            if(employer_id) {
                pinwheelSetToken(null)
                pinwheelSetShowModal(false)
            } else {
                return pinwheelSetShowModal(true)
            }
        }

        const token = await _integrations.pinwheel.link_token({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            account_id: account_id ? account_id : undefined,
            employer_id: employer_id ? employer_id : undefined,
        })

        if(employerOrPlatformName) pinwheelSetPlatform(employerOrPlatformName)

        if(token.data && token.data.token) {
            pinwheelSetToken(token.data.token)
            pinwheelSetShowModal(true)

            return _events.create({
                contact: foundContact._id, 
                company: foundCompany._id, 
                access_code: foundContact.access_code, 
                type: 'system', 
                body: `Began search for employer information.`
            })
        }

        if(token.message && token.message[0] && token.message[0] === 'staging') {
            return  toggleAlertBS(true,  t(`Main: Document Generation Is Not Allowed In The Staging Environment.`))
        } else if(token.message && token.message[0] && token.message[0] === 'The terms of use must be accepted before pulling documents.') {
            toggleAlertBS(true, t(`Main: The terms of use must be accepted before pulling documents.`))
        } else {
            return toggleAlertBS(true, t(`Main: We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`))
        }
        
    }, [foundContact._id, foundContact.access_code, foundCompany._id, t, pinwheelToken])

    const pinwheelOnSetAccounts = useCallback((newAccounts) => {
        _contacts.setEmployment({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            accounts: newAccounts
        })
        pinwheelSetAccounts(newAccounts)
    }, [foundContact._id, foundCompany._id, foundContact.access_code])

    const pinwheelCheckCredits = useCallback(async () => {
        const check = await _credit_events.check(foundContact._id, 'pinwheel');
        if(!check.data || (check.data && !check.data.allowed)) {
            pinwheelSetToken(false);
            renderNonPaymentMessage();
            const modal = document.getElementById('pinwheel-portal')
            modal.parentNode.removeChild(modal);

        }
    }, [foundContact._id, pinwheelSetToken, renderNonPaymentMessage])


    const pinwheelOnDeleteAccount = useCallback(async () => {

        let a = [...pinwheelAccounts];
        a = a.filter(ac => ac.platform_id !== pinwheelAccountToDelete.platform_id)
        
        pinwheelOnSetAccounts(a);

        // stop pinwheel monitoring
        _integrations.pinwheel.accounts.delete({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            account_id: pinwheelAccountToDelete.account_id
        })
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'action', 
            body: `Removed the employer: ${pinwheelAccountToDelete.platform}.`
        })

    }, [foundContact, foundCompany, pinwheelAccountToDelete, pinwheelAccounts, pinwheelOnSetAccounts])

    /*********************************************
    **********************************************
    **********************************************
    YODLEE FUNCTIONS
    **********************************************
    **********************************************
    **********************************************/

    const yodleeNeedsVerification = useCallback((account) => {

        let needs = false;

        account.dataset.forEach(set => {
            if(
                set.additionalStatus === 'PARTIAL_DATA_RETRIEVED' ||
                set.additionalStatus === 'DATA_RETRIEVAL_FAILED' ||
                set.additionalStatus === 'ADDL_AUTHENTICATION_REQUIRED' ||
                set.additionalStatus === 'INCORRECT_CREDENTIALS' ||
                set.additionalStatus === 'INVALID_ADDL_INFO_PROVIDED' ||
                set.additionalStatus === 'REQUEST_TIME_OUT' ||
                set.additionalStatus === 'SITE_BLOCKING_ERROR' ||
                set.additionalStatus === 'UNEXPECTED_SITE_ERROR' ||
                set.additionalStatus === 'SITE_UNAVAILABLE' ||
                set.additionalStatus === 'TECH_ERROR' ||
                set.additionalStatus === 'SITE_SESSION_INVALIDATED' ||
                set.additionalStatus === 'NEW_AUTHENTICATION_REQUIRED' ||
                set.additionalStatus === 'CONSENT_REQUIRED' ||
                set.additionalStatus === 'CONSENT_EXPIRED' ||
                set.additionalStatus === 'CONSENT_REVOKED' ||
                set.additionalStatus === 'INCORRECT_OAUTH_TOKEN'
            ) {
                needs = true;
            }
        })

        return needs;

    }, [])

    const yodleeFetchAccounts = useCallback(async () => {

        const request = await _integrations.yodlee.accounts.find({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
        });

        if(request.data) {
            yodleeSetAccounts(request.data);
        } else {
            setErr(true)
        }
    }, [foundContact._id, foundContact.access_code, foundCompany._id])

    const yodleeOnDeleteAccount = useCallback(async (account) => {

        toggleStandardLoader(true)

        await _integrations.yodlee.accounts.delete({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            account_id: account.id
        });

        yodleeFetchAccounts();

        toggleStandardLoader(false)

    }, [foundCompany._id, foundContact.access_code, foundContact._id, yodleeFetchAccounts])

    

    const yodleeCloseFastLink = useCallback(() => {
        window.fastlink.close();
        yodleeSetShowModal(false);
        yodleeSetConnectLoaded(false)
    }, [])

    const yodleeCheckCredits = useCallback(async () => {
        const check = await _credit_events.check(foundContact._id, 'mx');
        if(!check.data || (check.data && !check.data.allowed)) {
            yodleeCloseFastLink();
            renderNonPaymentMessage();
        }
    }, [foundContact._id, yodleeCloseFastLink, renderNonPaymentMessage])

    
    const yodleeOpenFastLink = useCallback(async (providerAccountId) => {    

        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'system', 
            body: `Began search and authorization of banking institutions.`
        })

        yodleeCheckCredits();

        yodleeSetShowModal(true);

        const request = await _integrations.yodlee.tokens.create({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
        });

        if(!request.data) {
            yodleeCloseFastLink()
            return toggleAlertBS(true, t(`Main: We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`))
        }

        setTimeout(() => {
            yodleeSetConnectLoaded(true)
        }, 2000)

        const configName = getYodleeConfigName(foundCompany)

        const params = {
            configName : configName,
        }

        if(providerAccountId) {
            params.providerAccountId = providerAccountId;
            params.flow = 'edit';
        }

        if(device.currentScreenWidth <= 768) {
            params.isIframeMounted = true;
        }

        window.fastlink.open({
            forceIframe: device.currentScreenWidth <= 768 ? true : false,
            fastLinkURL: keys.FAST_LINK_URL,
            accessToken: `Bearer ${request.data}`,
            params,
            onSuccess: function (data) {
                // will be called on success. For list of possible message, refer to onSuccess(data) Method.
                _documents.connectedYodlee({
                    contact: foundContact._id, 
                    company: foundCompany._id, 
                    access_code: foundContact.access_code, 
                    provider_id: data.providerId.toString(), 
                })
               
            },
            onError: function (data) {
                console.log(data);
                let log = 'unparsable error';
                try {
                    log = JSON.stringify(data)
                } catch(e){}

                _partner_errors.create({
                    company   : foundCompany._id,
                    contact   : foundContact._id,
                    partner   : 'yodlee',
                    log       : log,
                    type      : 'onError',
                })
            },
            onClose: function (data) {
                yodleeFetchAccounts();
                yodleeCloseFastLink()
            },
            onEvent: function (data) {
                // will be called on intermittent status update.
            }
        }, 'container-fastlink');

    }, [foundContact._id, foundContact.access_code, foundCompany, yodleeCloseFastLink, yodleeFetchAccounts, device.currentScreenWidth, yodleeCheckCredits, t])

    const yodleeGetConnectionName = useCallback((status) => {
        if(status === 'ACTIVE') return t('Main: Connected')
        return t(`Main: Needs Verification`)
    }, [t])

    /*********************************************
    **********************************************
    **********************************************
    MX FUNCTIONS
    **********************************************
    **********************************************
    **********************************************/

    const mxMemberNeedsVerification = useCallback((member) => {
        
        // always let partner pass
        if(member.partner) return false;

        if(member.connection_status !== 'CONNECTED') return true;
        if(!foundContact.mx_members) return true;
        const foundMember = foundContact.mx_members.find(m => m.member_guid === member.guid);
        if(!foundMember) return true;

        if(foundMember.identity_status === 'null') return true;
        if(foundMember.extended_status === 'null') return true;
        if(foundCompany.accept_real_statements && foundMember.statement_status === 'null') return true;

        return false;

    }, [foundContact, foundCompany])

    const mxFetchAccounts = useCallback(async () => {

        const foundMembers = await _integrations.mx.members.list({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
        });

        if(foundMembers.data) {
            mxSetAccounts(foundMembers.data);
        } else {

            const m = foundMembers.message
            // if a user has not tried to use mx let them pass
            if(m && m.error && m.error.message === "We were unable to find the record you requested.") {
                return mxSetAccounts([]);
            }

            setErr(true)
        }

    }, [foundContact._id, foundContact.access_code, foundCompany._id])

    const mxGetConnectionName = useCallback((name) => {
        if(!name) return;
        try {
            const formattedName = name.slice(0,1) + name.slice(1, name.length).toLowerCase();
            return t(`Main: MX Status: ${formattedName}`)
        } catch(e) {
            return name
        }
    }, [t])

    const mxCloseConnect = useCallback(async () => {

        if(view === 'MXUpdateConnection' || view === 'MXVerifyIdentity' || view === 'MXVerifyExtended' || view === 'MXVerifyStatements') {
            onSetView(redirectAfterVerify ? redirectAfterVerify : 'MXConnect')
        }

        mxSetShowModal(false);
        mxSetConnectLoaded(false);
        mxSetModalWidth(435);
        mxSetShowModalExit(false);
        // setView('VerifyMembers')
        var connectIFrame = document.querySelector('iframe[title="Connect"]')
        if(connectIFrame) connectIFrame.remove();

    }, [view, onSetView, redirectAfterVerify])

    const mxOpenConnect = useCallback(async (current_member_guid, current_institution_code) => {
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'system', 
            body: `Began search and authorization of financial institutions.`
        })

        mxSetShowModal(true);

        const url = await _integrations.mx.request_url({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            current_institution_code,
            current_member_guid
        })

        var mxConnect = new window.MXConnect({
            id: "connect-launcher",
            iframeTitle: "Connect",
            config: { ui_message_version: 4 },
            targetOrigin: "*",
        })

        // we have success, load the widget
        if(url.data && url.data.url) {
            return mxConnect.load(url.data.url);
        }

        // we have an error, send the correct message
        if(url.message && url.message[0] && url.message[0].retry) {
            // const time = moment.unix(url.message[0].retry).format('h:mm A')
            toggleAlertBS('info',  t(`Main: We are getting your account ready, please try again in a few minutes.`))
        } else if(url.message && url.message[0] && url.message[0] === 'The terms of use must be accepted before pulling documents.') {
            toggleAlertBS(true, t(`Main: The terms of use must be accepted before pulling documents.`))
        } else if(url.message && url.message[0] && url.message[0] === 'staging') {
            toggleAlertBS(true, t(`Main: Document Generation Is Not Allowed In The Staging Environment.`))
        } else {
            toggleAlertBS(true, t(`Main: We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`))
        }

        mxSetShowModal(false);

    }, [foundCompany._id, foundContact._id, foundContact.access_code, t])

    const mxCheckCredits = useCallback(async () => {
        const check = await _credit_events.check(foundContact._id, 'mx');
        if(!check.data || (check.data && !check.data.allowed)) {
            mxCloseConnect();
            renderNonPaymentMessage();
        }
    }, [foundContact._id, mxCloseConnect, renderNonPaymentMessage])

    const mxHandleEvent = useCallback((event) => {
        if (event.data.mx) {
            switch (event.data.type) {
                case 'mx/connect/loaded':
                    mxSetConnectLoaded(true)
                    break;
                case 'mx/connect/memberConnected':

                    _mx_initial_connections.create({
                        member_guid: event.data.metadata.member_guid,
                        contact_id: foundContact._id, 
                        company_id: foundCompany._id, 
                        access_code: foundContact.access_code, 
                    })

                    // if these are not null they should be a member guid
                    const identity = getUrlParameter('identity')
                    const extended = getUrlParameter('extended')
                    const statement = getUrlParameter('statement')

                    if(identity) {
                        onSetView('MXVerifyIdentity', identity );
                    } else if(extended) {
                        onSetView('MXVerifyExtended', extended );
                    } else if(statement) {
                        onSetView('MXVerifyStatements', statement );
                    } else {

                        // this means we are on either MXConnect or MX Apps, if so set the redirect after to go back to the page it was on
                        // force a redirect to verify identity
                        if(view !== 'MXVerifyIdentity' && view !== 'MXVerifyExtended' && view !== 'MXVerifyStatements') {
                            setRedirectAfterVerify(view)
                            onSetView('MXVerifyIdentity', event.data.metadata.member_guid );
                        // move from identity to statements
                        } else if(view === 'MXVerifyIdentity') {
                            onSetView('MXVerifyStatements', event.data.metadata.member_guid );
                        // move from statements to extending history
                        } else if(view === 'MXVerifyStatements') {
                            onSetView('MXVerifyExtended', event.data.metadata.member_guid)
                        // move from history back to the page were on to begin with
                        } else if(view === 'MXVerifyExtended') {
                            onSetView(redirectAfterVerify ? redirectAfterVerify : 'MXConnect')
                            _documents.connectedMX({
                                contact: foundContact._id, 
                                company: foundCompany._id, 
                                access_code: foundContact.access_code, 
                                member_guid: mxMemberToVerify, 
                            })
                        }
                    
                    }

                    // close widget after each connection
                    mxCloseConnect();

                    break;
                case 'mx/connect/stepChange':
                    if(event.data.metadata.current === 'search' && event.data.metadata.previous === 'disclosure') {
                        mxSetModalWidth(435)
                    }
                    break;

                case 'mx/connect/createMemberError':
                    console.log(event.data);
                    let log1 = 'unparsable error';
                    try {
                        log1 = JSON.stringify(event.data)
                    } catch(e){}
    
                    _partner_errors.create({
                        company   : foundCompany._id,
                        contact   : foundContact._id, 
                        partner   : 'mx',
                        log       : log1,
                        type: 'mx/connect/createMemberError'
                    })
                    break;
                case 'mx/connect/oauthError':
                    console.log(event.data);
                    let log2 = 'unparsable error';
                    try {
                        log2 = JSON.stringify(event.data)
                    } catch(e){}
    
                    _partner_errors.create({
                        company   : foundCompany._id,
                        contact   : foundContact._id, 
                        partner   : 'mx',
                        log       : log2,
                        type: 'mx/connect/oauthError'
                    })
                    break;
            
                case 'mx/connect/memberStatusUpdate':
                    console.log(event.data);
                    let log3 = 'unparsable error';
                    try {
                        log3 = JSON.stringify(event.data)
                    } catch(e){}
    
                    _partner_errors.create({
                        company   : foundCompany._id,
                        contact   : foundContact._id, 
                        partner   : 'mx',
                        log       : log3,
                        type: 'mx/connect/memberStatusUpdate'
                    })
                    break;
            
                default: break;
            }
        }
    }, [onSetView, view, mxCloseConnect, redirectAfterVerify, foundContact._id, foundCompany._id, foundContact.access_code, mxMemberToVerify])

    const mxOnDeleteMember = useCallback(async (memberToDelete) => {

        toggleStandardLoader(true)

        await _integrations.mx.members.delete({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            member_id: memberToDelete.guid
        });

        mxSetMemberToDelete(null)
        mxFetchAccounts();
        toggleStandardLoader(false)

    }, [mxFetchAccounts, foundCompany._id, foundContact.access_code, foundContact._id])
    
    /*********************************************
    **********************************************
    **********************************************
    SESSIONS
    **********************************************
    **********************************************
    **********************************************/

    const createSession = useCallback(async () => {
        const session = await _analytics.createSession({
            company_id                : foundCompany._id,
            timezone                  : Intl.DateTimeFormat().resolvedOptions().timeZone,
            hour_of_day               : new Date().getHours(),
            day_of_week               : new Date().getDay(),
            last_component_accessed   : 'VerifyAccess',
            device                    : deviceInfo.init()
        })
        if(session.data) setSession(session.data._id);
    }, [foundCompany._id])

    const onMessageReceived = useCallback((message) => {
        if(message && message.data && message.data.type === 'zapReportsDocuments') {
            if(message.data.event === 'onLoad') {
                setParentDomain(message.data.data.domain)
            }
        }
    }, [])

    /*********************************************
    **********************************************
    **********************************************
    EFFECTS
    **********************************************
    **********************************************
    **********************************************/

    useEffect(() => {
        if(window.location !== window.parent.location) {
            setIsIframe(true);
            window.addEventListener("message", onMessageReceived)
        } 

        return () => {
            if(window.location !== window.parent.location) {
                setIsIframe(true);
                window.removeEventListener("message", onMessageReceived)
            } 
        }

    }, [onMessageReceived])

    useEffect(() => {
        if(foundCompany._id) createSession();
    }, [foundCompany._id, createSession])

    useEffect(() => {
        if(session) {
            const intervalTime = 2000;

            const interval = setInterval(() => {
                _analytics.pollComponent({
                    company_id          : foundCompany._id,
                    portal_session_id   : session,
                    component           : view,
                    poll_interval       : intervalTime / 1000,
                    contact_id          : foundContact._id,
                })
            }, intervalTime)

            return () => {
                clearInterval(interval);
            }
        }
    }, [session, foundContact._id, foundCompany._id, view])

    useEffect(() => {
        if(pinwheelToken) pinwheelCheckCredits()
    }, [pinwheelCheckCredits, pinwheelToken])

    useEffect(() => {

        if(pinwheelAccount.account_id && pinwheelAccount.platform_id && pinwheelPlatform) {
            const a = [...pinwheelAccounts];
            a.push({
                account_id: pinwheelAccount.account_id, 
                platform_id: pinwheelAccount.platform_id, 
                platform: pinwheelPlatform
            });
            _documents.connectedPinwheel({
                contact: foundContact._id, 
                company: foundCompany._id, 
                access_code: foundContact.access_code, 
                pinwheel_accounts: [{
                    platform: pinwheelPlatform,
                    account_id: pinwheelAccount.account_id
                }]
            })
            pinwheelOnSetAccounts(a)
            // setView('VerifyPinwheel');
            _events.create({
                contact: foundContact._id, 
                company: foundCompany._id, 
                access_code: foundContact.access_code, 
                type: 'system', 
                body: `Authorized the employer: ${pinwheelPlatform}.`
            })
            pinwheelSetAccount({})
            pinwheelSetPlatform(false)
            pinwheelFetchAccounts();
            pinwheelSetShowModal(false);
            pinwheelSetToken(false);
        }

    }, [setView, foundContact, foundCompany._id, pinwheelAccount, pinwheelAccounts, pinwheelFetchAccounts, pinwheelOnSetAccounts, pinwheelPlatform ]);

    useEffect(() => {
        if(mxShowModal === true) {
            mxCheckCredits();
            window.addEventListener('message', mxHandleEvent)
        } else {
            window.removeEventListener('message', mxHandleEvent)
        }
        return () => {
            window.removeEventListener('message', mxHandleEvent)
        }
    }, [mxShowModal, mxCheckCredits, mxHandleEvent])


    useEffect(() => {

        const fetchCompany = async () => {

            let name = match.params.company_name;
            if(name) name = name.replace(/\+/g, ' ')

            const company = await _companies.findByName(name);

            if(company.data) {

                if(window.location !== window.parent.location) {    
                    // if we are an iframe return access denied if the docmain has not been whitelisted
                    // example whitelist: http://localhost:3000/
                    var url = (window.location !== window.parent.location) ? document.referrer: document.location.href;
                    if(!company.data.authorized_embed_domain || (company.data.authorized_embed_domain && !company.data.authorized_embed_domain.includes(url)) ) {
                      
                        setFoundCompany(company.data)
                        return setAccessDenied(true)
                    }
                } 

                if(company.data.branding && company.data.branding.color) {
                    document.body.style.background = company.data.branding.color;
                }

                const identifier = getUrlParameter('i')
                const access_code = getUrlParameter('a');

                if(identifier && access_code && company.data.accept_quick_links) {
                    const verified = await _contacts.verify({
                        phone: identifier, 
                        email: identifier, 
                        access_code, 
                        company: company.data._id 
                    })
                    if(verified.data) {
                        _contacts.setTimestamp({
                            contact_id: verified.data._id, 
                            company_id: company.data._id, 
                            access_code: verified.data.access_code, 
                            field: 'time_to_login'
                        })
                        setSkippedCompanyMatch(true)
                        onAccessVerified(verified.data, company.data)
                    } else {
                        await _analytics.createSession({
                            company_id                : null,
                            timezone                  : Intl.DateTimeFormat().resolvedOptions().timeZone,
                            hour_of_day               : new Date().getHours(),
                            day_of_week               : new Date().getDay(),
                            last_component_accessed   : 'Unauthorized',
                            device                    : deviceInfo.init(),
                            invalid_url               : window.location.href
                        })
                        setFoundCompany('not found')
                        setAccessDenied(true)
                    }
            
                }
                
                setFoundCompany(company.data)
                removeUrlParameters()

            } else {
                await _analytics.createSession({
                    company_id                : null,
                    timezone                  : Intl.DateTimeFormat().resolvedOptions().timeZone,
                    hour_of_day               : new Date().getHours(),
                    day_of_week               : new Date().getDay(),
                    last_component_accessed   : 'Unauthorized',
                    device                    : deviceInfo.init(),
                    invalid_url               : window.location.href
                })
                setFoundCompany('not found')
                setAccessDenied(true)
               
            }

        }

        fetchCompany()

    }, [match.params.company_name, onAccessVerified])

    if(foundCompany === 'not found') return <Redirect to="/" />;
    if(!foundCompany._id) return <div />

    const logo = foundCompany.branding && foundCompany.branding.logo ? foundCompany.branding.logo : keys.LOGO;
    const companyNameColor = foundCompany.branding && foundCompany.branding.name_color ? foundCompany.branding.name_color : '#525f7f';
    const companyLogoWidth = foundCompany.branding && foundCompany.branding.logo_width ? parseInt(foundCompany.branding.logo_width) : 300;
    const hideName = foundCompany.branding && foundCompany.branding.hide_name ? foundCompany.branding.hide_name : false;

    return (
        <>

			<Helmet>
				<title>{foundCompany ? `${foundCompany.name} - Powered By Zap Reports` : 'Powered By Zap Reports'}</title>
				<meta name="description" content={foundCompany ? `${foundCompany.name} - Powered By Zap Reports` : 'Zap Reports'} />
			</Helmet>

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} />
                                {!hideName ? (
                                    <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{foundCompany.name}</h3>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {!accessDenied ? <UtilityCloseIframe isIframe={isIframe} parentDomain={parentDomain} /> : null }

            {accessDenied ? (
                <UtilityAccessDenied isIframe={isIframe} parentDomain={parentDomain} />
            ) : err ? (
                <UtilityError />
            ) : view === 'VerifyAccess' ? (
                <VerifyAccess 
                    onLanguageChange={onLanguageChange}
                    onAccessVerified={onAccessVerified} 
                    foundCompany={foundCompany}
                    session={session}
                    companyNameColor={companyNameColor}
                />
            ) : view === 'VerifyCompany' ? (
                statusIsRejected(foundContact) ? (
                    <UtilityStatusRejected 
                        foundCompany={foundCompany}
                    />
                ) : (
                    <VerifyCompany 
                        onCompanyVerified={onCompanyVerified} 
                        foundCompany={foundCompany}
                        foundContact={foundContact}
                        companyNameColor={companyNameColor}
                        onLanguageChange={onLanguageChange}
                        skippedCompanyMatch={skippedCompanyMatch}
                    />
                )
            ) : view === 'VerifyName' ? (
                <VerifyName 
                    onNameVerified={onNameVerified} 
                    foundContact={foundContact}
                    setView={onSetView}
                    session={session}
                />
            ) : view === 'Payment' ? (
                <Payment 
                    foundContact={foundContact}
                    onSetView={onSetView}
                />
            ) : view === 'Yodlee' ? (
                <Yodlee 
                    setView={onSetView}
                    yodleeNeedsVerification={yodleeNeedsVerification}
                    yodleeAccounts={yodleeAccounts}
                    yodleeOpenFastLink={yodleeOpenFastLink}
                    yodleeGetConnectionName={yodleeGetConnectionName}
                    yodleeSetAccountToDelete={yodleeSetAccountToDelete}
                    yodleeFetchAccounts={yodleeFetchAccounts}
                />
            ) : view === 'MXConnect' ? (
                <MXConnect 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    setFoundContact={setFoundContact}
                    setAccessDenied={setAccessDenied}
                    verifyAccess={verifyAccess}
                    mxMemberNeedsVerification={mxMemberNeedsVerification}
                    mxFetchAccounts={mxFetchAccounts}
                    mxAccounts={mxAccounts}
                    mxGetConnectionName={mxGetConnectionName}
                    mxOpenConnect={mxOpenConnect}
                    mxSetMemberToDelete={mxSetMemberToDelete}
                    yodleeFetchAccounts={yodleeFetchAccounts}
                    yodleeAccounts={yodleeAccounts}
                />
            ) : view === 'MXUpdateConnection' ? (
                <MXUpdateConnection 
                    mxMemberToVerify={mxMemberToVerify}
                    mxOpenConnect={mxOpenConnect}
                />
            ) : view === 'MXVerifyIdentity' ? (
                <MXVerifyIdentity 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    mxMemberToVerify={mxMemberToVerify}
                    redirectAfterVerify={redirectAfterVerify}
                    mxOpenConnect={mxOpenConnect}
                />
            ) : view === 'MXVerifyExtended' ? (
                <MXVerifyExtended 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    mxMemberToVerify={mxMemberToVerify}
                    redirectAfterVerify={redirectAfterVerify}
                    mxOpenConnect={mxOpenConnect}
                />
            ) : view === 'MXVerifyStatements' ? (
                <MXVerifyStatements 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    mxMemberToVerify={mxMemberToVerify}
                    redirectAfterVerify={redirectAfterVerify}
                    mxOpenConnect={mxOpenConnect}
                />
            ) : view === 'MXApps' ? (
                <MXApps 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    verifyAccess={verifyAccess}
                    mxMemberNeedsVerification={mxMemberNeedsVerification}
                    mxFetchAccounts={mxFetchAccounts}
                    mxAccounts={mxAccounts}
                    mxGetConnectionName={mxGetConnectionName}
                    mxOpenConnect={mxOpenConnect}
                    mxSetMemberToDelete={mxSetMemberToDelete}
                />
            ) : view === 'PinwheelLink' ? (
                <PinwheelLink 
                    foundCompany={foundCompany}
                    setView={onSetView}    
                    pinwheelAccounts={pinwheelAccounts}
                    pinwheelAccountList={pinwheelAccountList}
                    companyNameColor={companyNameColor}
                    pinwheelNeedsVerification={pinwheelNeedsVerification}
                    pinwheelOpenLink={pinwheelOpenLink}
                    pinwheelToken={pinwheelToken}
                    pinwheelOnEvent={pinwheelOnEvent}
                    pinwheelFetchAccounts={pinwheelFetchAccounts}
                    pinwheelSetAccountToDelete={pinwheelSetAccountToDelete}
                    pinwheelShowModal={pinwheelShowModal}
                    pinwheelSetToken={pinwheelSetToken}
                />
            ) : view === 'PinwheelSideHustles' ? (
                <PinwheelSideHustles 
                    foundCompany={foundCompany}
                    setView={onSetView}    
                    pinwheelAccounts={pinwheelAccounts}
                    pinwheelAccountList={pinwheelAccountList}
                    companyNameColor={companyNameColor}
                    pinwheelNeedsVerification={pinwheelNeedsVerification}
                    pinwheelOpenLink={pinwheelOpenLink}
                    pinwheelToken={pinwheelToken}
                    pinwheelOnEvent={pinwheelOnEvent}
                    pinwheelFetchAccounts={pinwheelFetchAccounts}
                    pinwheelSetAccountToDelete={pinwheelSetAccountToDelete}
                    pinwheelShowModal={pinwheelShowModal}
                    pinwheelSetToken={pinwheelSetToken}
                />
            ) : view === 'Fize' ? (
                <Fize 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    verifyAccess={verifyAccess}
                    onSetView={onSetView}
                    fizeHandler={fizeHandler}
                    fizeLoading={fizeLoading}
                    fizeNewConnections={fizeNewConnections}
                />
            ) : view === 'ConfirmationPre' ? (
                <ConfirmationPre 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    mxAccounts={mxAccounts}
                    mxMemberNeedsVerification={mxMemberNeedsVerification}
                    mxGetConnectionName={mxGetConnectionName}
                    mxFetchAccounts={mxFetchAccounts}
                    pinwheelAccounts={pinwheelAccounts}
                    pinwheelAccountList={pinwheelAccountList}
                    pinwheelNeedsVerification={pinwheelNeedsVerification}
                    pinwheelFetchAccounts={pinwheelFetchAccounts}
                    yodleeAccounts={yodleeAccounts}
                    yodleeNeedsVerification={yodleeNeedsVerification}
                    yodleeFetchAccounts={yodleeFetchAccounts}
                    yodleeGetConnectionName={yodleeGetConnectionName}
                />
            ) : view === 'ConfirmationFinal' ? (
                <ConfirmationFinal 
                    companyNameColor={companyNameColor}
                />
            ) : null}

            {/* Opens Up MX Connect if mxShowModal is set to true */}
            <MXModal 
                mxShowModal={mxShowModal}
                mxSetShowModal={mxSetShowModal}
                mxConnectLoaded={mxConnectLoaded}
                mxShowModalExit={mxShowModalExit}
                mxSetShowModalExit={mxSetShowModalExit}
                mxModalWidth={mxModalWidth}
                mxCloseConnect={mxCloseConnect}
            />

            {/* Opens Up Yodlee Fast Link if yodleeShowModal is set to true */}
            <YodleeModal 
                yodleeShowModal={yodleeShowModal}
                yodleeConnectLoaded={yodleeConnectLoaded}
            />

            {/* Pinwheel Account Deletion Confirmation */}
            <ConfirmationModal 
                showModal={pinwheelAccountToDelete ? true : false}
                toggleModal={() => pinwheelSetAccountToDelete(false)}
                title="Remove Account"
                body={(
                    <span>
                        {t(`Main: Are you sure you wish to remove the account:`)}{' '}
                        <b className="text-underline">{pinwheelAccountToDelete ? pinwheelAccountToDelete.platform : 'null'}?</b>{' '}
                        {t(`Main: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.`)} 
                    </span>
                )}
                onConfirmation={pinwheelOnDeleteAccount}
            />

            {/* Yodlee Account Deletion Confirmation */}
            <ConfirmationModal 
                showModal={yodleeAccountToDelete ? true : false}
                toggleModal={() => yodleeSetAccountToDelete(false)}
                onConfirmation={() => yodleeOnDeleteAccount(yodleeAccountToDelete)}
                title="Remove Account"
                body={(
                    <span>
                        {t(`Main: Are you sure you wish to remove the account:`)}{' '}
                        <b className="text-underline">{yodleeAccountToDelete ? yodleeAccountToDelete.accountName : 'null'}?</b>{' '}
                        {t(`Main: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.`)}
                    </span>
                )}
            />

            {/* MX Account Deletion Confirmation */}
            <ConfirmationModal 
                showModal={mxMemberToDelete ? true : false}
                toggleModal={() => mxSetMemberToDelete(false)}
                title="Remove Account"
                body={(
                    <span>
                        {t(`Main: Are you sure you wish to remove the account:`)}{' '}
                        <b className="text-underline">{mxMemberToDelete ? mxMemberToDelete.name : 'null'}?</b>{' '}
                        {t(`Main: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.`)}
                    </span>
                )}
                onConfirmation={() => mxOnDeleteMember(mxMemberToDelete)}
            />


		</>

    )
}


const mapStateToProps = state => {
	return {
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(withTranslation()(Reports));
