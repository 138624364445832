import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Cookies from 'universal-cookie';

import VerifyAccess from './VerifyAccess'
import VerifyCompany from './VerifyCompany'
import VerifyName from './VerifyName'

import MXConnect from './MXConnect'
import MXModal from './MXModal'
import MXModalStatus from './MXModalStatus'
import MXUpdateConnection from './MXUpdateConnection'
import MXVerifyExtended from './MXVerifyExtended'
import MXVerifyIdentity from './MXVerifyIdentity'
import MXVerifyStatements from './MXVerifyStatements'
import MXApps from './MXApps'

import Fize from './Fize'

import YodleeYodlee from './YodleeYodlee'

import PinwheelLink from './PinwheelLink'
import PinwheelSideHustles from './PinwheelSideHustles'

import ConfirmationPre from './ConfirmationPre'
import ConfirmationFinal from './ConfirmationFinal'

import Main from './Main'

import UtilityAccessDenied from './UtilityAccessDenied'
import UtilityError from './UtilityError'
import UtilityStatusRejected from './UtilityStatusRejected'


import Payment from './Payment'

const cookies = new Cookies();
const lang = cookies.get('lang');

const resources = {
    en: { 
        translation: {
            ...VerifyAccess.en.translation,
            ...VerifyCompany.en.translation,
            ...VerifyName.en.translation,
            ...MXConnect.en.translation,
            ...MXModal.en.translation,
            ...MXModalStatus.en.translation,
            ...MXUpdateConnection.en.translation,
            ...MXVerifyExtended.en.translation,
            ...MXVerifyIdentity.en.translation,
            ...MXVerifyStatements.en.translation,
            ...YodleeYodlee.en.translation,
            ...MXApps.en.translation,
            ...PinwheelLink.en.translation,
            ...PinwheelSideHustles.en.translation,
            ...ConfirmationPre.en.translation,
            ...ConfirmationFinal.en.translation,
            ...Main.en.translation,
            ...UtilityAccessDenied.en.translation,
            ...UtilityError.en.translation,
            ...UtilityStatusRejected.en.translation,
            ...Payment.en.translation,
            ...Fize.en.translation,
        }
    },
    es: {
        translation: {
            ...VerifyAccess.es.translation,
            ...VerifyCompany.es.translation,
            ...VerifyName.es.translation,
            ...MXConnect.es.translation,
            ...MXModal.es.translation,
            ...MXModalStatus.es.translation,
            ...MXUpdateConnection.es.translation,
            ...MXVerifyExtended.es.translation,
            ...MXVerifyIdentity.es.translation,
            ...MXVerifyStatements.es.translation,
            ...YodleeYodlee.es.translation,
            ...MXApps.es.translation,
            ...PinwheelLink.es.translation,
            ...PinwheelSideHustles.es.translation,
            ...ConfirmationPre.es.translation,
            ...ConfirmationFinal.es.translation,
            ...Main.es.translation,
            ...UtilityAccessDenied.es.translation,
            ...UtilityError.es.translation,
            ...UtilityStatusRejected.es.translation,
            ...Payment.es.translation,
            ...Fize.es.translation,
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: lang ? lang : "en", 
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
