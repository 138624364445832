/*
Documentation

this page makes the user confirm the company they are logging into is correct

*/

import { useState, useEffect } from 'react';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row, Input, FormGroup } from "reactstrap";
import { withTranslation } from 'react-i18next';

import VerificationCompanyIcon from '../_images/VerificationCompany.svg'

import _contacts from '_functions/contacts';

import i18next from '../_i18n'

import ModalToggler from 'components/functional/modals/Toggler'
import ModalTerms from './ModalTerms';


const VerificationCompany = ({foundCompany, foundContact, onCompanyVerified, companyNameColor, onLanguageChange, t, skippedCompanyMatch}) => {

    const iframeCustomStyles = foundCompany.portal_embed_styles ? JSON.parse(JSON.parse(foundCompany.portal_embed_styles)) : {}

    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [agree, setAgree] = useState(false);
    const [err, setErr] = useState(false);

    const onVerify = async (e) => {

        e.preventDefault();

        if(!givenName || !familyName) return setErr('Please fill out your full legal name.');
        if(!agree) return setErr('You must agree with the terms of service to proceed.');

        _contacts.setTimestamp({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            field: 'time_to_accept_terms'
        });

        const values = await Promise.all([
            _contacts.setAgreed({
                contact_id: foundContact._id, 
                company_id: foundCompany._id, 
                access_code: foundContact.access_code, 
            }),
            _contacts.setName({
                contact_id: foundContact._id, 
                company_id: foundCompany._id, 
                access_code: foundContact.access_code, 
                given_name: givenName,
                family_name: familyName,
            })
        ])

        if(values[1] && values[1].data) return onCompanyVerified(values[1].data);

        setErr(`Something's not right, please try again.`)

    }

    useEffect(() => {
        let shouldByPass = false;
        if(foundContact.bypass_name_verification) {
            shouldByPass = true;
        } else if(foundCompany.bypass_name_verification && foundContact.given_name && foundContact.family_name) {
            shouldByPass = true;
        }
        if(shouldByPass) {
            try {
                const displayName   = foundContact.display_name.split(' ');
                const given_name    = displayName[0].trim();
                const family_name   = displayName[1].trim();

                setGivenName(given_name)
                setFamilyName(family_name)
            } catch(e) {
                setGivenName(foundContact.given_name)
                setFamilyName(foundContact.family_name)
            }

        }
    }, [foundContact, foundCompany.bypass_name_verification])


    return (

        <>
        {foundCompany.portal_embed_link && skippedCompanyMatch ? (
            <Container className="mt--7 mt-md--9 pb-9 mb-4 mb-md-5" style={{position: 'relative', top: -20}}>
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                    <p className="text-sm mb-0 text-center"><i className="fas fa-play mr-2 text-warning " /> Watch This Video</p>

                        <div style={iframeCustomStyles}>
                            <div className="video-container z-depth-" style={{overflow: 'visible'}}>
                                <iframe 
                                    id="tutorial-video"
                                    style={{zIndex: 2,}} 
                                    src={foundCompany.portal_embed_link} 
                                    frameBorder="0" 
                                    allow="autoplay; fullscreen; picture-in-picture" 
                                    allowFullScreen 
                                    title="Zap Reports Walkthrough.mp4"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        ) : null}
      
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
    
                    <Form role="form">
    
                        <Card className="bg-white border-0 mb-0 position-relative" >
    
                            {/* <img src={VerifyCompanyIcon} style={{position: 'absolute', top: -35, right: 0, width: 90, zIndex: 1}} alt="..." /> */}
                            <img src={VerificationCompanyIcon} alt="..." className="flow-icon bg-white" />
    
                            <CardHeader>
                                <CardTitle className="mb-0">{t('VerifyCompany: Your Almost There!')}</CardTitle>
                            </CardHeader>
    
                            <CardHeader>
                                <p className="mb-0 text-sm">
                                    {t('VerifyCompany: Before you proceed to get your documents please verify your legal name and that you agree to the')}{' '}
                                    <b className="text-underline cursor-pointer">
                                    <ModalToggler component={ModalTerms}>{t('VerifyCompany: Terms Of Service Listed Here.')}</ModalToggler>
                                    </b>
                                </p>

                            </CardHeader>
    
                            {/* <CardHeader>
                                <h4>{foundCompany.name}</h4>
                            </CardHeader> */}

                            <CardHeader>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label className="form-control-label">{t('VerifyCompany: Legal First Name*')}</label>
                                            <Input 
                                                disabled={foundContact.bypass_name_verification}
                                                type="text"
                                                value={givenName}
                                                invalid={err === 'Please fill out your full legal name.' ? true : false}
                                                onChange={(e) => setGivenName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label className="form-control-label">{t('VerifyCompany: Legal Last Name*')}</label>
                                            <Input 
                                                disabled={foundContact.bypass_name_verification}
                                                type="text"
                                                value={familyName}
                                                invalid={err === 'Please fill out your full legal name.' ? true : false}
                                                onChange={(e) => setFamilyName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                               
                            </CardHeader>


                            <CardHeader>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="confirm-terms-of-service"
                                        type="checkbox"
                                        onChange={(e) => setAgree(!agree)}
                                    />
                                    <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                        {t('VerifyCompany: I Agree To The Terms Of Service')}
                                    </label>
                                </div>
                            </CardHeader>

                            {err ? (
                                <CardHeader>
                                    <p className="text-sm font-weight-bold text-warning mb-0">{t('VerifyCompany: ' + err)}</p>
                                </CardHeader>
                            ) : null}
    
    
                            <CardFooter className="text-center">
                                <button id="loginButton" onClick={onVerify} className="my-2 btn-lg btn-block btn btn-success">
                                    {t('VerifyCompany: BUTTON I Agree To The Terms Of Service')}
                                </button>
                            </CardFooter>
                        </Card>

                        <p className="text-sm mt-4 text-center cursor-pointer" style={{color: companyNameColor}} onClick={() => onLanguageChange(i18next.language === 'es' ? 'en': 'es')} >
                            {t('VerifyCompany: Language Select')}
                        </p>

    
                    </Form>
    
                </Col>
            </Row>
        </Container>

        </>
    )

}

export default withTranslation()(VerificationCompany)

