import { useState, useCallback, useEffect } from "react";
import { withTranslation } from 'react-i18next';

import { Card, CardBody, Progress, Col, Container, Row } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';
import { setUrlParameter, removeUrlParameters } from 'utils/urls';

import _integrations from '_functions/integrations';

import Dots from 'components/markup/loading/Dots'


const MXVerifyStatement = ({
    foundContact, 
    foundCompany, 
    setView, 
    mxMemberToVerify, 
    redirectAfterVerify, 
    mxOpenConnect,
    t
}) => {

    const [progress, setProgress] = useState(0);

    const runProgress = useCallback((newProgress) => {
        if(newProgress === 100) return;
        const p = newProgress + 9 > 100 ? Math.floor(Math.random() * 10) : newProgress + 9
        setProgress(p);
        setTimeout(() => {
            runProgress(p)
        }, 1000)
    }, [])

    const runVerifyStatements = useCallback(async() => {

        if(!foundCompany.accept_real_statements) {
            return setView('MXVerifyExtended');
        }

        runProgress(0)

        removeUrlParameters();

        const member = await await _integrations.mx.members.read({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            member_id: mxMemberToVerify
        })

        if(member.data && member.data.connection_status !== 'CONNECTED') {
            setUrlParameter('statement', mxMemberToVerify)
            setView('MXUpdateConnection');
            return;
        }

        const verified = await _integrations.mx.members.statements({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            member_id: mxMemberToVerify
        })

        if(!verified.data) {
            toggleAlertBS(true, `Account connection failed, please try again.`)
            return setView(redirectAfterVerify ? redirectAfterVerify : 'MXConnect')
        }

        if(verified.data && verified.data.needMFA) {
            setProgress(null);
            mxOpenConnect(mxMemberToVerify);
        } else {
            setView('MXVerifyExtended');
        }

    }, [foundCompany._id, foundCompany.accept_real_statements, foundContact._id, foundContact.access_code, mxMemberToVerify, mxOpenConnect, setView, runProgress, redirectAfterVerify])

    useEffect(() => {
        if(mxMemberToVerify) {
            runVerifyStatements()
        }
    }, [runVerifyStatements, mxMemberToVerify])

    return (

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
                    <Card>
                        <CardBody>
                            {progress !== null ? (
                                <div className="progress-wrapper pt-3">
                                    <div className="progress-success">
                                        <div className="progress-label" style={{display: 'inline'}}>
                                            <span className="text-success" style={{position: 'relative', top: 13,}}>Progress</span>
                                        </div>
                                        <div className="progress-percentage">
                                        <span style={{position: 'relative', top: -3}}>{progress}%</span>
                                        </div>
                                    </div>
                                    <Progress max="100" value={progress} color="success" />
                                </div>
                            ) : null}
                            <h4 className="text-center">
                                {t(`MXVerifyStatements: Checking Statements`)}
                                <Dots />
                            </h4>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    )
}

export default withTranslation()(MXVerifyStatement);

