const YodleeYodlee = {
    en: {
        translation: {
            "YodleeYodlee: Let's connect your banks.": "Let's connect your banks.",
            "YodleeYodlee: Step 1/5": "Step 1/5",
            "YodleeYodlee: Click the": "Click the",
            "YodleeYodlee: BLUE": "BLUE",
            "YodleeYodlee: button below to log into any bank accounts you may have. If you cannot find an account don't worry. We'll take care of that in the next step.": "button below to log into any bank accounts you may have. If you cannot find an account don't worry. We'll take care of that in the next step.",
            "YodleeYodlee: Connect Banks": "Connect Banks",
            "YodleeYodlee: Connected Accounts": "Connected Accounts",
            "YodleeYodlee: Added By Partner": "Added By Partner",
            "YodleeYodlee: Click To Verify": "Click To Verify",
            "YodleeYodlee: You have not logged into any bank accounts.": "You have not logged into any bank accounts.",
            "YodleeYodlee: Move On": "Move On",
        }
    },
    es: {
        translation: {
            "YodleeYodlee: Let's connect your banks.": "Vamos A Conectar Sus Bancos.",
            "YodleeYodlee: Step 1/5": "Paso 1/5",
            "YodleeYodlee: Click the": "Haga clic en el botón",
            "YodleeYodlee: BLUE": "AZUL",
            "YodleeYodlee: button below to log into any bank accounts you may have. If you cannot find an account don't worry. We'll take care of that in the next step.": "de abajo para conectarse a las cuentas bancarias que pueda tener. Si no encuentra una cuenta, no se preocupe. Nos ocuparemos de ello en el siguiente paso.",
            "YodleeYodlee: Connect Banks": "Conectar Bancos",
            "YodleeYodlee: Connected Accounts": "Cuentas Conectadas",
            "YodleeYodlee: Added By Partner": "Añadido Por El Socio",
            "YodleeYodlee: Click To Verify": "Haga Clic Para Verificar",
            "YodleeYodlee: You have not logged into any bank accounts.": "No ha conectado ninguna cuenta bancaria.",
            "YodleeYodlee: Move On": "Seguir Adelante",
        }
    }
};

export default YodleeYodlee;