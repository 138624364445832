/*
Documentation

this component renders the connect widget with MX

*/

import { useState, useCallback, useEffect } from "react";
import { withTranslation } from 'react-i18next';

import { Card, CardFooter, CardHeader, CardTitle, Col, Container,  Row, Progress } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import ModalToggler from 'components/functional/modals/Toggler'

import MXConnectIcon from '../_images/MXConnect.svg'

import ModalStatus from './ModalStatus'
import ConfirmationModal from 'components/functional/modals/Confirmation'

import _events from '_functions/events';

const MXConnect = ({
    foundContact,
    foundCompany,
    setView,
    verifyAccess,
    companyNameColor,

    yodleeAccounts,
    yodleeFetchAccounts,

    mxFetchAccounts,
    mxOpenConnect,
    mxMemberNeedsVerification,
    mxAccounts,
    mxGetConnectionName,
    mxOnDeleteMember,
    mxMemberToDelete,
    mxSetMemberToDelete,

    t
}) => {

    const [showUnverified, setShowUnverified] = useState(false);

    const onMoveOn = useCallback((byPassUnverified) => {

        // see if we have unverified accounts, ignore those of partner
        const hasUnverified = mxAccounts.some(m => mxMemberNeedsVerification(m))

        if(hasUnverified && !byPassUnverified) {
            return setShowUnverified(true);
        } else {
            setShowUnverified(false)
        }
        setView('MXApps');
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'system', 
            body: `Finished linking financial institutions.`
        })
    }, [setView, foundContact._id, foundContact.access_code, foundCompany._id, mxMemberNeedsVerification, mxAccounts])
    
    useEffect(() => {
        mxFetchAccounts();
        yodleeFetchAccounts();
        verifyAccess();
    }, [verifyAccess, yodleeFetchAccounts, mxFetchAccounts])

    if(!mxAccounts || !yodleeAccounts) return <Circle />

    return (

        <>

        <ConfirmationModal 
            prevButtonClasses="btn btn-success"
            nextButtonClasses="btn btn-outline-warning"
            prevButtonText="Verify"
            nextButtonText="Proceed"
            showModal={showUnverified}
            toggleModal={() => setShowUnverified(false)}
            title={t(`MXConnect: Proceed With Unverified Accounts`)}
            body={(
                <span>
                    {t(`MXConnect: Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.`)}
                    <br /><br /> 
                    <b>
                        <span className="text-underline">{t(`MXConnect: To verify your account(s)`)}</span>{' '}
                            {t(`MXConnect: click the`)}{' '}
                        <span className="text-warning">{t(`MXConnect: ORANGE BUTTON`)}</span>{' '}
                        {t(`MXConnect: next to their name.`)}
                    </b>
                </span>
            )}
            onConfirmation={() => onMoveOn(true)}
        />

        <ConfirmationModal 
            showModal={mxMemberToDelete ? true : false}
            toggleModal={() => mxSetMemberToDelete(false)}
            title="Remove Account"
            body={(
                <span>
                    {t(`MXConnect: Are you sure you wish to remove the account`)}{' '}
                    <b className="text-underline">{mxMemberToDelete ? mxMemberToDelete.name : 'null'}?</b>{' '}
                    {t(`MXConnect: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.`)}
                </span>
            )}
            onConfirmation={() => mxOnDeleteMember(mxMemberToDelete)}
        />

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={MXConnectIcon} className="bg-white flow-icon" alt="..." />

                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">{t(`MXConnect: Let's connect your finances.`)}</CardTitle>
                            <div className="progress-wrapper pt-2">
                                <div className="progress-purple">
                                    <div className="progress-percentage">
                                    <span style={{position: 'relative', top: -3}}>{t(`MXConnect: Step 1/5`)}</span>
                                    </div>
                                </div>
                                <Progress max="100" value={20} color="purple" />
                            </div>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm">
                                {t(`MXConnect: Click the`)}{' '}
                                <b className="text-purple">{t(`MXConnect: PURPLE`)}</b> {' '}
                                {t(`MXConnect: button below to search for any banks, financial institutions, etc. that you may have.`)}{' '}
                                {/* <b>{t(`MXConnect: couldn't find in step 1.`)}</b> */}
                            </p>

                            <button  onClick={() => mxOpenConnect()} className=" btn btn-purple btn-block my-2 btn-lg">
                                <i className="fas fa-link mr-2 " /> 
                                {t(`MXConnect: Advanced Search`)}
                            </button>
                        </CardHeader>

                        <CardHeader className="bg-secondar">    
                            <CardTitle className="mb-0">
                                {t(`MXConnect: Deep Dive Accounts`)}
                                <ModalToggler component={ModalStatus}>
                                    <span className="mb-0 text-warning font-weight-bold text-underline cursor-pointer">
                                        <i className="fas fa-info-circle text-info float-right position-relative" style={{top: 5}} />
                                    </span>
                                </ModalToggler>
                            </CardTitle>
                               
                        </CardHeader>

                        {mxAccounts && mxAccounts.length ? mxAccounts.map((member, i) => (
                            <CardFooter key={i}>
                                <Row>
                                    <Col xs={10} className="align-self-center">
                                        <h4> {member.name}</h4>
                                        {member.partner ? (
                                            <p className="text-sm text-muted mb-0">{t(`MXConnect: Added By Partner`)}</p>
                                        ) : null}
                                        {!member.partner && mxMemberNeedsVerification(member) ? (
                                            <p className="text-sm mb-0" onClick={() => setView('MXVerifyIdentity', member.guid, 'MXConnect')}>
                                                <span className="cursor-pointer text-muted">
                                                    <i className="fas fa-exclamation-triangle text-warning mr-2 " /> 
                                                    {t(`MXConnect: Click To Verify`)}
                                                </span>
                                            </p>
                                        ) : 
                                            <p className="text-sm mb-0">
                                                {member.connection_status === 'CONNECTED' ? (
                                                    <span>
                                                        <i className="fas fa-check text-success mr-2 " />
                                                        {mxGetConnectionName(member.connection_status)}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <i className="fas fa-info-circle text-info mr-2 " />
                                                        {mxGetConnectionName(member.connection_status)}
                                                    </span>
                                                )}
                                            </p>
                                        }
                                    </Col>
                                    <Col xs={2} className="align-self-center text-right">
                                        {member.partner ? (
                                            '-'
                                        ) : (
                                            <i className="fas fa-times text-danger cursor-pointer pl-3 pt-3 pb-3" onClick={() => mxSetMemberToDelete(member)} />
                                        )}
                                    </Col>
                                </Row>  
                            </CardFooter>
                        )) : (
                            <CardFooter>
                                <p className="text-sm mb-0 text-warning font-weight-bold">
                                    {t(`MXConnect: You have not logged into any deep dive accounts.`)}
                                </p>
                            </CardFooter>
                        )}

                        {yodleeAccounts && yodleeAccounts.length ? (
                            <>
                                <CardFooter>
                                    <CardTitle className="mb-0 flow-icon-header">{t(`MXConnect: Accounts Connected On Step 1.`)}</CardTitle>
                                </CardFooter>
                                {yodleeAccounts.map((account, i) => (
                                    <CardFooter key={i}>
                                        <h4 className="mb-0 text-sm text-muted"> {account.accountName}</h4>
                                    </CardFooter>
                                ))}
                            </>
                        ) : null}
                        
                        <CardFooter className="text-center">
                            <button  onClick={() => onMoveOn(false)} className=" btn btn-success btn-block my-2 btn-lg">
                                {t(`MXConnect: Move On`)}
                                <i className="fas fa-arrow-right ml-2 " />
                            </button>
                        </CardFooter>

                    </Card>

                    {/* <p className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" style={{color: companyNameColor}}>
                        <span onClick={() => setView('Yodlee')}>
                        <i className="fas fa-arrow-left mr-2 " />
                            {t(`MXConnect: Back`)}
                        </span>
                    </p> */}

                </Col>
            </Row>
        </Container>

        </>

    )
}

export default withTranslation()(MXConnect)

