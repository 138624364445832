const MXApps = {
    en: {
        translation: {
            "MXApps: Proceed With Unverified Accounts": "Proceed With Unverified Accounts",
            "MXApps: Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.": "Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.",
            "MXApps: To verify your account(s)": "To verify your account(s)",
            "MXApps: click the": "click the",
            "MXApps: ORANGE BUTTON": "ORANGE BUTTON",
            "MXApps: next to their name.": "next to their name.",
            "MXApps: Step 2/5": "Step 2/5",
            "MXApps: Move On": "Move On",
            "MXApps: Back": "Back",
            "MXApps: Now let's check your apps.": "Now let's check your apps.",
            "MXApps: Now let's take a deep dive, into your financial apps. Take a look at the list below and connect any apps that you have an account with.": "Now let's take a deep dive, into your financial apps. Take a look at the list below and connect any apps that you have an account with.",
            "MXApps: Connect": "Connect",
            "MXApps: Not Set Up": "Not Set Up",
            "MXApps: Click To Verify": "Click To Verify",
        }
    },
    es: {
        translation: {
            "MXApps: Proceed With Unverified Accounts": "Proceder con cuentas No Verificadas.",
            "MXApps: Before proceeding please verify all of your apps. If you do not, no documents will be able to be retrieved from any account marked as needing verification.": "Antes de proceder por favor verifica todas tus aplicaciones financieras. De no hacerlo, lo documentos no se obtendrán de ninguna cuenta marcada como no verificada. ",
            "MXApps: To verify your account(s)": "Para verificar tu(s) cuenta(s)",
            "MXApps: click the": "haz click en el",
            "MXApps: ORANGE BUTTON": "BOTÓN NARANJA",
            "MXApps: next to their name.": "al lado de tu nombre.",
            "MXApps: Step 2/5": "Paso 2/5",
            "MXApps: Move On": "Avanza.",
            "MXApps: Back": "Regresar",
            "MXApps: Now let's check your apps.": "Ahora, revisa tus aplicaciones financieras.",
            "MXApps: Now let's take a deep dive, into your financial apps. Take a look at the list below and connect any apps that you have an account with.": "Ahora, vamos a navegar más a fondo en tus aplicaciones financieras. De la lista de abajo, selecciona y conecta la o las aplicaciones de la entidades financieras con las que tengas alguna cuenta. ",
            "MXApps: Connect": "Conectar",
            "MXApps: Not Set Up": "No se ha configurado",
            "MXApps: Click To Verify": "Haz click para verificar.",

        }
    }
};

export default MXApps;