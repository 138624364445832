/*
Documentation

this component renders the link widget with Pinwheel

*/

import { useEffect } from "react";
import { withTranslation } from 'react-i18next';

import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, Progress } from "reactstrap";

import PinwheelModal from '@pinwheel/react-modal'
import PinwheelLinkIcon from '../_images/PinwheelLink.svg'

import Circle from 'components/markup/loading/Circle'

const PinwheelLink = ({
    foundCompany,
    setView,
    pinwheelAccounts,
    pinwheelAccountList,
    companyNameColor,
    pinwheelNeedsVerification,
    pinwheelOpenLink,
    pinwheelToken,
    pinwheelOnEvent,
    pinwheelFetchAccounts,
    pinwheelSetAccountToDelete,
    pinwheelShowModal,
    pinwheelSetToken,
    t
}) => {
   

    useEffect(() => {
        pinwheelFetchAccounts();
        pinwheelSetToken(false);
    }, [pinwheelFetchAccounts, pinwheelSetToken]);

    if(!pinwheelAccounts) return <Circle />

    return (

        <>

        {pinwheelToken ? (
            <PinwheelModal
                open={pinwheelShowModal}
                linkToken={pinwheelToken}
                onEvent={pinwheelOnEvent}
            />
        ) : null}

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={PinwheelLinkIcon} alt="..." className="flow-icon bg-white" />

                        <CardHeader>
                            <CardTitle className="mb-0">{t(`PinwheelLink: Let's Verify Your Employment`)}</CardTitle>
                            <div className="progress-wrapper pt-2">
                                <div className="progress-warning">
                                    <div className="progress-percentage">
                                    <span style={{position: 'relative', top: -3}}>{t(`PinwheelLink: Step 3/5`)}</span>
                                    </div>
                                </div>
                                <Progress max="100" value={60} color="warning" />
                            </div>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm mb-0">
                                {t(`PinwheelLink: Click the`)}{' '}
                                <b className="text-warning">{t(`PinwheelLink: ORANGE`)}</b>{' '}
                                {t(`PinwheelLink: button below to verify your paystubs and income. If you are on unemployment search for "unemployment".`)}
                            </p>
                        </CardHeader>

                        <CardFooter className="text-center">
                            <button  onClick={() => pinwheelOpenLink()} className=" btn btn-warning btn-block my-2 btn-lg">
                                <i className="fas fa-search-location mr-2 " />{t(`PinwheelLink: Verify Employment`)}
                            </button>
                        </CardFooter>

                        <CardHeader className="bg-secondar">    
                            <CardTitle className="mb-0">
                                {t(`PinwheelLink: Connected Employers`)}
                            </CardTitle>
                        </CardHeader>

                        {pinwheelAccounts.length ? pinwheelAccounts.map((account, i) => (
                            <CardFooter key={i}>
                                <Row>
                                    <Col xs={10} className="align-self-center">
                                        <h4> 
                                            {account.platform}
                                        </h4>
                                        {account.partner ? (
                                            <p className="text-sm text-muted mb-0">{t(`PinwheelLink: Added By Partner`)}</p>
                                        ) : null}
                                        <span>
                                            {pinwheelNeedsVerification(account, pinwheelAccountList) ? (
                                                <button className="btn btn-warning btn-sm" onClick={() => pinwheelOpenLink(account.account_id, null, account.platform)} >
                                                    <i className="fas fa-info-circle mr-2 " />
                                                    {t(`PinwheelLink: Click To Verify`)}
                                                </button>
                                            ) : (
                                                <div><i className="fas fa-check text-success mr-2 " />{t(`PinwheelLink: Connected`)}</div>
                                            )}
                                            
                                        </span>
                                    </Col>
                                    <Col xs={2} className="align-self-center text-right">
                                        {account.partner ? (
                                            '-'
                                        ) : (
                                            <i className="fas fa-times text-danger cursor-pointer pl-3 pt-3 pb-3" onClick={() => pinwheelSetAccountToDelete(account)} />
                                        )}
                                    </Col>
                                </Row>  
                            </CardFooter>
                        )) : (
                            <CardFooter>
                                <p className="text-sm mb-0 text-warning font-weight-bold">
                                {t(`PinwheelLink: You have not logged into any employer accounts.`)}
                                </p>
                            </CardFooter>
                        )}
                       
                        <CardFooter className="text-center">
                            <button  onClick={() => setView('PinwheelSideHustles')} className=" btn btn-success btn-block my-2 btn-lg">
                                {t(`PinwheelLink: Move On`)}<i className="fas fa-arrow-right ml-2 " />
                            </button>
                        </CardFooter>

                    </Card>

                    {foundCompany.accept_financial ? (
                        <p className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" style={{color: companyNameColor}}>
                            <span onClick={() => setView('MXApps')}>
                            <i className="fas fa-arrow-left mr-2 " />
                                {t(`PinwheelLink: Back`)}
                            </span>
                        </p>
                    ) : null}
                    
                </Col>
            </Row>
        </Container>

        </>

    )
}

export default withTranslation()(PinwheelLink);
