/*
Documentation

this component renders the link widget with Pinwheel

*/

import keys from 'keys';
import { withTranslation } from 'react-i18next';
import { useEffect, useCallback, useState } from "react";
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, FormGroup, Input, Modal } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import PinwheelLinkIcon from '../_images/PinwheelLink.svg';

import _contact_purchases from '_functions/contact_purchases';

import Circle from 'components/markup/loading/Circle'

import SearchMain from '../../../../actions/pages/Search/Main'

const Payment = ({ onSetView, foundContact, t }) => {

    const [agree, setAgree] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(false);
    const [paymentIframe, setPaymentIframe] = useState(false);
    const [errs, setErrs] = useState([]);
    const [email, setEmail] = useState(foundContact.email ? foundContact.email : '');
    const [showModal, setShowModal] = useState(false);

    const price = foundContact.partner ? '$50' : '$25'

    const onChargeCard = useCallback(() => {

        if(!agree) return setErrs([<span><i className="fas fa-arrow-up mr-2 " /> {t('Payment: You must confirm the above before moving on.')}</span>])
        const _errs = [];

        setErrs([]);

        toggleStandardLoader(true);

        paymentIframe.encrypt().success( async function (res) {

            const purchased = await _contact_purchases.create({
                card_id: res.eToken,
                card_last_four: res.masked.number.slice(-4),
                card_expiration: res.masked.expy.slice(0, 2) + '/' + res.masked.expy.slice(2, 4),
                contact: foundContact._id,
                email,
            })

            if(purchased.success) {
                onSetView('Yodlee');
            } else {
                let message = t('Payment: Card Declined, Please Try Again.');
                if(purchased.message && purchased.message[0] === 'Only Debit Cards May Be Used.') {
                    message =  t('Payment: Only Debit Cards May Be Used.')
                }
                if(purchased.message && purchased.message[0] === 'Internal Error After Processing') {
                    message = t('Payment: A payment was processed but something went wrong updating your account. Please send an email to support@zapreports.com');
                }

                toggleAlertBS(true, message);
            }

            toggleStandardLoader(false);

        }).failure( function (err) {
            toggleStandardLoader(false);

            _errs.push(err.message)
            setErrs(_errs)

        }).invalidInput( function (data) {
            toggleStandardLoader(false);

            for ( var i = 0; i < data.invalidInputs.length; i++ ){
                const err = data.invalidInputs[i];
                _errs.push(err.field);
            }

            setErrs(_errs)

        });

    }, [paymentIframe, foundContact._id, email, onSetView, agree, t])

    const load = useCallback(() => {
        try {

            const iframe = new window.PaymentiFrame({
                create: true,
                iframeId: "payment_iframe",
                settings: {
                    account           : keys.BLUE_FIN_ACCOUNT_ID,
                    parentId          : "iframe_container",
                    lang              : "en",
                    cvv               : "required",
                    width             : "100%",
                    height            : "125px",
                    showFrame         : false,
                    devServer         : keys.BLUE_FIN_API_URL,
                    onload            : () => setIframeLoading(false),
                    numberLabel       : 1,
                    expyLabel         : 1,
                    cvvLabel          : 9,
                    layout            : 'layout_2',
                    inputWidth        : '20em',
                    inputStyle        : 1,
                    labelFontSize     : 'font_size_14',
                    labelFontFamily   : 'font_family_14',
                    labelFontColor    : '#abcd12',
                    inputFontSize     : 'font_size_14',
                    inputFontFamily   : 'font_family_14',
                    inputFontColor    : '#cc6633',
                    number_label      : 'Card Number ss',
                    expy              : 'double_input',
                    css: {
    
                        "class_label"  : "font-weight: 300; display: block; margin-bottom: .24rem; color: #525f7f; font-size: 0.9625rem; font-weight: 600; font-family: Helvetica",
                        "class_label:focus"  : "color: red",
    
                        "class_input_box": "display: block; width: 100%; box-sizing: border-box; padding: 0.3rem 0.3rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 0.935rem; background: #f6f9fc; border-radius: 16px; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                        "class_input_box:focus" : "border: solid 1px #fb6340; outline: none; border-radius: 0px",
    
                        "id_expy_input_month"  : "display: block; width: 48%; float: left; box-sizing: border-box; padding: 0.3rem 0.3rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 0.935rem; background: #f6f9fc; border-radius: 16px; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                        "id_expy_input_month:focus" : "border: solid 1px #fb6340; outline: none; border-radius: 0px",
                        "id_expy_input_month::placeholder"  : "color: #adb5bd",
    
                        "id_expy_input_year"   : "display: block; width: 48%; float: right; box-sizing: border-box; padding: 0.3rem 0.3rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 0.935rem; background: #f6f9fc; border-radius: 16px; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                        "id_expy_input_year:focus" : "border: solid 1px #fb6340; outline: none; border-radius: 0px",
                        "id_expy_input_year::placeholder"  : "color: #adb5bd",
    
                        "id_number_input::placeholder"  : "color: #adb5bd",
                        "id_cvv_input::placeholder"  : "color: #adb5bd",
    
                        "id_number_row": "margin-bottom: .6rem",
    
                        "id_expy_row"  : "width: 65%;float: left;",
    
                        "id_cvv_row"  : "width: 32%; float: right",
    
                    },
    
                    text     : {
                        number : {
                            label       : t('Payment: Card Number *'),
                            placeholder : "1111 2222 3333 4444"
                        },
                        cvv : {
                            label       : "CVV *",
                            placeholder : "123"
                        },
                        expy_double : {
                            label         : t('Payment: Expires *'),
                            placeholder_1 : "MM",
                            placeholder_2 :  t('Payment: YY'),
                        }
                    },
    
                }
            });
    
            setPaymentIframe(iframe);
            setIframeLoading(false)
    
        } catch(e) {
            console.log(e)
            toggleAlertBS(true, t('Payment: Please try again later.'))
        }
    }, [t])

    useEffect(() => {
        load()

        return () => {
            const iframe = document.getElementById('iframe_container')
            if(iframe) iframe.innerHTML = ''
        }
        
    }, [load]);

    // if(!pinwheelAccounts) return <Circle />

    return (

        <>

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={PinwheelLinkIcon} alt="..." className="flow-icon bg-white" />

                        <CardHeader>
                            <CardTitle className="mb-0">
                                {t('Payment: Purchase Access')} - {price}
                            </CardTitle>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm">
                                {t('Payment: Zap Reports is a service designed to help you get the required documents to file your case.')}
                            </p>

                            <p className="text-sm">
                                {t('Payment: By adding a DEBIT CARD below you authorize Zap Reports to charge that card')}{' '}
                                {price}.{' '}
                                {t('Payment: With your purchase, Zap Reports will allow you to come back to this portal for the next 90 days should you need to get updated statements and transaction histories for your accounts.')}
                            </p>

                            <p className="text-sm  mb-0">
                                <i className="fas fa-info-circle text-info mr-2" />{' '}
                                {t('Payment: Note that it is your responsibility to make sure that Zap Reports can access your bank accounts and employers before proceeding. Please')}{' '}
                                <span className="text-underline font-weight-bold cursor-pointer text-success" onClick={() => setShowModal(true)}>{t('Payment: CLICK HERE')}</span>{' '}
                                {t('Payment: to search and verify that Zap Reports able to connect to your required accounts.')}
                            </p>
                        </CardHeader>

                        <CardHeader>
                            <div className="py-3">
                                {iframeLoading ? <div className="py-6"><Circle /></div> : null}
                                <div id="iframe_container" />
                            </div>
                        </CardHeader>
                        <CardHeader>
                            <FormGroup>
                                <label className="form-control-label">{t('Payment: Email Receipt To:')}</label>
                                <Input 
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>
                        </CardHeader>
                                            
                        <CardFooter className="bg-secondary">
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="confirm-terms-of-service"
                                    type="checkbox"
                                    onChange={(e) => setAgree(!agree)}
                                />
                                <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                    {t('Payment: I confirm I can find my institutions with Zap Reports')}
                                </label>
                            </div>
                        </CardFooter>

                        {errs.length ? (
                            <CardHeader>
                                {errs.map((err, i) => (
                                    <p key={i} className="text-sm mb-0 text-danger">
                                        {
                                            err === 'number' ?  t('Payment: * Please check your card number.') : 
                                            err === 'expy'   ?  t(`Payment: * Please check your card's expiration date.`) :
                                            err === 'cvv'    ? t(`Payment: * Please check your card's CVV.`) : 
                                            err
                                        }
                                    </p>
                                ))}
                            </CardHeader>
                        ) : null}

                        <CardFooter className="text-center">
                            <button  onClick={onChargeCard} className=" btn btn-success btn-block my-2 btn-lg">
                                {t('Payment: Charge My Card')} - {price}
                            </button>
                        </CardFooter>

                    </Card>
                    
                </Col>
            </Row>
        </Container>

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => setShowModal(false)}
            size="md"
            id="archk-search-modal"
        >

            <div className="modal-header">
                <h5 className="modal-title">{t('Payment: Institutions Search')}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setShowModal(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <SearchMain />

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={() => setShowModal(false)}>
                    {t('Payment: Close')}
                </button>
            </div>

        </Modal>

        </>

    )
}

export default withTranslation()(Payment);
