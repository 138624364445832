import { withTranslation } from 'react-i18next';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

const UtilityStatusRejected = ({foundCompany, t}) => (
    <Container className="mt--9 pb-5 text-center">
        <Row className="justify-content-center">
            <Col lg="5" md="7">
                <Card className="bg-white border-0 mb-0 position-relative" >

                    <CardHeader>
                        <CardTitle className="mb-0">
                            {t('UtilityStatusRejected: Authorization Needed')}
                        </CardTitle>
                    </CardHeader>

                    <CardHeader className="bg-secondary">
                        <p className="mb-0">
                            {t('UtilityStatusRejected: Your account shows that you have either:')}
                        </p>
                    </CardHeader>
                    <CardHeader className="bg-scondary">
                        <p className="mb-0 text-warning font-weight-bold"> 
                            1){' '}
                            {t('UtilityStatusRejected: Not been given access or')}
                        </p>
                        <p className="mb-0 text-warning font-weight-bold"> 
                            2){' '}
                            {t('UtilityStatusRejected: Already completed this process.')}
                        </p>
                    </CardHeader>

                    <CardHeader className="bg-secondary">
                        <p className="mb-0">
                            {t('UtilityStatusRejected: If you do not believe this to be correct or you need to re-pull any financial information please contact the company who requested your statements:')}
                        </p>
                    </CardHeader>
                    
                    <CardFooter>
                        <p className="text-underline font-weight-bold mb-0"><b>{foundCompany.name}</b></p>
                    </CardFooter>

                </Card>
            </Col>
        </Row>
    </Container>
)

export default withTranslation()(UtilityStatusRejected);

