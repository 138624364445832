const isFinancial = (view) => {
    if(view === 'MXConnect' || view === 'Yodlee' || view === 'MXApps') {
        return true;
    }
    return false;
}

const isEmployment = (view) => {
    if(view === 'PinwheelLink' || view === 'PinwheelSideHustles') {
        return true;
    }
    return false;
}

const isAuto = (view) => {
    if(view === 'Fize') {
        return true;
    }
    return false;
}

export const getNextStep = (company, view) => {

    const rejectFinancial   = !company.accept_financial;
    const rejectEmployment  = !company.accept_employment;
    const rejectAuto        = !company.accept_auto_policies;

    const viewIsFinancial   = isFinancial(view)
    const viewIsEmployment  = isEmployment(view)
    const viewIsAuto        = isAuto(view)

    if(rejectFinancial && viewIsFinancial) {
        return getNextStep(company, 'PinwheelLink')
    } else if(rejectEmployment && viewIsEmployment) {
        return getNextStep(company, 'Fize')
    } else if(rejectAuto && viewIsAuto) {
        return 'ConfirmationPre'
    }

    return view;

}

export const totalSteps = (company) => {
    let steps = 0;

    if(company.accept_financial) steps += 3
    if(company.accept_employment) steps += 2
    if(company.accept_auto_policies) steps += 1;

    return steps;
}

