import { useState, useCallback } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input, Col, Container, Row } from "reactstrap";

import logo from 'assets/img/brand/ZapReportsLogo.png';

import _contacts from '_functions/contacts';

import Circle from 'components/markup/loading/Circle'

const Confirmation  = ({match}) => {

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [remindersDisabled, setRemindersDisabled] = useState(false);

    const onSave = useCallback(async () => {

        setLoading(true);

        const saved = await _contacts.setNotifications({
            contact_id: match.params.contact_id,
            reminders_disabled: remindersDisabled
        })

        setStatus(saved.success ? 'success' : 'err')
        setLoading(false);

    }, [match.params.contact_id, remindersDisabled])

    return (
        <Container className="pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <div className="text-center mt-5">
                        <img src={logo} style={{maxWidth: 200}} alt="Zap Reports" />
                    </div>

                    {status === 'err' ? (
                        <Card className="bg-white border-0  position-relative mt-5 " >
    
                            <CardHeader>
                                <CardTitle className="mb-0">Something's Not Right.</CardTitle>
                            </CardHeader>

                            <CardFooter>
                                <p className="text-sm mb-0">Please refresh your page and try again.</p>
                            </CardFooter>

                        </Card>
                    ) : status === 'success' ? (
                        <Card className="bg-white border-0  position-relative mt-5 " >
    
                            <CardHeader>
                                <CardTitle className="mb-0">Success!</CardTitle>
                            </CardHeader>
        
                            <CardFooter>
                                <p className="text-sm mb-0">Your notification preferences have been updated successfully.</p>
                            </CardFooter>
        
                        </Card>
                    ) : (
                        <Card className="bg-white border-0  position-relative mt-5 " >
    
                            <CardHeader>
                                <CardTitle className="mb-0">You're notification preferences.</CardTitle>
                            </CardHeader>
        
                            <CardBody>
                                <FormGroup>
                                    <label className="form-control-label">What notifications would you like to recieve?</label>
                                    <Input
                                        type="select"
                                        value={remindersDisabled === true ? true : false}
                                        onChange={(e) => setRemindersDisabled(e.target.value === 'true' ? true : false)}
                                    >
                                        <option value="false">I would like to receive notifications about my account.</option>
                                        <option value="true">I do not want to receive notifications.</option>
                                    </Input>
                                </FormGroup>
                            </CardBody>

                            <CardFooter className="text-right">
                                <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2" /> Save</button>
                            </CardFooter>
        
                        </Card>
                    )}
    
                    {loading ? (
                        <div className="text-center"><Circle /></div>
                    ) : null}
    
                </Col>
            </Row>
        </Container>
    )

}

export default Confirmation 

