const Payment = {
    en: {
        translation: {
            "Payment: You must confirm the above before moving on.": "You must confirm the above before moving on.",
            "Payment: Card Declined, Please Try Again.": "Card Declined, Please Try Again.",
            "Payment: Only Debit Cards May Be Used.": "Only Debit Cards May Be Used.",
            "Payment: A payment was processed but something went wrong updating your account. Please send an email to support@zapreports.com": "A payment was processed but something went wrong updating your account. Please send an email to support@zapreports.com",
            "Payment: Card Number *": "Card Number *",
            "Payment: Expires *": "Expires *",
            "Payment: YY": "MM",
            "Payment: Please try again later.": "Please try again later.",
            "Payment: Purchase Access": "Purchase Access",
            "Payment: Zap Reports is a service designed to help you get the required documents to file your case.": "Zap Reports is a service designed to help you get the required documents to file your case.",
            "Payment: By adding a DEBIT CARD below you authorize Zap Reports to charge that card": "By adding a DEBIT CARD below you authorize Zap Reports to charge that card",
            "Payment: With your purchase, Zap Reports will allow you to come back to this portal for the next 90 days should you need to get updated statements and transaction histories for your accounts.": "With your purchase, Zap Reports will allow you to come back to this portal for the next 90 days should you need to get updated statements and transaction histories for your accounts.",
            "Payment: Note that it is your responsibility to make sure that Zap Reports can access your bank accounts and employers before proceeding. Please": "Note that it is your responsibility to make sure that Zap Reports can access your bank accounts and employers before proceeding. Please",
            "Payment: CLICK HERE": "CLICK HERE",
            "Payment: to search and verify that Zap Reports able to connect to your required accounts.": "to search and verify that Zap Reports able to connect to your required accounts.",
            "Payment: Email Receipt To:": "Email Receipt To:",
            "Payment: I confirm I can find my institutions with Zap Reports": "I confirm I can find my institutions with Zap Reports",
            "Payment: * Please check your card number.": "* Please check your card number.",
            "Payment: * Please check your card's expiration date.": "* Please check your card's expiration date.",
            "Payment: * Please check your card's CVV.": "* Please check your card's CVV.",
            "Payment: Charge My Card": "Charge My Card",
            "Payment: Institutions Search": "Institutions Search",
            "Payment: Close": "Close",
        }
    },
    es: {
        translation: {
            "Payment: You must confirm the above before moving on.": "Debe confirmar lo anterior antes de continuar.",
            "Payment: Card Declined, Please Try Again.": "Tarjeta rechazada, por favor, inténtelo de nuevo.",
            "Payment: Only Debit Cards May Be Used.": "Sólo se pueden utilizar tarjetas de débito.",
            "Payment: A payment was processed but something went wrong updating your account. Please send an email to support@zapreports.com": "Se ha procesado un pago pero algo ha ido mal al actualizar su cuenta. Por favor, envíe un correo electrónico a support@zapreports.com",
            "Payment: Card Number *": "Número de tarjeta *",
            "Payment: Expires *": "Fecha de caducidad *",
            "Payment: YY": "AA",
            "Payment: Please try again later.": "Por favor, inténtelo de nuevo más tarde.",
            "Payment: Purchase Access": "Acceso a la compra",
            "Payment: Zap Reports is a service designed to help you get the required documents to file your case.": "Zap Reports es un servicio diseñado para ayudarle a obtener los documentos necesarios para presentar su caso.",
            "Payment: By adding a DEBIT CARD below you authorize Zap Reports to charge that card": "Al añadir una TARJETA DE DÉBITO a continuación, usted autoriza a Zap Reports a realizar un cargo en esa tarjeta",
            "Payment: With your purchase, Zap Reports will allow you to come back to this portal for the next 90 days should you need to get updated statements and transaction histories for your accounts.": "Con su compra, Zap Reports le permitirá volver a este portal durante los próximos 90 días en caso de que necesite obtener estados de cuenta e historiales de transacciones actualizados para sus cuentas.",
            "Payment: Note that it is your responsibility to make sure that Zap Reports can access your bank accounts and employers before proceeding. Please": "Tenga en cuenta que es su responsabilidad asegurarse de que Zap Reports puede acceder a sus cuentas bancarias y a sus empleadores antes de proceder. Por favor,",
            "Payment: CLICK HERE": "HAGA CLIC AQUÍ",
            "Payment: to search and verify that Zap Reports able to connect to your required accounts.": "para buscar y verificar que Zap Reports puede conectarse a sus cuentas requeridas.",
            "Payment: Email Receipt To:": "Recibir por correo electrónico a:",
            "Payment: I confirm I can find my institutions with Zap Reports": "Confirmo que puedo encontrar mis instituciones con Zap Reports",
            "Payment: * Please check your card number.": "* Por favor, compruebe el número de su tarjeta.",
            "Payment: * Please check your card's expiration date.": "* Por favor, compruebe la fecha de caducidad de su tarjeta.",
            "Payment: * Please check your card's CVV.": "* Por favor, compruebe el CVV de su tarjeta.",
            "Payment: Charge My Card": "Cargar mi tarjeta",
            "Payment: Institutions Search": "Búsqueda de instituciones",
            "Payment: Close": "Cerrar",
        }
    }
};

export default Payment;