const MXVerifyStatements = {
    en: {
        translation: {
            "MXVerifyStatements: Checking Statements": "Checking Statements",
        }
    },
    es: {
        translation: {
            "MXVerifyStatements: Checking Statements": "Comprobando las declaraciones",
        }
    }
};

export default MXVerifyStatements;