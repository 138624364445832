/*
Documentation

this component renders the connect widget with MX

*/

import { useCallback } from "react";
import { withTranslation } from 'react-i18next';

import { Card, CardFooter, CardHeader, CardTitle, Col, Container,  Row, Progress } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import FizeIcon from '../_images/fize.svg'

const Fize = ({
    fizeHandler,
    foundContact,
    foundCompany,
    setView,
    fizeLoading,
    companyNameColor,
    onSetView,
    fizeNewConnections,
    t
}) => {

    let allNames = foundContact.fize_connection_names ? foundContact.fize_connection_names : []
    allNames = allNames.concat(fizeNewConnections)

    const launchFize = useCallback(() => {

        var configObj = {
            config: {
                placement_id: 1,
                logo_url:'https://reports.zapreports.com/fizeLogo.png',
                display_name: foundCompany.name,
                policy_data_config: {
                    auto_policy: true,
                    home_policy: false,
                },
                theme_config: {
                    theme_color: "#2CC4E4",
                    primary_text_color: "#525f7f",
                    secondary_text_color: "#32325d",
                    primary_header_color: "#525f7f",
                    secondary_header_color: "#2dce89",
                    border_color: "#e6e5e3",
                    hover_bg_color: "#ebdec5",
                    font_family: "Arial",
                },
    
            },
        };
        fizeHandler.launch(configObj)
     
    }, [fizeHandler, foundCompany.name])
    
    return (

        <>

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={FizeIcon} className="bg-white flow-icon" alt="..." />

                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">{t(`Fize: Auto Insurance`)}</CardTitle>
                            <div className="progress-wrapper pt-2">
                                <div className="progress-purple">
                                    <div className="progress-percentage">
                                    <span style={{position: 'relative', top: -3}}>{t(`FIZE: Step 5/5`)}</span>
                                    </div>
                                </div>
                                <Progress max="100" value={90} color="purple" />
                            </div>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm">
                                {t(`Fize: Alright, time to connect your auto insurance policy.`)}{' '}
                            </p>
                            <p className="text-sm"> 
                                {t(`Fize: Click the`)}{' '}
                                <b className="text-info">{t(`Fize: BLUE`)}</b> {' '}
                                {t(`Fize: button below to search for your current auto insurance provider.`)}
                            </p>

                            <p className="text-sm"> 
                                {t(`Fize: If you do not currently have auto insurance you may move onto the next step.`)}{' '}
                            </p>

                            <button  onClick={() => launchFize()} className=" btn btn-info btn-block my-2 btn-lg">
                                <i className="fas fa-link mr-2 " /> 
                                {t(`Fize: Link Your Auto Policy`)}
                            </button>
                        </CardHeader>

                        <CardHeader className="bg-secondar">    
                            <CardTitle className="mb-0">
                                {t(`Fize: Connected Policies`)}
                            </CardTitle>
                               
                        </CardHeader>

                        {fizeLoading ? (
                            <div className="py-4"><Circle /></div>
                        ) : (
                            allNames && allNames.length ? allNames.map((account, i) => (
                                <CardFooter key={i}>
    
                                    <h4> {account}</h4>
                                    <p className="text-sm mb-0">
                                        <span>
                                            <i className="fas fa-check text-success mr-2 " />
                                            {t(`FIZE: Connected`)}
                                        </span>
                                    </p>
    
                                </CardFooter>
                            )) : (
                                <CardFooter>
                                    <p className="text-sm mb-0 text-warning font-weight-bold">
                                        {t(`Fize: You have not connected any auto insurance policies`)}
                                    </p>
                                </CardFooter>
                            )
                        )}

                        <CardFooter className="text-center">
                            <button  onClick={() => onSetView('ConfirmationPre')} className=" btn btn-success btn-block my-2 btn-lg">
                                {t(`FIZE: Move On`)}
                                <i className="fas fa-arrow-right ml-2 " />
                            </button>
                        </CardFooter>

                    </Card>

                    {foundCompany.accept_financial || foundCompany.accept_employment ? (
                        <p className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" style={{color: companyNameColor}}>
                            <span onClick={() => setView(foundCompany.accept_employment ? 'PinwheelSideHustles' : 'MXApps')}>
                            <i className="fas fa-arrow-left mr-2 " />
                                {t(`FIZE: Back`)}
                            </span>
                        </p>
                    ) : null}
                    

                </Col>
            </Row>
        </Container>

        </>

    )
}

export default withTranslation()(Fize)

