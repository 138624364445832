import { useState, useCallback } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input, Col, Row } from "reactstrap";

import _integrations from '_functions/integrations';

import Circle from 'components/markup/loading/Circle'

import * as _ from 'underscore';

const initialEmployers = [
    { name: 'ADP', logo_url: "https://cdn.getpinwheel.com/assets/platforms/https%3A//cdn.getpinwheel.com/assets/platforms/3b2b6106-bb22-40e7-aa8c-5a962f5be3a9.svg.svg" },
    { name: 'Paycom', logo_url: "https://cdn.getpinwheel.com/assets/platforms/https%3A//cdn.getpinwheel.com/assets/platforms/8c0ef2fb-84d4-4305-a3e9-babd3ba0f9a3.svg.svg" },
    { name: 'Paychex', logo_url: "https://cdn.getpinwheel.com/assets/platforms/https%3A//cdn.getpinwheel.com/assets/platforms/a4c58c81-ae52-4540-808f-79b2a154cf37.svg.svg" },
    { name: 'Amazon', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/amazon.svg.svg" },
    { name: 'United Parcel Service', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/6e3d6c6d-4f3a-4f76-a79e-49c44f7dbe2b.svg.svg" },
    { name: 'United States Postal Service', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/usps.svg.svg" },
    { name: 'AT&T', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/att.svg.svg" },
    { name: 'Best Buy', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/69901a83-90f8-4aeb-9ccc-6281fbe92cf2.svg.svg" },
    { name: 'Chipotle', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/chipotle_1596744840234.svg.svg" },
    { name: 'Circle K', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/9b7c32f5-e66f-4c32-b0e0-a897ab4f9cb1.svg.svg" },
    { name: 'Costco', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/costco_1600369121081.svg.png" },
    { name: 'CVS Health', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/cvsHealth.svg.svg" },
    { name: 'Dollar General', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/dollar_general_logo.svg.svg" },
    { name: 'HCA Healthcare', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/hcaHealthcare.svg.png" },
    { name: 'Home Depot', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/homeDepot.svg.svg" },
    { name: 'Kroger', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/kroger.svg.svg" },
    { name: `Lowe's`, logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/lowes.svg.svg" },
    { name: 'Publix', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/https%253A//cdn.getpinwheel.com/assets/employers/publix_brandmark_1.svg.svg.svg" },
    { name: 'Target', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/https%253A//cdn.getpinwheel.com/assets/employers/target_1596744821997.svg.png.svg" },
    { name: 'Walmart', logo_url: "https://cdn.getpinwheel.com/assets/employers/https%3A//cdn.getpinwheel.com/assets/employers/walmart.svg.svg" },
]

const Search  = () => {

    const [initialLoad, setInitialLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [string, setString] = useState('');
    const [institutions, setInstitutions] = useState([]);
    const [employment, setEmployment] = useState(initialEmployers);
    const [searchType, setSearchType] = useState('employment');

    const renderEmployment = useCallback((institutions) => {

        let color = 'purple';

        return institutions.map((institution, i) => {
            if(!institution) return ''

            let url = '';
    
            try {
                url = institution.loginUrl ? institution.loginUrl : institution.url;
                const { hostname, protocol } = new URL(url);
                url = protocol + '//' + hostname
            } catch(e) {
    
            }
    
            if(!institution.logo_url) {
                if(color === 'purple') {
                    color = 'green';
                } else if(color === 'green') {
                    color = 'yellow';
                } else if(color === 'yellow') {
                    color = 'orange';
                } else if(color === 'orange') {
                    color = 'pink';
                } else if(color === 'pink') {
                    color = 'blue';
                } else if(color === 'blue') {
                    color = 'purple';
                }
            }
    
            return (
                <CardBody className="bg-secondary border-top" key={i}>
                    <Row>
                        <div className="col-auto align-self-center"  style={{width: 50}}>
                            {institution.logo_url ? (
                                <div className={`employment-circle`}>
                                    <div className="logo-circle">
                                        <img 
                                            style={{width: '100%'}} 
                                            src={institution.logo_url} 
                                            alt={institution.name} 
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="employment-circle">
                                    <div className={`employment-circle-inner employment-circle-inner-${color}`}>
                                        {institution.name.slice(0,1)}
                                    </div>
                                </div>
                            )}
                            
                        </div >
                        <Col className="align-self-center">
                            <p className="text-sm mb--2 font-weight-bold">{institution.name}</p>
                            {url ? <p className="text-sm mb-0">{url}</p> : null}
                        </Col>
                    </Row>
                </CardBody>
            )
        })

    }, [])

    const renderInstitution = useCallback((institution, i) => {

        if(!institution) return ''

        let url = '';

        try {
            url = institution.loginUrl ? institution.loginUrl : institution.url;
            const { hostname, protocol } = new URL(url);
            url = protocol + '//' + hostname
        } catch(e) {

        }

        return (
            <CardBody className="bg-secondary border-top" key={i}>
                <Row>
                    <div className="col-auto align-self-center"  style={{width: 50}}>
                        <img 
                            style={{width: '100%'}} 
                            src={institution.favicon ? institution.favicon : institution.small_logo_url} 
                            alt={institution.name} 
                        />
                    </div >
                    <Col className="align-self-center">
                        <p className="text-sm mb--2 font-weight-bold">{institution.name}</p>
                        {url ? <p className="text-sm mb-0">{url}</p> : null}
                    </Col>
                </Row>
            </CardBody>
        )
        
    }, [])

    // eslint-disable-next-line
    const onSearchEmployment = useCallback(_.throttle(async (searchString, hideLoad) => {

        if(!hideLoad) setLoading(true);

        const data = await _integrations.pinwheel.employers.search(searchString)

        if(data.data && data.data.data) {
            setEmployment(data.data.data);
        }

        setLoading(false);
        setInitialLoad(true)
    
    }, 750), [])

    // eslint-disable-next-line
    const onSearchFinancial = useCallback(_.throttle(async (searchString, hideLoad) => {

        if(!hideLoad) setLoading(true);

        const values = await Promise.all([
            _integrations.mx.institutions.search(searchString),
            // _integrations.yodlee.providers.search(searchString),
        ])

        let data = [];
        if(values[0].data && values[0].data.institutions) data = data.concat(values[0].data.institutions)
        // if(values[1].data && values[1].data.provider) data = data.concat(values[1].data.provider)

        setInstitutions(data);
        setLoading(false);
        setInitialLoad(true)
    
    }, 750), [])

    const onSetSearchType = useCallback((type) => {
        if(type === searchType) return;
        setSearchType(type);
        setString('');

        if(type === 'financial') {
            setEmployment([]);
            onSearchFinancial('', true);
            setInitialLoad(false);
        } else {
            setInstitutions([])
            setEmployment(initialEmployers);
        }

    }, [searchType, onSearchFinancial])


    const onStringChange = useCallback((string) => {
        setString()
        if(searchType === 'financial') {
            onSearchFinancial(string);
        } else {
            onSearchEmployment(string);
        }
    }, [searchType, onSearchFinancial, onSearchEmployment])

    return (

        <Card className="connection-search bg-white border  position-relative z-depth-1" >

            <CardHeader>
                <CardTitle className="mb-0">
                    {searchType === 'financial' ? 'Type To Search Financial Institutions' : 'Type To Search Employers'}
                </CardTitle>
            </CardHeader>

            <CardBody className="border-bottom">
                <FormGroup className="mb-0 position-relative">
                    {loading ? (
                        <span
                            style={{position: 'absolute', top: -3, right: 0}}
                        >
                        <Circle />
                    </span>
                    ) : null}
                    <Input 
                        type="text"
                        value={string}
                        onChange={(e) => onStringChange(e.target.value)}
                    />
                </FormGroup>
            </CardBody>

            <CardBody className="border-bottom" style={{borderBottomLeftRadius: 0}}>
                <p className="text-sm mb-0">Search Type:</p>
                <Row>
                    <Col xs={6}>
                        <button 
                            onClick={() => onSetSearchType('employment')} 
                            className={searchType === 'employment' ? "btn btn-success btn-block" : "btn btn-outline-info btn-block"}
                        >
                            Employment
                        </button>
                    </Col>
                    <Col xs={6}>
                        <button 
                            onClick={() => onSetSearchType('financial')} 
                            className={searchType === 'financial' ? "btn btn-success btn-block" : "btn btn-outline-info btn-block"}
                        >
                            Financial
                        </button>
                    </Col>
                </Row>
            </CardBody>

            {initialLoad ? (
                searchType === 'financial' ? (
                    <div style={{maxHeight: '40vh', height: 400, minHeight: 400, overflow: 'auto'}}>
                        {institutions && institutions.length ? institutions.map((institution, i) => (
                            renderInstitution(institution, i)
                        )) : (
                            <CardBody>
                                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info " /> 
                                    No Institutions were found for "{string}", please try again.
                                </p>
                            </CardBody>
                        )}
                    </div>
                ) : (
                    <div style={{maxHeight: '40vh', height: 400, minHeight: 400, overflow: 'auto'}}>
                        {employment && employment.length ? renderEmployment(employment) : (
                            <CardBody>
                                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info " /> 
                                    No Employers were found for "{string}", please try again.
                                </p>
                            </CardBody>
                        )}
                    </div>
                )
            ) : (
                <CardBody className="py-6" style={{height: 400, minHeight: 400}}><Circle /></CardBody>
            )}
            
            <CardFooter className="border-top">
                <p className="text-sm mb-0">
                    {searchType === 'financial' ? (
                        <span onClick={() => onSetSearchType('employment')} className="cursor-pointer">
                            Click HERE to search <b className="text-underline">employment</b> accounts.
                        </span>
                    ) : (
                        <span onClick={() => onSetSearchType('financial')} className="cursor-pointer">
                            Click HERE to search <b className="text-underline">financial</b> accounts.
                        </span>
                    )}
                </p>
            </CardFooter>

        </Card>

    )

}

export default Search 

