const PinwheelSideHustles = {
    en: {
        translation: {
            "PinwheelSideHustles: Last Step!": "Last Step!",
            "PinwheelSideHustles: Step 4/5": "Step 4/5",
            "PinwheelSideHustles: Verify Employment": "Verify Employment",
            "PinwheelSideHustles: Connected Income": "Connected Income",
            "PinwheelSideHustles: Connected": "Connected",
            "PinwheelSideHustles: Move On": "Move On",
            "PinwheelSideHustles: Back": "Back",
            "PinwheelSideHustles: Click To Verify": "Click To Verify",
            "PinwheelSideHustles: Not Set Up": "Not Set Up",
            "PinwheelSideHustles: If you drive for any of the companies below please click the button to link your pay stubs from them.": "If you drive for any of the companies below please click the button to link your pay stubs from them.",
            'PinwheelSideHustles: When you are done click "Confirm Accounts".': 'When you are done click "Confirm Accounts".',
            "PinwheelSideHustles: Confirm Accounts": "Confirm Accounts",
            "PinwheelSideHustles: Connect": "Connect",
        }
    },
    es: {
        translation: {
            "PinwheelSideHustles: Last Step!": "¡Último paso!",
            "PinwheelSideHustles: Step 4/5": "Paso 4/5",
            "PinwheelSideHustles: Verify Employment": "Verificar el empleo",
            "PinwheelSideHustles: Connected Income": "Ingresos Conectados",
            "PinwheelSideHustles: Connected": "Conectado",
            "PinwheelSideHustles: Move On": "Avanza.",
            "PinwheelSideHustles: Back": "Regresar",
            "PinwheelSideHustles: Click To Verify": "Haz click para verificar.",
            "PinwheelSideHustles: Not Set Up": "No se ha configurado",
            "PinwheelSideHustles: If you drive for any of the companies below please click the button to link your pay stubs from them.": "Si laboras para alguna de las empresas que aparecen a continuación, haz clic en el botón para vincular tus recibos de pago.",
            'PinwheelSideHustles: When you are done click "Confirm Accounts".': 'Cuando hayas terminado, haga clic en "Confirmar Cuentas".',
            "PinwheelSideHustles: Confirm Accounts": "Confirmar Cuentas",
            "PinwheelSideHustles: Connect": "Conectar",
        }
    }
};

export default PinwheelSideHustles;