const MXModalStatus = {
    en: {
        translation: {
            "MXModalStatus: Close": "Close",
            "MXModalStatus: Created": "Created",
            "MXModalStatus: Prevented": "Prevented",
            "MXModalStatus: Denied": "Denied",
            "MXModalStatus: Challenged": "Challenged",
            "MXModalStatus: Rejected": "Rejected",
            "MXModalStatus: Locked": "Locked",
            "MXModalStatus: Connected": "Connected",
            "MXModalStatus: Impeded": "Impeded",
            "MXModalStatus: Reconnected": "Reconnected",
            "MXModalStatus: Degraded": "Degraded",
            "MXModalStatus: Disconnected": "Disconnected",
            "MXModalStatus: Closed": "Closed",
            "MXModalStatus: Delayed": "Delayed",
            "MXModalStatus: Failed": "Failed",
            "MXModalStatus: Updated": "Updated",
            "MXModalStatus: Disabled": "Disabled",
            "MXModalStatus: Imported": "Imported",
            "MXModalStatus: Resumed": "Resumed",
            "MXModalStatus: Expired": "Expired",
            "MXModalStatus: Impaired": "Impaired",
            "MXModalStatus: Pending": "Pending",
            "MXModalStatus: Connection Status": "Connection Status",
            "MXModalStatus: If the status of your accounts are": "If the status of your accounts are",
            "MXModalStatus: green": "green",
            "MXModalStatus: you are all set to move on from this page.": "you are all set to move on from this page.",
            "MXModalStatus: If an account has a": "If an account has a",
            "MXModalStatus: dash": "dash",
            "MXModalStatus: under actions it means it was connected by your partner and cannot be removed by you.": "under actions it means it was connected by your partner and cannot be removed by you.",
            "MXModalStatus: Your account is ready to go.": "Your account is ready to go.",
            "MXModalStatus: Your account is connecting now.": "Your account is connecting now.",
            "MXModalStatus: Reconnecting to your account now.": "Reconnecting to your account now.",
            "MXModalStatus: Account not valid": "Account not valid",
            "MXModalStatus: Please finish authenticating your account": "Please finish authenticating your account",
            "MXModalStatus: The last 3 attempts to connect have failed. Please re-enter your credentials to continue importing data.": "The last 3 attempts to connect have failed. Please re-enter your credentials to continue importing data.",
            "MXModalStatus: The credentials entered do not match your credentials at this institution. Please re-enter your credentials to continue importing data.": "The credentials entered do not match your credentials at this institution. Please re-enter your credentials to continue importing data.",
            "MXModalStatus: To authenticate your connection please answer the following challenge(s).": "To authenticate your connection please answer the following challenge(s).",
            "MXModalStatus: The answer or answers provided were incorrect. Please try again.": "The answer or answers provided were incorrect. Please try again.",
            "MXModalStatus: You must re-authenticate before your data can be imported. Please enter your credentials for the account again.": "You must re-authenticate before your data can be imported. Please enter your credentials for the account again.",
            "MXModalStatus: The answer or answers were not provided in time. Please try again.": "The answer or answers were not provided in time. Please try again.",
            "MXModalStatus: You must re-authenticate before your data can be imported. Please enter your credentials.": "You must re-authenticate before your data can be imported. Please enter your credentials.",
            "MXModalStatus: Your account is locked. Please log in to the appropriate website and follow the steps to resolve the issue.": "Your account is locked. Please log in to the appropriate website and follow the steps to resolve the issue.",
            "MXModalStatus: Your attention is needed at this institution’s website. Please log in to the appropriate website and follow the steps to resolve the issue.": "Your attention is needed at this institution’s website. Please log in to the appropriate website and follow the steps to resolve the issue.",
            "MXModalStatus: This connection has been closed. You may track this account manually. If reopened, you may connect the institution again.": "This connection has been closed. You may track this account manually. If reopened, you may connect the institution again.",
            "MXModalStatus: It looks like your data for this account cannot be imported. We are working to resolve the issue.": "It looks like your data for this account cannot be imported. We are working to resolve the issue.",
            "MXModalStatus: Connections to this institution are no longer supported. You may create a manual account and use manual transactions to track data for this account.": "Connections to this institution are no longer supported. You may create a manual account and use manual transactions to track data for this account.",
            "MXModalStatus: Importing data from this institution has been disabled. Please contact us if you believe it has been disabled in error.": "Importing data from this institution has been disabled. Please contact us if you believe it has been disabled in error.",
            "MXModalStatus: We are upgrading this connection. Please try again later.": "We are upgrading this connection. Please try again later.",
            "MXModalStatus: Importing your data may take a while. Please check back later.": "Importing your data may take a while. Please check back later.",
            "MXModalStatus: There was a problem validating your credentials. Please try again later.": "There was a problem validating your credentials. Please try again later",
        }
    },
    es: {
        translation: {
            "MXModalStatus: Close": "Cerrar",
            "MXModalStatus: Created": "Creado",
            "MXModalStatus: Prevented": "Preventivo",
            "MXModalStatus: Denied": "Denegado",
            "MXModalStatus: Challenged": "Cuestionado",
            "MXModalStatus: Rejected": "Rechazado",
            "MXModalStatus: Locked": "Bloqueado",
            "MXModalStatus: Connected": "Conectado",
            "MXModalStatus: Impeded": "Impedido",
            "MXModalStatus: Reconnected": "Reconectado",
            "MXModalStatus: Degraded": "Degradado",
            "MXModalStatus: Disconnected": "Desconectado",
            "MXModalStatus: Closed": "Cerrado",
            "MXModalStatus: Delayed": "Retrasado",
            "MXModalStatus: Failed": "Fallido",
            "MXModalStatus: Updated": "Actualizado",
            "MXModalStatus: Disabled": "Desactivado",
            "MXModalStatus: Imported": "Importado",
            "MXModalStatus: Resumed": "Reanudado",
            "MXModalStatus: Expired": "Caducado",
            "MXModalStatus: Impaired": "Deteriorado",
            "MXModalStatus: Pending": "Pendiente",
            "MXModalStatus: Connection Status": "Estado de la conexión",
            "MXModalStatus: If the status of your accounts are": " Si el estado de sus cuentas es",
            "MXModalStatus: green": "VERDE,",
            "MXModalStatus: you are all set to move on from this page.": "está todo listo para pasar a la siguiente página.",
            "MXModalStatus: If an account has a": "Si una cuenta tiene un",
            "MXModalStatus: dash": "guión",
            "MXModalStatus: under actions it means it was connected by your partner and cannot be removed by you.": "bajo las acciones significa que esta cuenta fue conectada por su pareja y no puede ser eliminada por usted.",
            "MXModalStatus: Your account is ready to go.": "Su cuenta está lista para funcionar.",
            "MXModalStatus: Your account is connecting now.": "Su cuenta se está conectando ahora.",
            "MXModalStatus: Reconnecting to your account now.": "Reconectando su cuenta ahora.",
            "MXModalStatus: Account not valid": "Cuenta no válida.",
            "MXModalStatus: Please finish authenticating your account": "Por favor, termine de autenticar su cuenta.",
            "MXModalStatus: The last 3 attempts to connect have failed. Please re-enter your credentials to continue importing data.": "Los últimos 3 intentos de conexión han fallado. Vuelva a introducir sus credenciales para continuar con la importación de datos.",
            "MXModalStatus: The credentials entered do not match your credentials at this institution. Please re-enter your credentials to continue importing data.": "Las credenciales introducidas no coinciden con sus credenciales en esta institución. Vuelva a introducir sus credenciales para continuar con la importación de datos.",
            "MXModalStatus: To authenticate your connection please answer the following challenge(s).": "Para autenticar su conexión, responda a las siguientes preguntas.",
            "MXModalStatus: The answer or answers provided were incorrect. Please try again.": "La respuesta o respuestas proporcionadas son incorrectas. Por favor, inténtelo de nuevo.",
            "MXModalStatus: You must re-authenticate before your data can be imported. Please enter your credentials for the account again.": "You Debe volver a autenticarse para poder importar sus datos. Vuelva a introducir sus credenciales para la cuenta.",
            "MXModalStatus: The answer or answers were not provided in time. Please try again.": "La respuesta o respuestas no se proporcionaron a tiempo. Por favor, inténtelo de nuevo.",
            "MXModalStatus: You must re-authenticate before your data can be imported. Please enter your credentials.": "Debe volver a autenticarse para poder importar sus datos. Por favor, introduzca sus credenciales.",
            "MXModalStatus: Your account is locked. Please log in to the appropriate website and follow the steps to resolve the issue.": " Su cuenta está bloqueada. Por favor, inicie sesión en el sitio web correspondiente y siga los pasos para resolver el problema.",
            "MXModalStatus: Your attention is needed at this institution’s website. Please log in to the appropriate website and follow the steps to resolve the issue.": "Se necesita su atención en el sitio web de esta institución. Por favor, conéctese al sitio web correspondiente y siga los pasos para resolver el problema.",
            "MXModalStatus: This connection has been closed. You may track this account manually. If reopened, you may connect the institution again.": "Esta conexión se ha cerrado. Puede realizar el seguimiento de esta cuenta manualmente. Si se vuelve a abrir, puede conectar la institución de nuevo.",
            "MXModalStatus: It looks like your data for this account cannot be imported. We are working to resolve the issue.": "Parece que los datos de esta cuenta no se pueden importar. Estamos trabajando para resolver el problema.",
            "MXModalStatus: Connections to this institution are no longer supported. You may create a manual account and use manual transactions to track data for this account.": "Las conexiones con esta institución ya no se admiten. Puede crear una cuenta manual y utilizar transacciones manuales para seguir los datos de esta cuenta.",
            "MXModalStatus: Importing data from this institution has been disabled. Please contact us if you believe it has been disabled in error.": "La importación de datos de esta institución ha sido desactivada. Póngase en contacto con nosotros si cree que se ha desactivado por error.",
            "MXModalStatus: We are upgrading this connection. Please try again later.": "Estamos actualizando esta conexión. Vuelva a intentarlo más tarde.",
            "MXModalStatus: Importing your data may take a while. Please check back later.": "La importación de sus datos puede tardar un poco. Vuelva a comprobarlo más tarde.",
            "MXModalStatus: There was a problem validating your credentials. Please try again later.": "Ha habido un problema al validar tus credenciales. Vuelve a intentarlo más tarde.",
        }
    }
};

export default MXModalStatus;