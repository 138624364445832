const ConfirmationFinal = {
    en: {
        translation: {
            "ConfirmationFinal: Thats It, You're All Done!!": "Thats It, You're All Done!!",
            "ConfirmationFinal: Your connected information will be available shortly. You may now close or leave this page.": "Your connected information will be available shortly. You may now close or leave this page.",
        }
    },
    es: {
        translation: {
            "ConfirmationFinal: Thats It, You're All Done!!": "Eso es todo.. ¡Haz finalizado!",
            "ConfirmationFinal: Your connected information will be available shortly. You may now close or leave this page.": "La información obtenida estará disponible pronto. Puedes cerrar o dejar esta página, si lo deseas.",
        }
    }
};

export default ConfirmationFinal;