import Circle from 'components/markup/loading/Circle'
import { withTranslation } from 'react-i18next';


const MXModal = ({mxShowModal, mxConnectLoaded, mxSetShowModal, mxShowModalExit, mxSetShowModalExit, mxModalWidth, mxCloseConnect, t}) => (
    <div className={mxShowModal ? "modal fade show" : "modal fade"} role="dialog" tabIndex="-1" style={{display: mxShowModal ? 'block' : 'none'}}>
        <div className="modal-backdrop fade show" />
        <div id="test" className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{maxWidth: mxModalWidth, borderRadius: 10, margin: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 99999999}}>
            <div className="modal-content">
                <div className="modal-body p-0" style={{minHeight: 500, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                    {!mxConnectLoaded ? <div style={{position: 'relative', top: 325}}><Circle /></div> : null}
                    <div id="connect-launcher" />
                </div>
                <div className="modal-footer mt--4 border-top" >
                    <span onClick={() => mxSetShowModalExit(true)} className="cursor-pointer" style={{position: 'absolute', top: 5, right: 5, zIndex: 999999,}}>
                        <svg  width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#828E9D" opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M30.7216 17.2784C30.3504 16.9072 29.7485 16.9072 29.3773 17.2784L24 22.6557L18.6228 17.2784C18.2516 16.9072 17.6497 16.9072 17.2785 17.2784C16.9072 17.6496 16.9072 18.2515 17.2785 18.6227L22.6557 24L17.2784 29.3773C16.9072 29.7485 16.9072 30.3504 17.2784 30.7216C17.6496 31.0928 18.2515 31.0928 18.6227 30.7216L24 25.3443L29.3773 30.7216C29.7485 31.0928 30.3504 31.0928 30.7216 30.7216C31.0928 30.3504 31.0928 29.7485 30.7216 29.3773L25.3443 24L30.7216 18.6227C31.0928 18.2515 31.0928 17.6496 30.7216 17.2784Z"></path></svg>
                    </span>
                </div>

                {mxShowModalExit ? (
                        <div className="confirm-mx-close" >
                        <div className="message">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M40 73.3333C58.4095 73.3333 73.3333 58.4095 73.3333 40C73.3333 21.5905 58.4095 6.66667 40 6.66667C21.5905 6.66667 6.66663 21.5905 6.66663 40C6.66663 58.4095 21.5905 73.3333 40 73.3333ZM39.9999 66.6668C54.7275 66.6668 66.6665 54.7278 66.6665 40.0002C66.6665 25.2726 54.7275 13.3335 39.9999 13.3335C25.2723 13.3335 13.3332 25.2726 13.3332 40.0002C13.3332 54.7278 25.2723 66.6668 39.9999 66.6668Z" fill="#4D5E73"></path><path fillRule="evenodd" clipRule="evenodd" d="M39.9999 20C38.159 20 36.6666 21.4924 36.6666 23.3333V43.3333C36.6666 45.1743 38.159 46.6667 39.9999 46.6667C41.8409 46.6667 43.3333 45.1743 43.3333 43.3333V23.3333C43.3333 21.4924 41.8409 20 39.9999 20ZM39.9998 53.3333C38.1588 53.3333 36.6664 54.8257 36.6664 56.6667C36.6664 58.5076 38.1588 60 39.9998 60C41.8407 60 43.3331 58.5076 43.3331 56.6667C43.3331 54.8257 41.8407 53.3333 39.9998 53.3333Z" fill="#4D5E73" fillOpacity="0.8"></path></svg>
                        
                            <h4 className="mt-4">{t(`MXModal: Are you sure you wish to exit?`)}</h4>
                            <p className="text-sm">{t(`MXModal: Your progress will be lost`)}</p>

                        </div>
                        <div>
                            <button onClick={mxCloseConnect} className="btn btn-block text-muted">{t(`MXModal: Exit`)}</button>
                            <button onClick={() => mxSetShowModalExit(false)} className="btn btn-block text-muted">{t(`MXModal: Go Back`)}</button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    </div>
)

export default withTranslation()(MXModal);