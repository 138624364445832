/*
Documentation

render uber lyft, door dash, etc

*/

import { useEffect } from "react";
import { withTranslation } from 'react-i18next';

import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, Progress } from "reactstrap";

import PinwheelModal from '@pinwheel/react-modal'
import PinwheelLinkIcon from '../_images/PinwheelLink.svg'

import Circle from 'components/markup/loading/Circle'

const employers = {
    uberDriver        : '91063607-2b4a-4c8e-8045-a543f01b8b09',
    uberEatsDriver    : "cace440e-43f7-46b4-a937-063f7ed22b74",
    lyftDriver        : "70b2bed2-ada8-49ec-99c2-691cc7d28df6",
    doorDash          : "737d833a-1b68-44f7-92ae-3808374cb459"
}

const MemberCard = withTranslation()(({logo, account, pinwheelNeedsVerification, pinwheelAccountList, pinwheelSetAccountToDelete, name, pinwheelOpenLink, employerId, t}) => {

    if(account) return (
        <CardFooter>
            <Row>
                <Col xs={10} className="align-self-center">
                    <h4>
                        {logo ? (
                            <img 
                                src={logo}
                                alt="name"
                                className="mr-2"
                                style={{
                                    border: name === 'DoorDash (Dasher)' ? 'solid 1px #ccc' : '',
                                    position: 'relative',
                                    top: -2,
                                    width: 30,
                                    borderRadius: 4,
                                    display: 'inline-blcok'
                                }}
                            />
                        ) : null}
                        {name}
                    </h4>
                    {pinwheelNeedsVerification(account, pinwheelAccountList) ? (
                         <button className="btn btn-warning btn-sm" onClick={() => pinwheelOpenLink(account.account_id, null, name)} >
                            <i className="fas fa-info-circle mr-2 " />
                            {t(`PinwheelSideHustles: Click To Verify`)}
                        </button>
                    ) : 
                        <p className="text-sm mb-0">
                            <div><i className="fas fa-check text-success mr-2 " />{t(`PinwheelSideHustles: Connected`)}</div>
                        </p>
                    }
                </Col>
                <Col xs={2} className="align-self-center text-right">
                    <i className="fas fa-times text-danger cursor-pointer pl-3 pt-3 pb-3" onClick={() => pinwheelSetAccountToDelete(account)} />
                </Col>
            </Row>  
        </CardFooter>
    )

    return  (
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <h4>
                        {logo ? (
                            <img 
                                src={logo}
                                alt="name"
                                className="mr-2"
                                style={{
                                    border: name === 'DoorDash (Dasher)' ? 'solid 1px #ccc' : '',
                                    position: 'relative',
                                    top: -2,
                                    width: 30,
                                    borderRadius: 4,
                                    display: 'inline-blcok'
                                }}
                            />
                        ) : null}
                        {name}
                    </h4>
                    <p className="text-muted text-sm mb-0">{t(`PinwheelSideHustles: Not Set Up`)}</p>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button className="btn btn-outline-success" onClick={() => pinwheelOpenLink(null, employerId, name)}>
                        <i className="fas fa-plus mr-2 " /> {t(`PinwheelSideHustles: Connect`)}
                    </button>
                </Col>
            </Row>  
        </CardFooter>
    )

})

const PinwheelSideHustles = ({
    setView,
    pinwheelAccounts,
    pinwheelAccountList,
    companyNameColor,
    pinwheelNeedsVerification,
    pinwheelOpenLink,
    pinwheelToken,
    pinwheelOnEvent,
    pinwheelFetchAccounts,
    pinwheelSetAccountToDelete,
    pinwheelShowModal,
    pinwheelSetToken,
    t
}) => {
   

    useEffect(() => {
        pinwheelFetchAccounts();
        pinwheelSetToken(false);
    }, [pinwheelFetchAccounts, pinwheelSetToken]);

    if(!pinwheelAccounts) return <Circle />

    const accountUberDriver         = pinwheelAccounts.find(a => a.platform === "Uber (Driver)")
    const accountUberEatsDriver    = pinwheelAccounts.find(a => a.platform === "Uber Eats (Driver)")
    const accountLyftDriver         = pinwheelAccounts.find(a => a.platform === "Lyft (Driver)")
    const accountDoorDash           = pinwheelAccounts.find(a => a.platform === "DoorDash (Dasher)")

    return (

        <>

        {pinwheelToken ? (
            <PinwheelModal
                open={pinwheelShowModal}
                linkToken={pinwheelToken}
                onEvent={pinwheelOnEvent}
            />
        ) : null}

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={PinwheelLinkIcon} alt="..." className="flow-icon bg-white" />

                        <CardHeader>
                            <CardTitle className="mb-0">{t(`PinwheelSideHustles: Last Step!`)}</CardTitle>
                            <div className="progress-wrapper pt-2">
                                <div className="progress-success">
                                    <div className="progress-percentage">
                                    <span style={{position: 'relative', top: -3}}>{t(`PinwheelSideHustles: Step 4/5`)}</span>
                                    </div>
                                </div>
                                <Progress max="100" value={80} color="success" />
                            </div>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm">
                                {t(`PinwheelSideHustles: If you drive for any of the companies below please click the button to link your pay stubs from them.`)}
                            </p>
                            <p className="text-sm mb-0">
                                {t(`PinwheelSideHustles: When you are done click "Confirm Accounts".`)}
                            </p>
                        </CardHeader>

                        <CardHeader className="bg-secondar">    
                            <CardTitle className="mb-0">
                                {t(`PinwheelSideHustles: Connected Income`)}
                            </CardTitle>
                        </CardHeader>


                        <MemberCard 
                            logo="https://cdn.getpinwheel.com/assets/employers/logos/Uber%20%28Corporate%29.svg"
                            name="Uber (Driver)"
                            account={accountUberDriver}
                            pinwheelAccountList={pinwheelAccountList}
                            pinwheelNeedsVerification={pinwheelNeedsVerification}
                            setView={setView}
                            pinwheelSetAccountToDelete={pinwheelSetAccountToDelete}
                            pinwheelOpenLink={pinwheelOpenLink}
                            employerId={employers.uberDriver}
                        />
                        <MemberCard 
                            logo="https://cdn.getpinwheel.com/assets/employers/logos/Uber%20%28Corporate%29.svg"
                            name="Uber Eats (Driver)"
                            account={accountUberEatsDriver}
                            pinwheelAccountList={pinwheelAccountList}
                            pinwheelNeedsVerification={pinwheelNeedsVerification}
                            setView={setView}
                            pinwheelSetAccountToDelete={pinwheelSetAccountToDelete}
                            pinwheelOpenLink={pinwheelOpenLink}
                            employerId={employers.uberEatsDriver}
                        />
                        <MemberCard 
                            logo="https://cdn.getpinwheel.com/assets/employers/logos/Lyft%20%28Driver%29.svg"
                            name="Lyft (Driver)"
                            account={accountLyftDriver}
                            pinwheelAccountList={pinwheelAccountList}
                            pinwheelNeedsVerification={pinwheelNeedsVerification}
                            setView={setView}
                            pinwheelSetAccountToDelete={pinwheelSetAccountToDelete}
                            pinwheelOpenLink={pinwheelOpenLink}
                            employerId={employers.lyftDriver}
                        />
                        <MemberCard 
                            logo="https://cdn.getpinwheel.com/assets/employers/logos/Doordash%20%28Corporate%29.svg"
                            name="DoorDash (Dasher)"
                            account={accountDoorDash}
                            pinwheelAccountList={pinwheelAccountList}
                            pinwheelNeedsVerification={pinwheelNeedsVerification}
                            setView={setView}
                            pinwheelSetAccountToDelete={pinwheelSetAccountToDelete}
                            pinwheelOpenLink={pinwheelOpenLink}
                            employerId={employers.doorDash}
                        />
                       
                        <CardFooter className="text-center">
                            <button  onClick={() => setView('Fize')} className=" btn btn-success btn-block my-2 btn-lg">
                                {t(`PinwheelSideHustles: Confirm Accounts`)}<i className="fas fa-arrow-right ml-2 " />
                            </button>
                        </CardFooter>

                    </Card>

                    <p className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" style={{color: companyNameColor}}>
                        <span onClick={() => setView('PinwheelLink')}>
                        <i className="fas fa-arrow-left mr-2 " />
                            {t(`PinwheelSideHustles: Back`)}
                        </span>
                    </p>
                    
                </Col>
            </Row>
        </Container>

        </>

    )
}

export default withTranslation()(PinwheelSideHustles);
