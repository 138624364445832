const MXUpdateConnection = {
    en: {
        translation: {
            "MXUpdateConnection: Updating Connection": "Updating Connection",
        }
    },
    es: {
        translation: {
            "MXUpdateConnection: Updating Connection": "Actualizando la conexión",
        }
    }
};

export default MXUpdateConnection;