import Reports from '../pages/Reports';
import Register from '../pages/Register';
import Home from '../pages/Home/index';

const routes = [

    {
        path: ":company_name/register",
        component: Register,
    },
    {
        path: ":company_name",
        component: Reports,
    },

    

    {
        path: "",
        component: Home,
    },

]

export default routes;