const Main = {
    en: {
        translation: {
            "Main: MX Status: Created": "Created",
            "Main: MX Status: Prevented": "Prevented",
            "Main: MX Status: Denied": "Denied",
            "Main: MX Status: Challenged": "Challenged",
            "Main: MX Status: Rejected": "Rejected",
            "Main: MX Status: Locked": "Locked",
            "Main: MX Status: Connected": "Connected",
            "Main: MX Status: Impeded": "Impeded",
            "Main: MX Status: Reconnected": "Reconnected",
            "Main: MX Status: Degraded": "Degraded",
            "Main: MX Status: Disconnected": "Disconnected",
            "Main: MX Status: Closed": "Closed",
            "Main: MX Status: Delayed": "Delayed",
            "Main: MX Status: Failed": "Failed",
            "Main: MX Status: Updated": "Updated",
            "Main: MX Status: Disabled": "Disabled",
            "Main: MX Status: Imported": "Imported",
            "Main: MX Status: Resumed": "Resumed",
            "Main: MX Status: Expired": "Expired",
            "Main: MX Status: Impaired": "Impaired",
            "Main: MX Status: Pending": "Pending",
            "Main: Your account requires payment before proceeding. Please refresh your page and try again.": "Your account requires payment before proceeding. Please refresh your page and try again.",
            "Main: This account has been temporarily suspended due to lack of funds.": "This account has been temporarily suspended due to lack of funds.",
            "Main: You're ready to start pulling your information. Make sure you do not refresh or close this page before you complete the report process.": "You're ready to start pulling your information. Make sure you do not refresh or close this page before you complete the report process.",
            "Main: Document Generation Is Not Allowed In The Staging Environment.": "Document Generation Is Not Allowed In The Staging Environment.",
            "Main: The terms of use must be accepted before pulling documents.": "The terms of use must be accepted before pulling documents.",
            "Main: We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.": "We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.",
            "Main: We are getting your account ready, please try again in a few minutes.": "We are getting your account ready, please try again in a few minutes.",
            "Main: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.": "You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.",
            "Main: Are you sure you wish to remove the account:": "Are you sure you wish to remove the account:",
            "Main: Connected": "Connected",
            "Main: Needs Verification": "Needs Verification",
        }
    },
    es: {
        translation: {
            "Main: MX Status: Created": "Creado",
            "Main: MX Status: Prevented": "Preventivo",
            "Main: MX Status: Denied": "Denegado",
            "Main: MX Status: Challenged": "Cuestionado",
            "Main: MX Status: Rejected": "Rechazado",
            "Main: MX Status: Locked": "Bloqueado",
            "Main: MX Status: Connected": "Conectado",
            "Main: MX Status: Impeded": "Impedido",
            "Main: MX Status: Reconnected": "Reconectado",
            "Main: MX Status: Degraded": "Degradado",
            "Main: MX Status: Disconnected": "Desconectado",
            "Main: MX Status: Closed": "Cerrado",
            "Main: MX Status: Delayed": "Retrasado",
            "Main: MX Status: Failed": "Fallido",
            "Main: MX Status: Updated": "Actualizado",
            "Main: MX Status: Disabled": "Desactivado",
            "Main: MX Status: Imported": "Importado",
            "Main: MX Status: Resumed": "Reanudado",
            "Main: MX Status: Expired": "Caducado",
            "Main: MX Status: Impaired": "Deteriorado",
            "Main: MX Status: Pending": "Pendiente",
            "Main: Your account requires payment before proceeding. Please refresh your page and try again.": "Su cuenta requiere de un pago para proceder. Por favor refresca la página e intenta nuevamente. ",
            "Main: This account has been temporarily suspended due to lack of funds.": "Esta cuenta ha sido suspendida temporalmente debido a fondos insuficientes.",
            "Main: You're ready to start pulling your information. Make sure you do not refresh or close this page before you complete the report process.": "Estás listo para empezar a obtener tu información. Asegúrate de no refrescar ni cerrar la página antes de poder completar el proceso de reporte. ",
            "Main: Document Generation Is Not Allowed In The Staging Environment.": "La generación de documentos no está permitida en el entorno de preparación",
            "Main: The terms of use must be accepted before pulling documents.": "Los términos de uso deben ser aceptados antes de empezar a obtener los documentos.",
            "Main: We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.": "Estamos experimentando fallas técnicas, por favor intenta nuevamente en unos minutos. Si este problema persiste por favor contactarnos por ayuda.",
            "Main: We are getting your account ready, please try again in a few minutes.": "Estamos preparando tu cuenta, por favor intenta nuevamente en unos minutos.",
            "Main: You will need to re-authenticate with this institution in the future if you wish to have your report include their statements.": "Deberás re-autenticar tu información con ésta Institución en el futuro si quieres incluir tus extractos bancarios. ",
            "Main: Are you sure you wish to remove the account:": "Estás seguro(a) de querer remover la cuenta",
            "Main: Connected": "Conectada",
            "Main: Needs Verification": "Necesita Verificación",
        }
    }
};

export default Main;