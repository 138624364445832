const UtilityStatusRejected = {
    en: {
        translation: {
            "UtilityStatusRejected: Authorization Needed": "Authorization Needed",
            "UtilityStatusRejected: Your account shows that you have either:": "Your account shows that you have either:",
            "UtilityStatusRejected: Not been given access or": "Not been given access or",
            "UtilityStatusRejected: Already completed this process.": "Already completed this process.",
            "UtilityStatusRejected: If you do not believe this to be correct or you need to re-pull any financial information please contact the company who requested your statements:": "If you do not believe this to be correct or you need to re-pull any financial information please contact the company who requested your statements:",
        }
    },
    es: {
        translation: {
            "UtilityStatusRejected: Authorization Needed": "Se Necesita Autorización",
            "UtilityStatusRejected: Your account shows that you have either:": "La cuenta muestra que usted:",
            "UtilityStatusRejected: Not been given access or": "Aún no tiene acceso o",
            "UtilityStatusRejected: Already completed this process.": "Ya ha completado este proceso.",
            "UtilityStatusRejected: If you do not believe this to be correct or you need to re-pull any financial information please contact the company who requested your statements:": "Si cree que esto no es correcto o necesita volver a obtener alguna información financiera, por favor, póngase en contacto con:",
        }
    }
};

export default UtilityStatusRejected;