// corresponds to https://docs.mx.com/api#core_resources_members_connection_statuses

import { Modal } from "reactstrap";
import { withTranslation } from 'react-i18next';

const TR = ({status, meaning, type}) => (
    <tr 
        className={
            type === 'info' ? 'bg-gradient-info text-white' :
            type === 'success' ? 'bg-gradient-success text-white' :
            type === 'error' ? 'bg-gradient-danger text-white' : 
            ''
        }
    >
        <td className="text-capitalize " style={{width: 130, paddingLeft: 20}}>
            {status}
        </td>
        <td className="">{meaning}</td>
    </tr>
)

const MXModalStatus = ({showModal, toggleModal, t}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"

    >

        <div className="modal-header">
            <h5 className="modal-title text-capitalize">{t(`MXModalStatus: Connection Status`)}</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggleModal} >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <p className="text-sm ">
                {t(`MXModalStatus: If the status of your accounts are`)}{' '}
                <b className="text-underline text-success">{t(`MXModalStatus: green`)}</b>{' '}
                {t(`MXModalStatus: you are all set to move on from this page.`)}{' '}
            </p>
            <p className="text-sm mb-0">
                {t(`MXModalStatus: If an account has a`)}{' '}
                <b className='text-underline text-danger'> {t(`MXModalStatus: dash`)}</b> {' '}
                {t(`MXModalStatus: under actions it means it was connected by your partner and cannot be removed by you.`)}
            </p>
        </div>

        <div className="modal-body p-0" style={{maxHeight: 400, overflow: 'auto'}}>

            <div className="table-responsive">
                <table className="table mb-0">
                    <tbody>
                        <TR  status={t(`MXModalStatus: Connected`)}    type="success" meaning={t(`MXModalStatus: Your account is ready to go.`)} />
                        <TR  status={t(`MXModalStatus: Created`)}      type="success" meaning={t(`MXModalStatus: Your account is connecting now.`)} />
                        <TR  status={t(`MXModalStatus: Updated`)}      type="success" meaning={t(`MXModalStatus: Your account is connecting now.`)} />
                        <TR  status={t(`MXModalStatus: Resumed`)}      type="success" meaning={t(`MXModalStatus: Your account is connecting now.`)} />
                        <TR  status={t(`MXModalStatus: Reconnected`)}  type="success" meaning={t(`MXModalStatus: Reconnecting to your account now.`)} />

                        <TR  status={t(`MXModalStatus: Pending`)}      type="info" meaning={t(`MXModalStatus: Please finish authenticating your account`)} />
                        <TR  status={t(`MXModalStatus: Prevented`)}    type="info" meaning={t(`MXModalStatus: The last 3 attempts to connect have failed. Please re-enter your credentials to continue importing data.`)} />
                        <TR  status={t(`MXModalStatus: Denied`)}       type="info" meaning={t(`MXModalStatus: The credentials entered do not match your credentials at this institution. Please re-enter your credentials to continue importing data.`)} />
                        <TR  status={t(`MXModalStatus: Challenged`)}   type="info" meaning={t(`MXModalStatus: To authenticate your connection please answer the following Challenge(s).`)} />
                        <TR  status={t(`MXModalStatus: Rejected`)}     type="info" meaning={t(`MXModalStatus: The answer or answers provided were incorrect. Please try again.`)} />
                        <TR  status={t(`MXModalStatus: Degraded`)}     type="info" meaning={t(`MXModalStatus: We are upgrading this connection. Please try again later.`)} />
                        <TR  status={t(`MXModalStatus: Delayed`)}      type="info" meaning={t(`MXModalStatus: Importing your data may take a while. Please check back later.`)} />
                        <TR  status={t(`MXModalStatus: Failed`)}       type="info" meaning={t(`MXModalStatus: There was a problem validating your credentials. Please try again later.`)} />
                        <TR  status={t(`MXModalStatus: Imported`)}     type="info" meaning={t(`MXModalStatus: You must re-authenticate before your data can be imported. Please enter your credentials for the account again.`)} />
                        <TR  status={t(`MXModalStatus: Expired`)}      type="info" meaning={t(`MXModalStatus: The answer or answers were not provided in time. Please try again.`)} />
                        
                        <TR  status={t(`MXModalStatus: Locked`)}       type="error" meaning={t(`MXModalStatus: Your account is locked. Please log in to the appropriate website and follow the steps to resolve the issue.`)} />
                        <TR  status={t(`MXModalStatus: Impeded`)}      type="error" meaning={t(`MXModalStatus: Your attention is needed at this institution’s website. Please log in to the appropriate website and follow the steps to resolve the issue.`)} />
                        <TR  status={t(`MXModalStatus: Closed`)}       type="error" meaning={t(`MXModalStatus: This connection has been closed. You may track this account manually. If reopened, you may connect the institution again.`)} />
                        <TR  status={t(`MXModalStatus: Disconnected`)} type="error" meaning={t(`MXModalStatus: It looks like your data for this account cannot be imported. We are working to resolve the issue.`)} />
                        <TR  status={t(`MXModalStatus: Disabled`)}     type="error" meaning={t(`MXModalStatus: Importing data from this institution has been disabled. Please contact us if you believe it has been disabled in error.`)} />

                    </tbody>

                </table>
            </div>

        </div>

        <div className="modal-footer" style={{zIndex: 20}}>
            <button className="btn btn-outline-warning" onClick={toggleModal} >
                {t(`MXModalStatus: Close`)}
            </button>
        </div>

    </Modal>
)

export default withTranslation()(MXModalStatus);
