/*
Documentation

this component renders the connect widget with MX

*/

import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { useEffect } from "react";
import { Card, CardFooter, CardHeader, CardTitle, Col, Container,  Row, Progress } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import YodleeIcon from '../_images/Yodlee.svg'

const Yodlee = ({
    setView,
    yodleeNeedsVerification,
    yodleeAccounts,
    yodleeFetchAccounts,
    yodleeOpenFastLink,
    yodleeGetConnectionName,
    yodleeSetAccountToDelete,
    t
}) => {

    useEffect(() => {
        yodleeFetchAccounts()
    }, [yodleeFetchAccounts])

    if(!yodleeAccounts) return <Circle />

    return (

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={YodleeIcon} className="flow-icon bg-white" alt="..." />

                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">
                                {t(`YodleeYodlee: Let's connect your banks.`)}
                            </CardTitle>
                                <div className="progress-wrapper pt-2">
                                    <div className="progress-sucess">
                                        <div className="progress-percentage">
                                        <span style={{position: 'relative', top: -3}}>{t(`YodleeYodlee: Step 1/5`)}</span>
                                        </div>
                                    </div>
                                    <Progress max="100" value={25} color="sucess" />
                                </div>
                        </CardHeader>

                        <CardHeader>
                            <p className="text-sm">
                                {t(`YodleeYodlee: Click the`)}{' '}
                                <b className="text-info">{t(`YodleeYodlee: BLUE`)}</b>{' '}
                                {t(`YodleeYodlee: button below to log into any bank accounts you may have. If you cannot find an account don't worry. We'll take care of that in the next step.`)}
                            </p>

                            <button  onClick={() => yodleeOpenFastLink()} className=" btn btn-info btn-block my-2 btn-lg">
                                <i className="fas fa-link mr-2 " />{t(`YodleeYodlee: Connect Banks`)}
                            </button>
                        </CardHeader>

                        <CardHeader className="bg-secondar border-top">    
                            <CardTitle className="mb-0">
                                {t(`YodleeYodlee: Connected Accounts`)}
                            </CardTitle>                               
                         </CardHeader>

                        {yodleeAccounts && yodleeAccounts.length ? yodleeAccounts.map((account, i) => (
                            <CardFooter key={i}>
                                <Row>
                                    <Col xs={10} className="align-self-center">
                                        <h4 className="mb-0">{account.providerName} - <span style={{fontWeight: 'normal'}}>{account.accountName}</span></h4>
                                        {account.partner ? (
                                            <p className="text-sm text-muted mb-0">{t(`YodleeYodlee: Added By Partner`)}</p>
                                        ) : null}
                                        <p className="text-sm mb-0">
                                            {yodleeNeedsVerification(account) ? (
                                                <span>
                                                    <button className="btn btn-warning btn-sm" onClick={() => yodleeOpenFastLink(account.providerAccountId)}>
                                                        <i className="fas fa-info-circle mr-2 " />
                                                        {t(`YodleeYodlee: Click To Verify`)}
                                                    </button>
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="fas fa-check text-success mr-2 " />
                                                    {yodleeGetConnectionName(account.accountStatus)}
                                                </span>
                                            )}
                                          
                                        </p>
                                      
                                    </Col>
                                    <Col xs={2} className="align-self-center text-right">
                                        {account.partner ? '-' : (
                                            <i className="fas fa-times text-danger cursor-pointer pl-3 pt-3 pb-3" onClick={() => yodleeSetAccountToDelete(account)} />
                                        )}
                                    </Col>
                                </Row>
                               
                            </CardFooter>
                        )) : (
                            <CardFooter>
                                <p className="text-sm mb-0 text-warning font-weight-bold">
                                    {t(`YodleeYodlee: You have not logged into any bank accounts.`)}
                                </p>
                            </CardFooter>
                        )}

                        <CardFooter className="text-center">
                            <button className=" btn btn-success btn-block my-2 btn-lg" onClick={() => setView('MXConnect')}>
                                {t(`YodleeYodlee: Move On`)} <i className="fas fa-arrow-right ml-2 " />
                            </button>
                        </CardFooter>

                    </Card>

                </Col>
            </Row>
        </Container>

    )
}


const mapStateToProps = state => {
	return {
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(withTranslation()(Yodlee));
