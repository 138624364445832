const MXVerifyIdentity = {
    en: {
        translation: {
            "MXVerifyIdentity: Matching Identity": "Matching Identity",
        }
    },
    es: {
        translation: {
            "MXVerifyIdentity: Matching Identity": "Comparando la identidad",
        }
    }
};

export default MXVerifyIdentity;