export const getYodleeConfigName = (company) => {

    const configDefault             = 'Config1';
    const configNoStatements        = 'NoBankStatements';
    const config1MonthsStatements   = '1MonthBankStatements';
    const config3MonthsStatements   = '3MonthsBankStatements';

    if(!company) return configDefault;
    if(!company.accept_real_statements_length) return configDefault;

    if(company.accept_real_statements_length === 'none') return configNoStatements;
    if(company.accept_real_statements_length === '30') return config1MonthsStatements;
    if(company.accept_real_statements_length === '90') return config3MonthsStatements;
    if(company.accept_real_statements_length === '180') return configDefault;
    if(company.accept_real_statements_length === '365') return configDefault;

    return configDefault;

}