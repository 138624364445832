const VerifyName = {
    en: {
        translation: {
            "VerifyName: Please Confirm Your Name": "Please Confirm Your Name",
            "VerifyName: Please type your legal first and last name EXACTLY as it appears on your drivers license or birth certificate.": "Please type your legal first and last name EXACTLY as it appears on your drivers license or birth certificate.",
            "VerifyName: Make sure your name is free of any spelling mistakes and capitalization is correct.": "Make sure your name is free of any spelling mistakes and capitalization is correct.",
            "VerifyName: Confirm Legal First Name*": "Confirm Legal First Name*",
            "VerifyName: Confirm Legal Last Name*": "Confirm Legal Last Name*",
            "VerifyName: The name you have added does not match the name you accepted the terms of service with. Please correct the spelling on this page before moving on.": "The name you have added does not match the name you accepted the terms of service with. Please correct the spelling AND capitalization on this page before moving on.",
            "VerifyName: Please fill out your full legal name.": "Please fill out your full legal name.",
            "VerifyName: Next": "Next",
        }
    },
    es: {
        translation: {
            "VerifyName: Please Confirm Your Name": "Confirme Su Nombre",
            "VerifyName: Please type your legal first and last name EXACTLY as it appears on your drivers license or birth certificate.": "Por favor, escriba su nombre y apellido legal EXACTAMENTE como aparece en su licencia de conducir o certificado de nacimiento.",
            "VerifyName: Make sure your name is free of any spelling mistakes and capitalization is correct.": "Asegúrese de que su nombre no tiene errores ortográficos y que las mayúsculas son correctas",
            "VerifyName: Confirm Legal First Name*": "Confirme El Nombre Legal*",
            "VerifyName: Confirm Legal Last Name*": "Confirme El Apellido Legal*",
            "VerifyName: The name you have added does not match the name you accepted the terms of service with. Please correct the spelling on this page before moving on.": "El nombre que ha añadido no coincide con el nombre con el que aceptó las condiciones de servicio. Por favor, corrija la ortografía en esta página antes de continuar.",
            "VerifyName: Please fill out your full legal name.": "Por favor, escriba su nombre legal completo.",
            "VerifyName: Next": "Siguiente",
        }
    }
};

export default VerifyName;