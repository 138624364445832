const UtilityAccessDenied = {
    en: {
        translation: {
            "UtilityAccessDenied: Access Denied.": "Access Denied.",
            "UtilityAccessDenied: We take your security seriously.": "We take your security seriously.",
            "UtilityAccessDenied: If you are on this page it means you have been given an incorrect link or did not following the the exact directions to pull your documents.": "If you are on this page it means you have been given an incorrect link or did not following the the exact directions to pull your documents.",
            "UtilityAccessDenied: To proceed please click on your invitation link in either your text messages or email. If you feel this page has been shown in error you may try refreshing your page.": "To proceed please click on your invitation link in either your text messages or email. If you feel this page has been shown in error you may try refreshing your page.",
        }
    },
    es: {
        translation: {
            "UtilityAccessDenied: Access Denied.": "Acceso Denegado.",
            "UtilityAccessDenied: We take your security seriously.": "Nos tomamos muy en serio su seguridad.",
            "UtilityAccessDenied: If you are on this page it means you have been given an incorrect link or did not following the the exact directions to pull your documents.": "Si está en esta página significa que se le ha dado un enlace incorrecto o que no ha seguido las instrucciones exactas para sacar sus documentos.",
            "UtilityAccessDenied: To proceed please click on your invitation link in either your text messages or email. If you feel this page has been shown in error you may try refreshing your page.": "Para continuar, por favor haga clic en el enlace de su invitación en sus mensajes de texto o correo electrónico. Si cree que esta página se ha mostrado por error, puede intentar actualizar su página.",
        }
    }
};

export default UtilityAccessDenied;