import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';

const ConfirmationFinal  = ({companyNameColor, t}) => (
    <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
            <Col lg="5" md="7">

                <div className="text-center mb-6 mt--5">
                    <h2 className="h1"><i className="fas fa-check-circle " style={{fontSize: 80, color: companyNameColor}}/></h2>
                </div>

                <Card className="bg-white border-0  position-relative text-center" >

                    <CardHeader>
                        <CardTitle className="mb-0">{t(`ConfirmationFinal: Thats It, You're All Done!!`)}</CardTitle>
                    </CardHeader>

                    <CardFooter>
                        <p className="mb-0">{t(`ConfirmationFinal: Your connected information will be available shortly. You may now close or leave this page.`)}</p>
                    </CardFooter>

                </Card>

            </Col>
        </Row>
    </Container>
)

export default withTranslation()(ConfirmationFinal);

