const MXModal = {
    en: {
        translation: {
            "MXModal: Are you sure you wish to exit?": "Are you sure you wish to exit?",
            "MXModal: Your progress will be lost": "Your progress will be lost",
            "MXModal: Exit": "Exit",
            "MXModal: Go Back": "Go Back",
        }
    },
    es: {
        translation: {
            "MXModal: Are you sure you wish to exit?": "¿Estás seguro de querer salir de esta página? ",
            "MXModal: Your progress will be lost": "Tu progreso se perderá.",
            "MXModal: Exit": "Salir",
            "MXModal: Go Back": "Regresar",
        }
    }
};

export default MXModal;