import { Col, Container, Row } from "reactstrap";

import logo from 'assets/img/brand/ZapReportsLogo.png';

import Main from './Main'

const Search  = () => (
    <Container className="pb-5">
        <Row className="justify-content-center">
            <Col lg="5" md="7">

                <div className="text-center mt-5 mb-5">
                    <img src={logo} style={{maxWidth: 200}} alt="Zap Reports" />
                </div>

                <Main />

            </Col>
        </Row>
    </Container>
)

export default Search 

