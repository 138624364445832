const VerifyAccess = {
    en: {
        translation: {
            "VerifyAccess: Language Select": "Para Español Haz Click Aquí",
            "VerifyAccess: Hello and Welcome!": "Hello and Welcome!",
            "VerifyAccess: Our mission is to make": "Our mission is to make",
            "VerifyAccess: getting your information": "getting your information",
            "VerifyAccess: as easy as possible by helping you pull your required documents quickly and easily.": "as easy as possible by helping you pull your required documents quickly and easily.",
            "VerifyAccess: Please enter the": "Please enter the",
            "VerifyAccess: phone number": "phone number",
            "VerifyAccess: or": "or",
            "VerifyAccess: email address": "email address",
            "VerifyAccess: you received your invitation from as well as your": "you received your invitation from as well as your",
            "VerifyAccess: 6 digit access code": "6 digit access code",
            "VerifyAccess: to get started.": "to get started.",
            "VerifyAccess: Phone / Email*": "Phone / Email*",
            "VerifyAccess: Access Code*": "Access Code*",
            "VerifyAccess: Don't know your access code?": "Don't know your access code?",
            "VerifyAccess: Invalid Credentials, Please Try Again.": "Invalid Credentials, Please Try Again.",
            "VerifyAccess: Finding Your Access Code": "Finding Your Access Code",
            "VerifyAccess: Your access code is unique to you and can be found in the email or text you received inviting you to this portal.": "Your access code is unique to you and can be found in the email or text you received inviting you to this portal.",
            "VerifyAccess: It is most likely next to the link you clicked to navigate to this page.": "It is most likely next to the link you clicked to navigate to this page.",
            "VerifyAccess: If you can't find your access code please contact the company that requested you pull your documents.": "If you can't find your access code please contact the company that requested you pull your documents.",
            "VerifyAccess: Close": "Close",
            "VerifyAccess: Verify My Identity": "Verify My Identity",
        }
    },
    es: {
        translation: {
            "VerifyAccess: Language Select": "Para traducir esta información a Inglés haga clic aquí.",
            "VerifyAccess: Hello and Welcome!": " ¡Hola Y Bienvenido!",
            "VerifyAccess: Our mission is to make": "Nuestra misión es hacer",
            "VerifyAccess: getting your information": " que la obtención de su información",
            "VerifyAccess: as easy as possible by helping you pull your required documents quickly and easily.": "sea lo más fácil posible, ayudándole a obtener los documentos requeridos de forma rápida y sencilla.",
            "VerifyAccess: Please enter the": "Por favor, introduzca el",
            "VerifyAccess: phone number": "número de teléfono",
            "VerifyAccess: or": "o",
            "VerifyAccess: email address": "la dirección de correo electrónico",
            "VerifyAccess: you received your invitation from as well as your": "de la que recibió su invitación, así como su",
            "VerifyAccess: 6 digit access code": "código de acceso de 6 dígitos",
            "VerifyAccess: to get started.": "para empezar.",
            "VerifyAccess: Phone / Email*": "Teléfono / Correo Electrónico*",
            "VerifyAccess: Access Code*": "Código De Acceso*",
            "VerifyAccess: Don't know your access code?": " ¿No sabe su código de acceso?",
            "VerifyAccess: Invalid Credentials, Please Try Again.": "Credenciales inválidas, por favor, inténtelo de nuevo.",
            "VerifyAccess: Finding Your Access Code": "Encontrar Código de Acceso",
            "VerifyAccess: Your access code is unique to you and can be found in the email or text you received inviting you to this portal.": " Su código de acceso es único para usted y puede encontrarse en el correo electrónico o texto que recibió como invitación a Zap Reports",
            "VerifyAccess: It is most likely next to the link you clicked to navigate to this page.": "Lo más probable es que se encuentre junto al enlace en el que hizo clic para navegar hasta esta página.",
            "VerifyAccess: If you can't find your access code please contact the company that requested you pull your documents.": "Si no encuentra su código de acceso, póngase en contacto con",
            "VerifyAccess: Close": 'Regresar',
            "VerifyAccess: Verify My Identity": 'Verificar mi identidad',
        }
    }
};

export default VerifyAccess;