const UtilityError = {
    en: {
        translation: {
            "UtilityError: Something's Not Right": "Something's Not Right",
            "UtilityError: It looks like we are having technical difficulties right now.": "It looks like we are having technical difficulties right now.",
            "UtilityError: Please check back in a few minutes or try refreshing your page.": "Please check back in a few minutes or try refreshing your page.",
        }
    },
    es: {
        translation: {
            "UtilityError: Something's Not Right": "Algo no va bien",
            "UtilityError: It looks like we are having technical difficulties right now.": "Parece que estamos teniendo dificultades técnicas en este momento.",
            "UtilityError: Please check back in a few minutes or try refreshing your page.": "Por favor, vuelva a comprobarlo en unos minutos o intente refrescar su página.",
        }
    }
};

export default UtilityError;