const MXApps = {
    en: {
        translation: {
            "Fize: Auto Insurance": "Auto Insurance",
            "Fize: Alright, time to connect your auto insurance policy.": "Alright, time to connect your auto insurance policy.",
            "Fize: Click the": "Click the",
            "Fize: BLUE": "BLUE",
            "Fize: button below to search for your current auto insurance provider.": "button below to search for your current auto insurance provider.",
            "Fize: If you do not currently have auto insurance you may move onto the next step.": "If you do not currently have auto insurance you may move onto the next step.",
            "Fize: Link Your Auto Policy": "Link Your Auto Policy",
            "Fize: Connected Policies": "Connected Policies",
            "Fize: You have not connected any auto insurance policies": "You have not connected any auto insurance policies",

            "FIZE: Connected": "Connected",
            "FIZE: Step 5/5": "Step 5/5",

            "FIZE: Move On": "Move On",
            "FIZE: Back": "Back",
        }
    },
    es: {
        translation: {
            "Fize: Auto Insurance": "Auto Insurance",
            "Fize: Alright, time to connect your auto insurance policy.": "Alright, time to connect your auto insurance policy.",
            "Fize: Click the": "Click the",
            "Fize: BLUE": "BLUE",
            "Fize: button below to search for your current auto insurance provider.": "button below to search for your current auto insurance provider.",
            "Fize: If you do not currently have auto insurance you may move onto the next step.": "If you do not currently have auto insurance you may move onto the next step.",
            "Fize: Link Your Auto Policy": "Link Your Auto Policy",
            "Fize: Connected Policies": "Connected Policies",
            "Fize: You have not connected any auto insurance policies": "You have not connected any auto insurance policies",

            "FIZE: Connected": "Conectado",
            "FIZE: Step 5/5": "Paso 5/5",

            "FIZE: Move On": "Seguir Adelante",
            "FIZE: Back": "Regresar",
        }
    }
};

export default MXApps;