/*
Documentation

returns a modal with a button for cancel and confirm

cancelling just toggles the modal off, confirming passes out an
onConfirmation prop types to handle a user saying "yes" to whatever
was asked of them

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

class ConfirmationModal extends Component {

    state = {
        user: null
    };

    onConfirm = () => {

        this.props.onConfirmation()
        this.props.toggleModal()

    }

    render() {

        const { showModal, toggleModal, title, body, zIndex, nextButtonText, prevButtonText, nextButtonClasses, prevButtonClasses } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
                zIndex={zIndex ? zIndex : undefined}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm mb-0">{body}</p>

                </div>

                <div className="modal-footer">
                    <button className={prevButtonClasses ? prevButtonClasses : "btn btn-outline-warning"} onClick={toggleModal} >
                        <i className="fas fa-arrow-left" /> {prevButtonText ? prevButtonText : "Cancel"}
                    </button>
                    <button className={nextButtonClasses ? nextButtonClasses : "btn btn-success"} onClick={this.onConfirm} >
                        <i className="fas fa-check mr-2 " /> {nextButtonText ? nextButtonText : 'Confirm'}
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ConfirmationModal.propTypes = {
    showModal         : PropTypes.bool.isRequired,
    toggleModal       : PropTypes.func.isRequired,
    // title             : PropTypes.string.isRequired,
    body              : PropTypes.element.isRequired,
    onConfirmation    : PropTypes.func.isRequired,
    zIndex            : PropTypes.number,
    prevButtonText    : PropTypes.string,
    nextButtonText    : PropTypes.string,
    prevButtonClasses : PropTypes.string,
    nextButtonClasses : PropTypes.string,
}

export default connect(mapStateToProps, '')(ConfirmationModal);
