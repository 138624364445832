import keys from 'keys';
import { Redirect } from 'react-router-dom';
import { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Col, Container, Row, Card, CardHeader, CardTitle, FormGroup, Input, Form, CardFooter } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';

import UtilityAccessDenied from '../Reports/Utility/AccessDenied'

import StartIcon from './_images/start.svg'

import _companies from '_functions/companies';

const Reports = ({match}) => {

    const [loading,         setLoading]      = useState();
    const [redirect,        setRedirect]      = useState();
    const [err,             setErr]           = useState(false);
    const [identifier,      setIdentifier]    = useState('');
    const [foundCompany,    setFoundCompany]  = useState({});
    const [accessDenied,    setAccessDenied]  = useState(false);

    const onVerify = useCallback(async (e) => {
        e.preventDefault();

        if(!identifier) return setErr(`Please add your mobile phone number or email address.`)

        setLoading(true);

        const register = await _companies.register({
            identifier    : identifier,
            company_id    : foundCompany._id,
            a             : getUrlParameter('a'),
            b             : getUrlParameter('b'),
        })

        if(register.success) {
            toggleAlertBS('info', `Your account has been registered, please check your email address or phone for your access code.`)
            setRedirect(`/${foundCompany.custom_link ? foundCompany.custom_link : foundCompany.name}`)
        } else {
            setLoading(false)
            setErr(register.message ? register.message[0] : `Something's not right, please refresh your page and try again.`)
        }

    }, [foundCompany, identifier])

    useEffect(() => {

        const fetchCompany = async () => {

            let name = match.params.company_name;
            if(name) name = name.replace(/\+/g, ' ')

            const company = await _companies.findByName(name);

            if(company.data) {

                setFoundCompany(company.data)

                if(company.data.branding && company.data.branding.color) {
                    document.body.style.background = company.data.branding.color
                }

            } else {
                setFoundCompany('not found')
                setAccessDenied(true)
            }

        }

        fetchCompany()

    }, [match.params.company_name])

    if(foundCompany === 'not found') return <Redirect to="/" />;
    if(redirect) return <Redirect to={redirect} />;
    if(!foundCompany._id) return <div />

    const logo = foundCompany.branding && foundCompany.branding.logo ? foundCompany.branding.logo : keys.LOGO
    const companyNameColor = foundCompany.branding && foundCompany.branding.name_color ? foundCompany.branding.name_color : '#525f7f'
    const companyLogoWidth = foundCompany.branding && foundCompany.branding.logo_width ? parseInt(foundCompany.branding.logo_width) : 300
    const hideName = foundCompany.branding && foundCompany.branding.hide_name ? foundCompany.branding.hide_name : false

    return (
        <>

			<Helmet>
				<title>{foundCompany ? `${foundCompany.name} - Powered By Zap Reports` : 'Powered By Zap Reports'}</title>
				<meta name="description" content={foundCompany ? `${foundCompany.name} - Powered By Zap Reports` : 'Zap Reports'} />
			</Helmet>

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} />
                                {!hideName ? (
                                    <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{foundCompany.name}</h3>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {accessDenied || !foundCompany.client_registration ? <UtilityAccessDenied /> : (
                <Container className="mt--9 pb-5">
                    <Row className="justify-content-center">
                    <Col lg="5" md="7">

                        <Form role="form" style={{zIndex: 2, position: 'relative'}}>

                            <Card className="bg-white border-0 mb-0 position-relative" >

                                <img src={StartIcon} alt="..." className="flow-icon bg-white" />

                                <CardHeader>
                                    <CardTitle className="flow-icon-header mb-0">Register Account</CardTitle>
                                </CardHeader>

                                <CardHeader>
                                    <p className="m text-sm">Our goal is to make the document collection process <b className="text-underline">as easy as possible</b> for you.</p>
                                    <p className="m text-sm">All you need to do is <b className="text-success">1)</b> register your account below with your email or phone number below <b className="text-success">2)</b> enter the access token sent to you and  <b className="text-success">3)</b> purchase access / log into your financial and employment accounts. It's as simple as that. </p>
                                    <p className="mb-0 text-sm"><b className="text-info">Let's get started,</b> to register an account add your email address or mobile phone number below. </p>
                                </CardHeader>

                                <CardHeader >
                                    <FormGroup>
                                        <label className="form-control-label">Enter Your Email / Phone*</label>
                                        <Input
                                            name="identifier"
                                            type="text"
                                            value={identifier}
                                            invalid={err ? true : false}
                                            onChange={(e) => setIdentifier(e.target.value)}
                                        />
                                    </FormGroup>
                                </CardHeader>
                                <CardHeader className="bg-secondary">
                                    <p className="text-sm mb-0">**By opting in to create a Zap Reports account you authorize Zap Reports to send you automated email and text message reminders about your account status. You may opt out of these messages at any time.</p>
                                </CardHeader>

                                {err ? (
                                <CardHeader>
                                    <p className="mb-0 text-sm text-danger font-weight-bold">{err}</p>
                                </CardHeader>
                                ) : null}

                                <CardFooter className="text-center">
                                    <button disabled={loading} id="loginButton" onClick={onVerify} className="my-2 btn-block btn btn-success btn-lg">
                                        <i className="fas fa-lock mr-2 " /> Verify My Identity
                                    </button>
                                </CardFooter>
                            </Card>

                        </Form>

                    </Col>
                </Row>
            </Container>
            )}

            


		</>

    )
}

export default Reports
