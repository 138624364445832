const MXVerifyExtended = {
    en: {
        translation: {
            "MXVerifyExtended: Verifying Transactions": "Verifying Transactions",
        }
    },
    es: {
        translation: {
            "MXVerifyExtended: Verifying Transactions": "Verificando las transacciones",
        }
    }
};

export default MXVerifyExtended;