const VerifyCompany = {
    en: {
        translation: {
            "VerifyCompany: Language Select": "Para Español Haz Click Aquí",
            "VerifyCompany: Your Almost There!": "Terms Of Service",
            // "VerifyCompany: Before you proceed to get your documents please verify your legal name and that you agree to the terms of service listed below.": "Before you proceed to get your documents please verify your legal name and that you agree to the terms of service listed below.",
            "VerifyCompany: Before you proceed to get your documents please verify your legal name and that you agree to the": "Before you proceed to get your documents please verify your legal name and that you agree to the",
            "VerifyCompany: Terms Of Service Listed Here.": "Terms Of Service Listed Here.",
            "VerifyCompany: Legal First Name*": "First Name*",
            "VerifyCompany: Legal Last Name*": "Last Name*",
            "VerifyCompany: I Agree To The Terms Of Service": "I Agree To The Terms Of Service",
            "VerifyCompany: BUTTON I Agree To The Terms Of Service": "I Agree To The Terms Of Service",
            "VerifyCompany: Please fill out your full legal name.": "Please fill out your full legal name.",
            "VerifyCompany: You must agree with the terms of service to proceed.": "You must agree with the terms of service to proceed.",
            "VerifyCompany: Something's not right, please try again.": "Something's not right, please try again.",



        }
    },
    es: {
        translation: {
            "VerifyCompany: Language Select": "Para traducir esta información a Inglés haga clic aquí.",
            "VerifyCompany: Your Almost There!": " ¡Ya Casi Terminamos!",
            "VerifyCompany: Before you proceed to get your documents please verify your legal name and that you agree to the": "Antes de continuar, por favor verifica tu nombre legal y que está de acuerdo con los términos del servicio.",
            "VerifyCompany: Terms Of Service Listed Here.": "que se indican a aqui.",

            "VerifyCompany: Legal First Name*": "Nombre Legal*",
            "VerifyCompany: Legal Last Name*": "Apellido Legal*",
            "VerifyCompany: I Agree To The Terms Of Service": "Estoy de acuerdo con las condiciones de servicio",
            "VerifyCompany: BUTTON I Agree To The Terms Of Service": "Acepto",
            "VerifyCompany: Please fill out your full legal name.": "Por favor, escriba su nombre legal completo.",
            "VerifyCompany: You must agree with the terms of service to proceed.": "Debe aceptar las condiciones del servicio para continuar.",
            "VerifyCompany: Something's not right, please try again.": "Algo no está bien, inténtalo de nuevo",


        }
    }
};

export default VerifyCompany;